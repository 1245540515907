import { message } from 'antd';

import api from '../../../services/api';

export function getUsuario(values) {
  return {
    type: 'USUARIO_FETCH',
    payload: values
  }
}

export function getUsers({ consultorio, page, linesPerPage } = {}) {
  const request = api.get(`usuarios`, {
    params: {
      consultorio,
      page,
      linesPerPage
    }
  });
  return {
    type: 'USERS_FETCHED',
    payload: request
  }
}

export function searchUsers({ consultorio, text } = {}) {
  const request = api.get(`usuarios/search`, {
    params: {
      consultorio,
      text
    }
  });
  return {
    type: 'USERS_SEARCH',
    payload: request
  }
}

export function updateUsuario(values) {
  return submit(values, 'put')
}

function submit(values, method) {
  return dispatch => {
    const id = values.id ? values.id : ''
    api[method](`usuarios/${id}`, values)
      .then(resp => {
        message.success('Operação realizada com sucesso!')
        dispatch([
          updateListUsers(resp.data),
          updateUser(resp.data)
        ])
      });
  }
}

export function updateListUsers(values) {
  return {
    type: 'UPADTE_LIST_USERS',
    payload: values
  }
}

export function updateUser(values) {
  return {
    type: 'UPADTE_USER',
    payload: values
  }
}

export function modalProfile(status) {
  return {
    type: 'MANAGE_PROFILE_MODAL',
    payload: status
  }
}

export function modalClinic(status) {
  return {
    type: 'MANAGE_CLINIC_MODAL',
    payload: status
  }
}

export function clearUsuario() {
  return {
    type: 'CLEAR_USUARIO',
    payload: null
  }
}
