import { combineReducers } from 'redux';

import dashboardReducer from '../components/Home/Redux/reducers';
import loginReducer from '../components/Login/Redux/reducers';
import cargoReducer from '../components/Cargo/Redux/reducers';
import consultorioReducer from '../components/Consultorio/Redux/reducers';
import enderecoReducer from '../components/Endereco/Redux/reducers';
import funcionarioReducer from '../components/Funcionario/Redux/reducers';
import pacienteReducer from '../components/Paciente/Redux/reducers';
import atendimentoReducer from '../components/Atendimento/Redux/reducers';
import servicoReducer from '../components/Servico/Redux/reducers';
import financeiroReducer from '../components/Financeiro/Redux/reducers';
import userReducer from '../components/User/Redux/reducers';

const rootReducer = combineReducers({
    dashboard: dashboardReducer,
    login: loginReducer,
    cargo: cargoReducer,
    consultorio: consultorioReducer,
    endereco: enderecoReducer,
    funcionario: funcionarioReducer,
    paciente: pacienteReducer,
    atendimento: atendimentoReducer,
    servico: servicoReducer,
    financeiro: financeiroReducer,
    user: userReducer
});

export default rootReducer;