import React from 'react';
import { Form, Input, Button, Typography } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import logo from '../../assets/logo_dente.png';
import { forgot, validateToken, loadingUpdate } from './Redux/actions';

const { Title } = Typography;

class Forgot extends React.Component {

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.loadingUpdate(true);
        this.props.forgot(values);
      }
    });
  }

  handleBack = () => {
    this.props.history.goBack();
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { loading } = this.props;
    const titleButton = !loading ? 'Solicitar nova senha' : 'Solicitando...';
    return (
      <div style={{ 
            background: '#f2f2f2', 
            height: '100%', 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            justifyContent: 'center', 
            flexWrap: 'wrap' 
          }}>
        <div style={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center', 
              justifyContent: 'center', 
              background: '#fff', 
              borderRadius: '10px', 
              padding: '77px 55px 33px 55px', 
              width: '350px', 
              border: '1px solid #e0e0e0', 
              boxShadow: '0px 0px 8px 2px #d2c9c9' 
            }}>
            <img style={{ marginBottom: '20px' }} src={logo} alt="sysprod_logo"/>
            <Title style={{ color: 'rgba(0, 0, 0, 0.65)' }} level={4}>Redefinir Senha</Title>

            <Form onSubmit={this.handleSubmit} className="login-form">
                <Form.Item>
                {getFieldDecorator('email', {
                    rules: [{ type: 'email', required: true, message: 'Por favor, informe seu e-mail que está cadastrado!' }],
                })(
                <Input placeholder="Email" />
                )}
                </Form.Item>
                <Form.Item>
                  {
                    !loading ?
                    <Button 
                      loading={loading} 
                      style={{ width: '100%' }} 
                      type="primary" 
                      htmlType="submit" 
                      className="login-form-button"
                    >
                      { titleButton }
                    </Button>
                    :
                    <Button 
                      loading={loading} 
                      style={{ width: '100%' }} 
                      type="primary" 
                      className="login-form-button"
                    >
                      { titleButton }
                    </Button>
                  }
                  <Button 
                      disabled={loading}
                      style={{ width: '100%' }} 
                      className="login-form-button"
                      onClick={() => this.handleBack()}
                    >
                      Voltar
                    </Button>
                </Form.Item>
            </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({ 
    loading: state.login.loading,
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
    forgot,
    validateToken,
    loadingUpdate
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(Forgot));
withRouter(Forgot)