import React from 'react';
import { Input, Form, Select } from 'antd';
import { withRouter } from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getEstados, getCidadesFromEstado, getCep } from './Redux/actions';
import { toUpperCase } from '../UI/util/toUpperCase';

const FormItem = Form.Item;
const Option = Select.Option;

class EnderecoFullForm extends React.Component {

  static propTypes = {
    endereco: PropTypes.shape({
      logradouro: PropTypes.string,
      numero: PropTypes.string,
      complemento: PropTypes.string,
      bairro: PropTypes.string,
      estado: PropTypes.string,
      cidade: PropTypes.object,
      cep: PropTypes.string
    }),
    form: PropTypes.object.isRequired
  };

  componentDidMount() {
    this.props.getEstados()
    
    const { form, endereco } = this.props;
    if (endereco.id) {
      this.props.getCidadesFromEstado(endereco.cidade.estado.id);
      form.setFieldsValue({
        'endereco.logradouro': endereco.logradouro,
        'endereco.numero': endereco.numero,
        'endereco.complemento': endereco.complemento,
        'endereco.bairro': endereco.bairro,
        "endereco.estado": endereco.cidade.estado.id,
        'endereco.cidade': endereco.cidade.id,
        'endereco.cep': endereco.cep
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { form, cep, endereco } = this.props;
    if (prevProps.cep !== cep) {
      form.setFieldsValue({
        'endereco.logradouro': cep.logradouro,
        'endereco.bairro': cep.bairro,
        "endereco.estado": cep.uf,
        'endereco.cidade': cep.localidade,
      });
    }

    if(this.props.endereco !== prevProps.endereco) {
      if (endereco.id) {
        this.props.getCidadesFromEstado(endereco.cidade.estado.id);
        form.setFieldsValue({
          'endereco.logradouro': endereco.logradouro,
          'endereco.numero': endereco.numero,
          'endereco.complemento': endereco.complemento,
          'endereco.bairro': endereco.bairro,
          "endereco.estado": endereco.cidade.estado.id,
          'endereco.cidade': endereco.cidade.id,
          'endereco.cep': endereco.cep
        });
      }
    }
  }

  handleCidadeChange = id => {
    this.props.getCidadesFromEstado(id);
  };

  handleFormatCep = (event) => {
    let number = this.convertNumber(event.target.value);
    event.target.value = number.replace(/^(\d{5})(\d)/, "$1-$2");
    //this.handleSearchCep(event.target.value);
  }

  handleSearchCep = (cep) => {
    const { getCep } = this.props;
    if (cep.length === 9) {
      getCep(cep);
    }
  }

  handleStringToNumber = (event) => {
    let number = this.convertNumber(event.target.value);
    event.target.value = number;
  }

  convertNumber(string) {
    return string.replace(/[^0-9]/g, '');
  }

  render() {
    const { estados, consultorioActive } = this.props;
    const { cidadesFromEstado, form } = this.props;
    const cidades = cidadesFromEstado.map(cidade => ({...cidade}));
    cidades.forEach((cidade, i) => {
      if(cidade.id === consultorioActive.endereco.cidade.id) {
        cidades.splice(i, 1);
        cidades.unshift(cidade);
      }
    });
    const { getFieldDecorator } = form;
    const formItemLayout = {
      labelCol: { span: 7 },
      wrapperCol: { span: 10 }
    };

    return (
      <React.Fragment>
        <FormItem
          {...formItemLayout}
          label="Logradouro"
        >
          {getFieldDecorator('endereco.logradouro', {
            rules: [{ required: true, message: 'Por favor informe a rua da escola!' }],
          })(
            <Input onChange={toUpperCase} maxLength={50} />
          )}
        </FormItem>
        <FormItem
          {...formItemLayout}
          label="Número"
        >
          {getFieldDecorator('endereco.numero', {
            rules: [{ required: true, message: 'Por favor informe o número!' }],
          })(
            <Input
              style={{ width: 100 }}
              maxLength={4}
              onChange={this.handleStringToNumber} />
          )}
        </FormItem>
        <FormItem
          {...formItemLayout}
          label="Complemento"
        >
          {getFieldDecorator('endereco.complemento', {
            rules: [{ required: false }],
          })(
            <Input onChange={toUpperCase} maxLength={30} placeholder="Ex.: Ap 405" />
          )}
        </FormItem>
        <FormItem
          {...formItemLayout}
          label="Bairro"
        >
          {getFieldDecorator('endereco.bairro', {
            rules: [{ required: true, message: 'Por favor informe o bairro da escola!' }],
          })(
            <Input onChange={toUpperCase} maxLength={30} />
          )}
        </FormItem>
        <FormItem
          {...formItemLayout}
          label="Estado"
        >
          {getFieldDecorator('endereco.estado', {
            rules: [{ required: true, message: 'Por favor selecione o estado!' }],
          })(
            <Select
              showSearch
              filterOption
              optionFilterProp="children"
              notFoundContent="Estado não encontrado"
              placeholder="Selecione o estado"
              onChange={this.handleCidadeChange}>
              {estados.map(e =>
                <Option
                  key={e.id}
                  value={e.id}>
                  {e.nome.toUpperCase()}
                </Option>
              )}
            </Select>
          )}
        </FormItem>
        <FormItem
          {...formItemLayout}
          label="Cidade"
        >
          {getFieldDecorator('endereco.cidade', {
            rules: [{ required: true, message: 'Por favor selecione a cidade!' }],
          })(
            <Select 
              showSearch
              filterOption
              optionFilterProp="children"
              placeholder="Selecione a cidade">
              {cidades.map(c =>
                <Option
                  key={c.id}
                  value={c.id}>
                  {c.nome.toUpperCase()}
                </Option>
              )}
            </Select>
          )}
        </FormItem>
        <FormItem
          {...formItemLayout}
          label="CEP"
        >
          {getFieldDecorator('endereco.cep', {
            rules: [{ required: true, message: 'Por favor informe o CEP!' }],
          })(
            <Input
              onChange={this.handleFormatCep}
              style={{ width: 120 }}
              maxLength={9} />
          )}
        </FormItem>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  estados: state.endereco.estados,
  cidadesFromEstado: state.endereco.cidadesFromEstado,
  cep: state.endereco.cep,
  consultorioActive: state.login.consultorioActive
});
const mapDispatchToProps = dispatch => bindActionCreators({
  getEstados,
  getCidadesFromEstado,
  getCep
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(EnderecoFullForm));
withRouter(EnderecoFullForm)
