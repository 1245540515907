import React from 'react'
import { Card, Col, Row, Divider } from 'antd';

import './Odontograma.css'
import OdontogramaSubtitle from './OdontogramaSubtitle';
import ToothPopover from './ToothPopover';

export default class Odontograma extends React.Component {

  handleToothAndColor = (values) => {
    this.props.toothAndColor(values);
  }

  render() {
    const { odontograma, click } = this.props;
    return (
      <React.Fragment>
        <Row gutter={16}>
          <Col span={12}>
            <Card className="card-odontograma" bordered={false}>
              <ul>
                <li>
                  <ToothPopover 
                    left
                    click={click}
                    toothNumber="18"
                    src={odontograma.d18} 
                    toothAndColor={(obj) => this.handleToothAndColor(obj)}
                  />
                </li>
                <li>
                  <ToothPopover
                    left 
                    click={click}
                    toothNumber="17"
                    src={odontograma.d17} 
                    toothAndColor={(obj) => this.handleToothAndColor(obj)}
                  />
                </li>
                <li>
                  <ToothPopover
                    left
                    click={click} 
                    toothNumber="16"
                    src={odontograma.d16} 
                    toothAndColor={(obj) => this.handleToothAndColor(obj)}
                  />
                </li>
                <li>
                  <ToothPopover
                    left
                    click={click} 
                    toothNumber="15"
                    src={odontograma.d15} 
                    toothAndColor={(obj) => this.handleToothAndColor(obj)}
                  />
                </li>
                <li>
                  <ToothPopover
                    left
                    click={click} 
                    toothNumber="14"
                    src={odontograma.d14} 
                    toothAndColor={(obj) => this.handleToothAndColor(obj)}
                  />
                </li>
                <li>
                  <ToothPopover
                    left
                    click={click} 
                    toothNumber="13"
                    src={odontograma.d13} 
                    toothAndColor={(obj) => this.handleToothAndColor(obj)}
                  />
                </li>
                <li>
                  <ToothPopover
                    left
                    click={click} 
                    toothNumber="12"
                    src={odontograma.d12} 
                    toothAndColor={(obj) => this.handleToothAndColor(obj)}
                  />
                </li>
                <li>
                  <ToothPopover
                    left
                    click={click} 
                    toothNumber="11"
                    src={odontograma.d11} 
                    toothAndColor={(obj) => this.handleToothAndColor(obj)}
                  />
                </li>
              </ul>
            </Card>
            <Card className="card-odontograma" bordered={false}>
              <ul>
                <li>
                  <ToothPopover
                    left
                    click={click} 
                    numberDown
                    toothNumber="48"
                    src={odontograma.d48} 
                    toothAndColor={(obj) => this.handleToothAndColor(obj)}
                  />
                </li>
                <li>
                <ToothPopover
                  left
                  click={click} 
                    numberDown
                    toothNumber="47"
                    src={odontograma.d47} 
                    toothAndColor={(obj) => this.handleToothAndColor(obj)}
                  />
                </li>
                <li>
                  <ToothPopover
                    left
                    click={click} 
                    numberDown
                    toothNumber="46"
                    src={odontograma.d46} 
                    toothAndColor={(obj) => this.handleToothAndColor(obj)}
                  />
                </li>
                <li>
                  <ToothPopover
                    left
                    click={click} 
                    numberDown
                    toothNumber="45"
                    src={odontograma.d45} 
                    toothAndColor={(obj) => this.handleToothAndColor(obj)}
                  />
                </li>
                <li>
                  <ToothPopover
                    left
                    click={click} 
                    numberDown
                    toothNumber="44"
                    src={odontograma.d44} 
                    toothAndColor={(obj) => this.handleToothAndColor(obj)}
                  />
                </li>
                <li>
                  <ToothPopover
                    left
                    click={click} 
                    numberDown
                    toothNumber="43"
                    src={odontograma.d43} 
                    toothAndColor={(obj) => this.handleToothAndColor(obj)}
                  />
                </li>
                <li>
                  <ToothPopover
                    left
                    click={click} 
                    numberDown
                    toothNumber="42"
                    src={odontograma.d42} 
                    toothAndColor={(obj) => this.handleToothAndColor(obj)}
                  />
                </li>
                <li>
                  <ToothPopover
                    left
                    click={click} 
                    numberDown
                    toothNumber="41"
                    src={odontograma.d41} 
                    toothAndColor={(obj) => this.handleToothAndColor(obj)}
                  />
                </li>
              </ul>
            </Card>
            <Card className="card-odontograma" bordered={false}>
              <ul>
                <li>
                  <ToothPopover
                    left
                    click={click} 
                    toothNumber="55"
                    src={odontograma.d55} 
                    toothAndColor={(obj) => this.handleToothAndColor(obj)}
                  />
                </li>
                <li>
                  <ToothPopover
                    left
                    click={click} 
                    toothNumber="54"
                    src={odontograma.d54} 
                    toothAndColor={(obj) => this.handleToothAndColor(obj)}
                  />
                </li>
                <li>
                  <ToothPopover
                    left
                    click={click} 
                    toothNumber="53"
                    src={odontograma.d53} 
                    toothAndColor={(obj) => this.handleToothAndColor(obj)}
                  />
                </li>
                <li>
                  <ToothPopover
                    left
                    click={click} 
                    toothNumber="52"
                    src={odontograma.d52} 
                    toothAndColor={(obj) => this.handleToothAndColor(obj)}
                  />
                </li>
                <li>
                  <ToothPopover
                    left
                    click={click} 
                    toothNumber="51"
                    src={odontograma.d51} 
                    toothAndColor={(obj) => this.handleToothAndColor(obj)}
                  />
                </li>
              </ul>
            </Card>
            <Card className="card-odontograma" bordered={false}>
              <ul>
                <li>
                  <ToothPopover
                    left
                    click={click} 
                    numberDown
                    toothNumber="85"
                    src={odontograma.d85} 
                    toothAndColor={(obj) => this.handleToothAndColor(obj)}
                  />
                </li>
                <li>
                  <ToothPopover
                    left
                    click={click} 
                    numberDown
                    toothNumber="84"
                    src={odontograma.d84} 
                    toothAndColor={(obj) => this.handleToothAndColor(obj)}
                  />
                </li>
                <li>
                  <ToothPopover
                    left
                    click={click} 
                    numberDown
                    toothNumber="83"
                    src={odontograma.d83} 
                    toothAndColor={(obj) => this.handleToothAndColor(obj)}
                  />
                </li>
                <li>
                  <ToothPopover
                    left
                    click={click} 
                    numberDown
                    toothNumber="82"
                    src={odontograma.d82} 
                    toothAndColor={(obj) => this.handleToothAndColor(obj)}
                  />
                </li>
                <li>
                  <ToothPopover
                    left
                    click={click} 
                    numberDown
                    toothNumber="81"
                    src={odontograma.d81} 
                    toothAndColor={(obj) => this.handleToothAndColor(obj)}
                  />
                </li>
              </ul>
            </Card>
          </Col>
          <Col span={12}>
            <Card className="card-odontograma" bordered={false}>
              <ul>
                <li>
                  <ToothPopover
                    click={click} 
                    toothNumber="21"
                    src={odontograma.d21} 
                    toothAndColor={(obj) => this.handleToothAndColor(obj)}
                  />
                </li>
                <li>
                  <ToothPopover
                    click={click} 
                    toothNumber="22"
                    src={odontograma.d22} 
                    toothAndColor={(obj) => this.handleToothAndColor(obj)}
                  />
                </li>
                <li>
                  <ToothPopover
                    click={click} 
                    toothNumber="23"
                    src={odontograma.d23} 
                    toothAndColor={(obj) => this.handleToothAndColor(obj)}
                  />
                </li>
                <li>
                  <ToothPopover
                    click={click} 
                    toothNumber="24"
                    src={odontograma.d24} 
                    toothAndColor={(obj) => this.handleToothAndColor(obj)}
                  />
                </li>
                <li>
                  <ToothPopover
                    click={click} 
                    toothNumber="25"
                    src={odontograma.d25} 
                    toothAndColor={(obj) => this.handleToothAndColor(obj)}
                  />
                </li>
                <li>
                  <ToothPopover
                    click={click} 
                    toothNumber="26"
                    src={odontograma.d26} 
                    toothAndColor={(obj) => this.handleToothAndColor(obj)}
                  />
                </li>
                <li>
                  <ToothPopover
                    click={click} 
                    toothNumber="27"
                    src={odontograma.d27} 
                    toothAndColor={(obj) => this.handleToothAndColor(obj)}
                  />
                </li>
                <li>
                  <ToothPopover
                    click={click} 
                    toothNumber="28"
                    src={odontograma.d28} 
                    toothAndColor={(obj) => this.handleToothAndColor(obj)}
                  />
                </li>
              </ul>
            </Card>
            <Card className="card-odontograma" bordered={false}>
              <ul>
                <li>
                  <ToothPopover
                    click={click} 
                    numberDown
                    toothNumber="31"
                    src={odontograma.d31} 
                    toothAndColor={(obj) => this.handleToothAndColor(obj)}
                  />
                </li>
                <li>
                  <ToothPopover
                    click={click} 
                    numberDown
                    toothNumber="32"
                    src={odontograma.d32} 
                    toothAndColor={(obj) => this.handleToothAndColor(obj)}
                  />
                </li>
                <li>
                  <ToothPopover
                    click={click} 
                    numberDown
                    toothNumber="33"
                    src={odontograma.d33} 
                    toothAndColor={(obj) => this.handleToothAndColor(obj)}
                  />
                </li>
                <li>
                  <ToothPopover
                    click={click} 
                    numberDown
                    toothNumber="34"
                    src={odontograma.d34} 
                    toothAndColor={(obj) => this.handleToothAndColor(obj)}
                  />
                </li>
                <li>
                  <ToothPopover
                    click={click} 
                    numberDown
                    toothNumber="35"
                    src={odontograma.d35} 
                    toothAndColor={(obj) => this.handleToothAndColor(obj)}
                  />
                </li>
                <li>
                  <ToothPopover
                    click={click} 
                    numberDown
                    toothNumber="36"
                    src={odontograma.d36} 
                    toothAndColor={(obj) => this.handleToothAndColor(obj)}
                  />
                </li>
                <li>
                  <ToothPopover
                    click={click} 
                    numberDown
                    toothNumber="37"
                    src={odontograma.d37} 
                    toothAndColor={(obj) => this.handleToothAndColor(obj)}
                  />
                </li>
                <li>
                  <ToothPopover
                    click={click} 
                    numberDown
                    toothNumber="38"
                    src={odontograma.d38} 
                    toothAndColor={(obj) => this.handleToothAndColor(obj)}
                  />
                </li>
              </ul>
            </Card>
            <Card className="card-odontograma" bordered={false}>
              <ul>
                <li>
                  <ToothPopover
                    click={click} 
                    toothNumber="61"
                    src={odontograma.d61} 
                    toothAndColor={(obj) => this.handleToothAndColor(obj)}
                  />
                </li>
                <li>
                  <ToothPopover
                    click={click} 
                    toothNumber="62"
                    src={odontograma.d62} 
                    toothAndColor={(obj) => this.handleToothAndColor(obj)}
                  />
                </li>
                <li>
                  <ToothPopover
                    click={click} 
                    toothNumber="63"
                    src={odontograma.d63} 
                    toothAndColor={(obj) => this.handleToothAndColor(obj)}
                  />
                </li>
                <li>
                  <ToothPopover
                    click={click} 
                    toothNumber="64"
                    src={odontograma.d64} 
                    toothAndColor={(obj) => this.handleToothAndColor(obj)}
                  />
                </li>
                <li>
                  <ToothPopover
                    click={click} 
                    toothNumber="65"
                    src={odontograma.d65} 
                    toothAndColor={(obj) => this.handleToothAndColor(obj)}
                  />
                </li>
              </ul>
            </Card>
            <Card className="card-odontograma" bordered={false}>
              <ul>
                <li>
                  <ToothPopover
                    click={click} 
                    numberDown
                    toothNumber="71"
                    src={odontograma.d71} 
                    toothAndColor={(obj) => this.handleToothAndColor(obj)}
                  />
                </li>
                <li>
                  <ToothPopover
                    click={click} 
                    numberDown
                    toothNumber="72"
                    src={odontograma.d72} 
                    toothAndColor={(obj) => this.handleToothAndColor(obj)}
                  />
                </li>
                <li>
                  <ToothPopover
                    click={click} 
                    numberDown
                    toothNumber="73"
                    src={odontograma.d73} 
                    toothAndColor={(obj) => this.handleToothAndColor(obj)}
                  />
                </li>
                <li>
                  <ToothPopover
                    click={click} 
                    numberDown
                    toothNumber="74"
                    src={odontograma.d74} 
                    toothAndColor={(obj) => this.handleToothAndColor(obj)}
                  />
                </li>
                <li>
                  <ToothPopover
                    click={click} 
                    numberDown
                    toothNumber="75"
                    src={odontograma.d75} 
                    toothAndColor={(obj) => this.handleToothAndColor(obj)}
                  />
                </li>
              </ul>
            </Card>
          </Col>
        </Row>
        <Divider className="divider-legenda" type="horizontal" />
          <OdontogramaSubtitle onClick={() => {}} />
      </React.Fragment>
    );
  }

}
