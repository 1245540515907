import React from 'react';
import { Link } from 'react-router-dom';
import { Result, Button } from 'antd';

export default function PageNotFound() {
  return (
    <Result
      status="404"
      title="404"
      subTitle="Desculpe, página não encontrada!."
      extra={
        <Link to="/dashboard">
          <Button type="primary">Página inicial</Button>
        </Link>
      }
    />
  );
}