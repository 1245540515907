import { message } from 'antd';

import api from '../../../services/api';
import jsonpRequest from '../../../services/jsonpRequest';

export function getConsultorio(values) {
  return {
    type: 'CONSULTORIO_FETCH',
    payload: values
  }
}

export function getConsultorios({ usuario = '', page, linesPerPage } = {}) {
  const request = api.get(`consultorios`, {
    params: {
      usuario,
      page,
      linesPerPage
    }
  })
  return {
    type: 'CONSULTORIO_FETCHED',
    payload: request
  }
}

export function searchConsultorio({ text } = {}) {
  const request = api.get(`consultorios/search`, {
    params: {
      text
    }
  })
  return {
    type: 'CONSULTORIO_SEARCH',
    payload: request
  }
}


export function addConsultorio(values, param) {
  return submit(values, 'post', param)
}

export function updateConsultorio(values, param) {
  return submit(values, 'put', param)
}

export function removeConsultorio(values, param) {
  return submit(values, 'delete', param)
}

function submit(values, method, param) {
  return dispatch => {
    const id = values.id ? values.id : ''
    api[method](`consultorios/${id}`, values)
      .then(resp => {
        message.success('Operação realizada com sucesso!')
        dispatch([
          getConsultorios({ page: 0, linesPerPage: 15 })
        ])
      });
  }
}

export function clearConsultorio() {
  return {
    type: 'CONSULTORIO_CLEAR',
    payload: null
  }
}

export function getCnpj(cnpj) {
  const request = jsonpRequest.get(`https://www.receitaws.com.br/v1/cnpj/${cnpj}`);
  return {
    type: 'CNPJ',
    payload: request
  }
}