import React from 'react'
import { Popover, Tooltip } from 'antd';

import './Odontograma.css'
import OdontogramaSubtitle from './OdontogramaSubtitle';

import Tooth_Black_Middle from '../../../assets/Tooth/Tooth_Black_Middle.png';
import Tooth_Blank_Middle from '../../../assets/Tooth/Tooth_Blank_Middle.png';
import Tooth_Blue_Middle from '../../../assets/Tooth/Tooth_Blue_Middle.png';
import Tooth_Green_Middle from '../../../assets/Tooth/Tooth_Green_Middle.png';
import Tooth_Grey_Middle from '../../../assets/Tooth/Tooth_Grey_Middle.png';
import Tooth_Red_Middle from '../../../assets/Tooth/Tooth_Red_Middle.png';
import Tooth_Yellow_Middle from '../../../assets/Tooth/Tooth_Yellow_Middle.png';

export default class ToothPopover extends React.Component {

  state = {
    visible: false,
    tooth: '',
    color: ''
  }

  verifyColor = (color) => {
    switch (color) {
      case 0:
        return Tooth_Blank_Middle;
      case 1:
        return Tooth_Black_Middle;
      case 2:
        return Tooth_Grey_Middle;
      case 3:
        return Tooth_Red_Middle;
      case 4:
        return Tooth_Yellow_Middle;
      case 5:
        return Tooth_Blue_Middle;
      case 6:
        return Tooth_Green_Middle;
      // case 'remocao_a_fazer':
      //   return Odontograma_Removal_Todo;
      // case 'removido_aqui':
      //   return Odontograma_Removed_Inside;
      //   case 'removido_fora':
      //   return Odontograma_Removed_Out;

      default:
        break;
    }
  }

  handleChooseColor = (color) => {
    this.setState({ ...this.state, color, visible: false });
    this.props.toothAndColor({ tooth: this.state.tooth, color, position: 'centro' });
  }

  handleTooth = (tooth) => {
    this.setState({ ...this.state, tooth });
  }

  handleVisibleChange = (visible, tooth) => {
    this.setState({ ...this.state, visible, tooth });
  }

  render() {
    const { toothNumber, src, click, titleTooltip } = this.props;
    const content = <OdontogramaSubtitle onClick={(color) => this.handleChooseColor(color)} />

    return (
      <React.Fragment>
        <Popover
          content={content}
          title="Escolha uma opção"
          //onClick={() => this.handleTooth("d" + toothNumber)}
          trigger={click ? "click" : ""}
          visible={this.state.visible}
          onVisibleChange={(visible) => this.handleVisibleChange(visible, "d" + toothNumber)}
        >
          <Tooltip 
          title={titleTooltip}>
            <img className="tooth-middle" src={this.verifyColor(src)} alt={"d" + toothNumber} />
          </Tooltip>
        </Popover>
      </React.Fragment>
    );
  }

}
