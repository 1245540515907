import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Tooltip, Input, Tag, Switch, Divider, message } from 'antd';
import debounce from 'lodash.debounce';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { verifyProfile } from '../UI/util/verifyProfile';
import { getUsers, updateUsuario, getUsuario, searchUsers, modalProfile, modalClinic, clearUsuario } from './Redux/actions';
import { FaKey, FaIdBadge, FaClinicMedical } from 'react-icons/fa';
import ManageProfileModal from './Profile/ManageProfileModal';
import ManageClinicModal from './Clinic/ManageClinicModal';

const Search = Input.Search;

const PAGE_SIZE = 20;

class ListUsers extends React.Component {
  
    state = {
      searchText: '',
      currentPage: 0
    }

    handleSearch = (event) => {
      const searchText = event.target.value;
      this.setState({ searchText }, () => {
        if (this.state.searchText)
          this.searchUsers();
        else
          this.getFirstPage();
      });
    };

    searchUsers = debounce(() => {
      const { searchText } = this.state;
      const { consultorioActive } = this.props;
      const consultorio = consultorioActive.id ? consultorioActive.id : '';
      this.props.searchUsers({ consultorio, text: searchText })
    }, 500);

    componentDidMount() {
      this.getFirstPage();
    }

    handlePaginationChange = (page, pageSize) => {
      this.setState({ currentPage: page });
      const { consultorioActive } = this.props;
      const consultorio = consultorioActive.id ? consultorioActive.id : '';
      this.props.getUsers({ consultorio, page: page - 1, linesPerPage: pageSize });
    };

    getFirstPage = () => {
      const { consultorioActive } = this.props;
      const consultorio = consultorioActive.id ? consultorioActive.id : '';
      this.props.getUsers({ consultorio, page: 0, linesPerPage: PAGE_SIZE });
    }

    changeStatus = (status, usuario) => {
      const { updateUsuario, user } = this.props;
      let usuarioNew = JSON.parse(JSON.stringify(usuario));

      if(usuario.id === user.id) return message.warning("Você não pode desativar a si próprio!");

      if(user.isAdmin || user.isSuper) {
        usuarioNew.ativo = status;
        updateUsuario(usuarioNew);
      }

    }

    showModal = (usuario, option) => {
      this.props.getUsuario(usuario);
      if(option === 'profile') this.props.modalProfile(true);
      if(option === 'clinic') this.props.modalClinic(true);
    };
  
    handleOk = option => {
      if(option === 'profile') this.props.modalProfile(false);
      if(option === 'clinic') this.props.modalClinic(false);
      this.props.clearUsuario();
    };
  
    handleCancel = option => {
      if(option === 'profile') this.props.modalProfile(false);
      if(option === 'clinic') this.props.modalClinic(false);
      this.props.clearUsuario();
    };
    
    render() {
      const { searchText, currentPage } = this.state;
      const { users, loading, getUsuario, visible, visibleClinic, usuario } = this.props;
      const data = (users.content || []).map(i => ({...i, key: i.id}));
      const columns = [{
        title: 'Nome',
        dataIndex: 'pessoa.nome',
        key: 'nome',
        render: (_, usuario) => <Link onClick={() => getUsuario(usuario)} to={`/usuario/detalhes/${usuario.id}`}>{usuario.pessoa.nome}</Link>,
      }, {
        title: 'Login',
        dataIndex: 'login',
        key: 'login'
      }, {
        title: 'CPF',
        dataIndex: 'pessoa.cpf',
        key: 'cpf'
      }, {        
        title: 'Status',
        key: 'ativo',
        dataIndex: 'ativo',
        render: (ativo, usuario) => (
          <Switch
            checked={ativo}
            onChange={(status) => this.changeStatus(status, usuario)}
            checkedChildren="Ativo"
            unCheckedChildren="Inativo"
          />
        )
      },
      {        
        title: 'Perfis',
        key: 'perfis',
        dataIndex: 'perfis',
        align: 'center',
        render: (perfis) => (
          <span>
            { perfis.map(perfil => <Tag key={perfil} color="blue">{verifyProfile(perfil)}</Tag>) }
          </span>
        )
      }, {
        title: 'Ações',
        key: 'action',
        align: 'center',
        render: (_, usuario, record) => 
          <span>
            <Tooltip 
              title={`Gerenciar perfis`}
              placement="bottom">
              <Link to="/usuario/list">
                <FaIdBadge size="12" onClick={() => this.showModal(usuario, 'profile')} />
              </Link>
            </Tooltip>
            <Divider type="vertical" />
            <Tooltip 
              title={`Gerenciar consultórios`}
              placement="bottom">
              <Link to="/usuario/list">
                <FaClinicMedical size="12" onClick={() => this.showModal(usuario, 'clinic')} />
              </Link>
              </Tooltip>
            <Divider type="vertical" />
            <Tooltip 
              title={`Redefinir senha`}
              placement="bottom">
              <Link to={`/usuario/form/edit/${usuario.id}`}>
                <FaKey size="12" onClick={() => getUsuario(usuario)} />
              </Link>
            </Tooltip>
          </span>
        
      }];

        const pagination = (!searchText && 
          { 
            current: currentPage,
            defaultPageSize: PAGE_SIZE,
            pageSize: PAGE_SIZE,
            total: users.totalElements || 0,
            onChange: this.handlePaginationChange
          }
        ) || false;

        return (
          <React.Fragment>
              <h1 style={{ textAlign: 'center' }}>Usuários</h1>
            <Search
              placeholder="Buscar usuário por Nome, Login ou CPF"
              onChange={this.handleSearch} />
            <Table 
              columns={columns} 
              dataSource={data} 
              loading={loading} 
              pagination={pagination}
            />
            <ManageProfileModal
              visible={visible}
              handleOk={() => this.handleOk('profile')}
              handleCancel={() => this.handleCancel('profile')}
              usuario={usuario}
            />
            <ManageClinicModal
              visibleClinic={visibleClinic}
              handleOk={() => this.handleOk('clinic')}
              handleCancel={() => this.handleCancel('clinic')}
              usuario={usuario}
            />
          </React.Fragment>
        );
    }

}

const mapStateToProps = state => ({ 
  users: state.user.list, 
  loading: state.user.loading,
  user: state.login.user,
  visible: state.user.visible,
  visibleClinic: state.user.visibleClinic,
  usuario: state.user.usuario,
  consultorioActive: state.login.consultorioActive
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
  getUsers, 
  getUsuario,
  updateUsuario,
  searchUsers,
  modalProfile,
  modalClinic,
  clearUsuario
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ListUsers);
