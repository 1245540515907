import React from 'react';
import { Table, Divider, Icon, Popconfirm, Tooltip, Button, Input, Tag, DatePicker } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import debounce from 'lodash.debounce';
import moment from 'moment';

import { getAtendimento, getAtendimentos, removeAtendimento, searchAtendimento, searchAtendimentoByDate, loadingAtendimento } from './Redux/actions';
import { Link } from 'react-router-dom';
import './Style.css';

const Search = Input.Search;

const PAGE_SIZE = 15;

class ListCargos extends React.Component {
  
    state = {
      searchText: '',
      currentPage: 0,
      dataAtendimento: null
    }
    
    componentDidMount() {      
      this.getFirstPage();
    }

    handleSearch = (event) => {
      const searchText = event.target.value;
      this.setState({ searchText }, () => {
        if (this.state.searchText)
          this.searchAtendimentos();
        else
          this.getFirstPage();
      });
    };

    searchAtendimentos = debounce(() => {
      const { searchText } = this.state;
      const { consultorioActive } = this.props;
      const consultorio = consultorioActive.id ? consultorioActive.id : '';
      this.props.searchAtendimento({ text: searchText, consultorio });
    }, 500);

    handlePaginationChange = (page, pageSize) => {
      const { consultorioActive, loadingAtendimento } = this.props;
      loadingAtendimento(true);
      const consultorio = consultorioActive.id ? consultorioActive.id : '';
      this.setState({ currentPage: page });
      this.props.getAtendimentos({ consultorio, page: page - 1, linesPerPage: pageSize});
    };

    getFirstPage = () => {
      const { consultorioActive } = this.props;
      const consultorio = consultorioActive.id ? consultorioActive.id : '';
      this.props.getAtendimentos({ consultorio, page: 0, linesPerPage: PAGE_SIZE });
    }

    handleChangeDate = (date) => {
      this.setState({ ...this.state, dataAtendimento: date });
    }

    handleSearchDate = () => {
      const { dataAtendimento } = this.state;
      const { consultorioActive } = this.props;
      const consultorio = consultorioActive.id ? consultorioActive.id : '';
      const data = moment(dataAtendimento).format("YYYY/MM/DD");
      this.props.searchAtendimentoByDate({ consultorio, data });
    }

    handleSearchClear = () => {
      const { consultorioActive } = this.props;
      const consultorio = consultorioActive.id ? consultorioActive.id : '';
      this.setState({ ...this.state, dataAtendimento: null })
      this.props.getAtendimentos({ consultorio, page: 0, linesPerPage: PAGE_SIZE });
    }
    
    render() {
      const { searchText, currentPage } = this.state;
      const { atendimentos, loading, getAtendimento, removeAtendimento, consultorioActive } = this.props;
      const data = (atendimentos.content || []).map(i => ({...i, key: i.id}));

      const columns = [{
        title: 'Paciente',
        dataIndex: 'paciente.nome',
        key: 'paciente.nome',
        render: (_, atendimento) => <Link to={`/atendimento/detalhes/${atendimento.id}`} onClick={() => getAtendimento(atendimento)}>{atendimento.paciente.nome}</Link>,
      }, {
        title: 'Data',
        dataIndex: 'dataAtendimento',
        key: 'dataAtendimento',
        align: 'center',
        render: (_, atendimento) => moment(atendimento.dataAtendimento).format('DD/MM/YYYY')
      }, {
        title: 'Serviços',
        dataIndex: 'servicos',
        key: 'servicos',
        align: 'center',
        render: (_, atendimento) => (
          <span>
            {atendimento.servicos.map(servico => <Tag color="blue" key={servico.id}>{servico.nome}</Tag> )}
          </span>
        ),
      }, {
        title: 'Valor',
        dataIndex: 'valor',
        key: 'valor',
        align: 'center',
        render: (_, atendimento) => atendimento.valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
      }, {
        title: 'Ações',
        key: 'action',
        render: (_, atendimento, record) => 
          <span>
            <Tooltip 
              title={`Editar`}
              placement="bottom">
              <Link to={`/atendimento/form/edit/${atendimento.id}`}>
                <Icon type="edit" theme="twoTone" onClick={() => getAtendimento(atendimento)}/>
              </Link>
            </Tooltip>
            <Divider type="vertical" />
            <Popconfirm
              title="Deseja remover?"
              okText="Remover"
              cancelText="Cancelar"
              onConfirm={() => removeAtendimento(atendimento, consultorioActive)}>
              <Tooltip 
                placement="bottom"
                title={`Remover`}>
                <Icon type="delete" theme="twoTone" />
              </Tooltip>
            </Popconfirm>
          </span>
        
      }];

      const pagination = (!searchText && 
        { 
          current: currentPage,
          defaultPageSize: PAGE_SIZE,
          pageSize: PAGE_SIZE,
          total: atendimentos.totalElements || 0,
          onChange: this.handlePaginationChange
        }
      ) || false;

      return (
          <React.Fragment>
            <Link to="/atendimento/form/new">
              <Button icon="plus-square">
                Nova Atendimento
              </Button>
            </Link>
              <h1 style={{ textAlign: 'center' }}>Atendimentos</h1>
              <div className="search-date">
                <DatePicker value={this.state.dataAtendimento} format="DD/MM/YYYY" onChange={this.handleChangeDate} />
                <Button onClick={() => this.handleSearchDate()} type="primary" icon="search" size="small">Consultar</Button>
                <Button onClick={() => this.handleSearchClear()} icon="close" size="small">Limpar</Button>
              </div>
              <Search
                placeholder="Buscar atendimentos por Nome do paciente"
                onChange={this.handleSearch} />
              <Table 
                pagination={pagination} 
                columns={columns} 
                dataSource={data} 
                loading={loading} 
              />
          </React.Fragment>
      );
  }

}

const mapStateToProps = state => ({ 
  atendimentos: state.atendimento.list, 
  loading: state.atendimento.loading,
  user: state.login.user,
  consultorioActive: state.login.consultorioActive
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
  getAtendimento, 
  getAtendimentos, 
  removeAtendimento, 
  searchAtendimento,
  searchAtendimentoByDate,
  loadingAtendimento
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ListCargos);
