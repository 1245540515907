import React from 'react';
import { Input, Form, Select, DatePicker, Button, Card, Modal } from 'antd';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { addFuncionario, updateFuncionario, clearFuncionario, findPessoaByCpf } from './Redux/actions';
import { ContentTitle } from '../UI/ContentTitle/ContentTitle';
import EnderecoFullForm from '../Endereco/EnderecoFull';
import { InputCurrencyBRL } from '../UI/NumericInput/InputCurrencyBRL';
import { InputTelephone } from '../UI/TelefoneInput/InputTelephone';
import { InputCellPhone } from '../UI/TelefoneInput/InputCellPhone';
import { formatToUsd } from '../UI/util/FormatToUsd';
import { getCargos } from '../Cargo/Redux/actions';
import { toUpperCase } from '../UI/util/toUpperCase';

const FormItem = Form.Item;
const Option = Select.Option;

class FuncionarioForm extends React.Component {

  componentDidMount() {
    const id = this.funcionarioId;
    const { form, funcionario, consultorioActive } = this.props;
    const consultorio = consultorioActive.id ? consultorioActive.id : '';
    this.props.getCargos({ consultorio });
    
    if (id) {
      form.setFieldsValue({
        'pessoa.nome': funcionario.pessoa.nome,
        'pessoa.cpf': funcionario.pessoa.cpf,
        'pessoa.dataNascimento': moment(funcionario.pessoa.dataNascimento),
        'pessoa.genero': funcionario.pessoa.genero === 'MASCULINO' ? 0 : 1,
        'cargo': funcionario.cargo.id,
        'valorSalario': funcionario.valorSalario,
        'pessoa.telefone': funcionario.pessoa.telefone,
        'pessoa.celular': funcionario.pessoa.celular,
        'pessoa.email': funcionario.pessoa.email
      });
    } //else this.props.form.resetFields();
  }

  componentDidUpdate(prevProps) {
    const changed = this.props.funcionario.pessoa.id !== prevProps.funcionario.pessoa.id;

    if (changed) {
      this.confirmModal();
    }
  }

  componentWillUnmount() {      
    this.props.clearFuncionario();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { funcionario, consultorioActive } = this.props;
        
        values.pessoa.consultorio = {id: consultorioActive.id};
        values.pessoa.endereco = values.endereco;
        values.endereco.cidade = {id: values.endereco.cidade};
        values.valorSalario = formatToUsd(values.valorSalario);
        values.cargo = {id: values.cargo};

        const funcionarioId = this.funcionarioId;
        if (funcionarioId) {
          values.id = funcionarioId;
          values.pessoa.id = funcionario.pessoa.id;
          values.pessoa.endereco.id = funcionario.pessoa.endereco.id;
          this.props.updateFuncionario(values, consultorioActive);
        } else {
          values.pessoa.id = funcionario.pessoa.id ? funcionario.pessoa.id : null;
          this.props.addFuncionario(values, consultorioActive);
        }
        this.props.history.replace('/funcionarios/list');
      }
    });
  }

  handleBack = () => {
    this.props.history.goBack();
  }

  confirmModal = () => {
    const { form, funcionario } = this.props;

    Modal.confirm({
      title: 'Já existe uma pessoa cadastrada com esse CPF!',
      content: 'Deseja autocompletar os campos?',
      okText: 'Sim',
      cancelText: 'Não',
      onOk() {
        form.setFieldsValue({
          'pessoa.nome': funcionario.pessoa.nome,
          'pessoa.cpf': funcionario.pessoa.cpf,
          'pessoa.dataNascimento': moment(funcionario.pessoa.dataNascimento),
          'pessoa.genero': funcionario.pessoa.genero === 'MASCULINO' ? 0 : 1,
          'cargo': funcionario.cargo.id,
          'valorSalario': funcionario.valorSalario,
          'pessoa.telefone': funcionario.pessoa.telefone,
          'pessoa.celular': funcionario.pessoa.celular,
          'pessoa.email': funcionario.pessoa.email
        });
      },
      onCancel() {},
    });
  }

  handleformatCpf = (event) => {
    let cpfDigitado = this.convertNumber(event.target.value);
    if (cpfDigitado.length > 11) {
      cpfDigitado = cpfDigitado.slice(0, 11);
    }
    event.target.value = this.formatCpf(cpfDigitado);

    if (event.target.value.length === 14) {
      const cpf = event.target.value;
      this.props.findPessoaByCpf({ cpf });
    }
  }

  validateCPF = (rule, value, callback) => {
    if (value) {
      let soma, resto, cpf = value;
      let strCPF = this.convertNumber(cpf);
      soma = 0;
      if (strCPF === "00000000000") callback('CPF inválido!');

      for (let i = 1; i <= 9; i++) soma = soma + parseInt(strCPF.substring(i - 1, i), 10) * (11 - i);
      resto = (soma * 10) % 11;

      if ((resto === 10) || (resto === 11)) resto = 0;
      if (resto !== parseInt(strCPF.substring(9, 10), 10)) callback('CPF inválido!');

      soma = 0;
      for (let i = 1; i <= 10; i++) soma = soma + parseInt(strCPF.substring(i - 1, i), 10) * (12 - i);
      resto = (soma * 10) % 11;

      if ((resto === 10) || (resto === 11)) resto = 0;
      if (resto !== parseInt(strCPF.substring(10, 11), 10)) callback('CPF inválido!');
    }
    callback();
  }

  convertNumber(string) {
    return string.replace(/[^0-9]/g, '');
  }

  formatCpf(cpf) {
    cpf = cpf.replace(/\D/g, "");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    return cpf;
  }

  get funcionarioId() {
    return this.props.match.params.id;
  }

  render() {
    const { form, funcionario, cargos } = this.props;
    const { pessoa } = funcionario;
    const { endereco } = pessoa;
    const { id } = this.props.match.params;
    const title = id ? "Atualização de Funcionário" : "Cadastro de Funcionário";
    const { getFieldDecorator } = form;
    const formItemLayout = {
      labelCol: { span: 7 },
      wrapperCol: { span: 10 }
    };
    const cargoList = cargos.content;

    return (
      <div>
        <ContentTitle hasBack>
          {title}
        </ContentTitle>
        <Form onSubmit={this.handleSubmit}>
          <Card title='Dados Pessoais' bordered={false}>
            <FormItem
              {...formItemLayout}
              label="Nome"
            >
              {getFieldDecorator('pessoa.nome', {
                rules: [{ required: true, message: 'Por favor informe o nome completo do funcionário!' }],
              })(
                <Input onChange={toUpperCase} maxLength={80} placeholder="Nome completo" />
              )}
            </FormItem>
            <FormItem
              {...formItemLayout}
              label="CPF"
            >
              {getFieldDecorator('pessoa.cpf', {
                rules: [{ required: true, message: 'Por favor informe o CPF do funcionário!' },
                {
                  validator: this.validateCPF,
                }
                ],
              })(
                <Input
                  style={{ width: 140 }}
                  onChange={this.handleformatCpf} />
              )}
            </FormItem>
            <FormItem
              {...formItemLayout}
              label="Data de nascimento"
            >
              {getFieldDecorator('pessoa.dataNascimento', {
                rules: [{ required: true, message: 'Por favor informe a data de nascimento do funcionário!' }],
              })(
                <DatePicker format="DD/MM/YYYY" />
              )}
            </FormItem>
            <FormItem
              {...formItemLayout}
              label="Gênero"
            >
              {getFieldDecorator('pessoa.genero', {
                rules: [{ required: true, message: 'Por favor informe o gênero do funcionário!' }],
              })(
                <Select placeholder="Selecione">
                  <Option value={0}>Masculino</Option>
                  <Option value={1}>Feminino</Option>
                </Select>
              )}
            </FormItem>
          </Card>
          <Card title='Financeiro' bordered={false}>
          <FormItem
            {...formItemLayout}
            label="Cargo"
          >
            {getFieldDecorator('cargo', {
              rules: [{ required: true, message: 'Por favor informe o cargo do funcionário!' }],
            })(
              <Select
                notFoundContent="Cargos não encontrado" 
                placeholder="Selecione o cargo">
                { cargoList.map(c => 
                  <Option 
                    key={c.id}
                    value={c.id}>
                    {c.nome}
                  </Option> 
                ) }
              </Select>
            )}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label="Salário R$"
          >
            {getFieldDecorator('valorSalario', {
              rules: [{ required: true, message: 'Por favor informe o salário do funcionário!' }],
            })(
              <InputCurrencyBRL style={{ width: 140 }} />
            )}
          </FormItem>
          </Card>
          <Card title='Contato' bordered={false}>
            <FormItem
              {...formItemLayout}
              label="Telefone"
            >
              {getFieldDecorator('pessoa.telefone', {
                rules: [{ required: false, message: 'Por favor informe um telefone de contato do funcionário!' }],
              })(
                <InputTelephone />
              )}
            </FormItem>
            <FormItem
              {...formItemLayout}
              label="Celular"
            >
              {getFieldDecorator('pessoa.celular', {
                rules: [{ required: false }],
              })(
                <InputCellPhone />
              )}
            </FormItem>
            <FormItem
              {...formItemLayout}
              label="E-mail"
            >
              {getFieldDecorator('pessoa.email', {
                rules: [{
                  required: true,
                  type: 'email', message: 'O e-mail não é válido!',
                }],
              })(
                <Input maxLength={45} />
              )}
            </FormItem>
          </Card>
          <Card title='Endereço' bordered={false}>
            <FormItem>
              <EnderecoFullForm form={form} endereco={endereco} />
            </FormItem>
          </Card>
          <FormItem>
            <center>
              <Button type="primary" htmlType="submit">{id ? 'Atualizar' : 'Salvar'}</Button>
              {'    '}
              <Button onClick={this.handleBack}>Cancelar</Button>
            </center>
          </FormItem>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  funcionario: state.funcionario.funcionario,
  user: state.login.user,
  cargos: state.cargo.list,
  consultorioActive: state.login.consultorioActive
});
const mapDispatchToProps = dispatch => bindActionCreators({
  addFuncionario,
  updateFuncionario,
  clearFuncionario,
  getCargos,
  findPessoaByCpf
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(FuncionarioForm));
withRouter(FuncionarioForm)