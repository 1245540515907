import React from 'react';
import { Input, Form, Button, Card } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { addCargo, updateCargo, clearCargo } from './Redux/actions';
import { ContentTitle } from '../UI/ContentTitle/ContentTitle';
import { toUpperCase } from '../UI/util/toUpperCase';

const FormItem = Form.Item;

class CargoForm extends React.Component {
  
  componentDidMount() {
    const { form, cargo } = this.props;
    
    form.setFieldsValue({
      nome: cargo.nome
    });
  }

  componentWillUnmount() {      
    this.props.clearCargo();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { cargo, consultorioActive } = this.props;

        values.consultorio = {id: consultorioActive.id};

        const cargoId = this.cargoId;
        if (cargoId) {
          values.id = cargoId;
          values.createdDate = cargo.createdDate;
          this.props.updateCargo(values, consultorioActive);
        } else {
          this.props.addCargo(values, consultorioActive);
        }
        this.props.history.replace('/cargo/list');
      }
    });
  }

  handleBack = () => {
    this.props.history.goBack();
  }

  get cargoId() {
    return this.props.match.params.id;
  }

  render() {
    const id = this.cargoId;
    const title = id ? 'Atualizar Cargo' : 'Cadastro de Cargo';
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 7 },
      wrapperCol: { span: 10 }
    };
    
    return (
      <React.Fragment>
        <ContentTitle hasBack>
          { title }
        </ContentTitle>
        <Form onSubmit={this.handleSubmit}>
          <Card title='Dados do Cargo' bordered={false}>
          <FormItem
            {...formItemLayout}
            label="Nome"
          >
            {getFieldDecorator('nome', {
              rules: [{ required: true, message: 'Por favor informe o cargo.' }],
            })(
              <Input onChange={toUpperCase} maxLength={25} placeholder="Ex.: Dentista" />
            )}
          </FormItem>
          </Card>
          
          <FormItem>
            <center>
              <Button type="primary" htmlType="submit">{id ? 'Atualizar' : 'Salvar'}</Button>
              {'    '}
              <Button onClick={this.handleBack}>Cancelar</Button>
            </center>
          </FormItem>
        </Form>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({ 
  cargo: state.cargo.cargo,
  user: state.login.user,
  consultorios: state.consultorio.list,
  consultorioActive: state.login.consultorioActive
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
  addCargo, 
  updateCargo, 
  clearCargo
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(CargoForm));
