const INITIAL_STATE = {
  consultorio: {
    endereco: {
      id: null,
      cidade: { 
        estado: {  } 
      } 
    } 
  },
  list: { content: [] },
  loading: true,
  cnpj: {}
}
export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'CONSULTORIO_FETCHED':
      return { 
        ...state, 
        list: action.payload.data ? action.payload.data : { content: [] }, 
        loading: false 
      }
    case 'CONSULTORIO_FETCH':
      return { ...state, consultorio: action.payload }
    case 'CONSULTORIO_CLEAR':
      return { ...state, consultorio: INITIAL_STATE.consultorio }
    case 'CONSULTORIO_SEARCH':
      return { ...state, list: action.payload.data }
    case 'INSTITUICAO_FETCH_ID':
      return { ...state, instituicao: action.payload.data }
    case 'CNPJ':
      return {...state, cnpj: action.payload.data}

    default:
      return state
  }
}