import React from 'react'
import { Col, Row, Tag } from 'antd';

import './Odontograma.css';
import X_Removed_Inside from '../../../assets/X_Removed_Inside.png';
import X_Removed_Out from '../../../assets/X_Removed_Out.png';
import X_Removal_Todo from '../../../assets/X_Removal_Todo.png';
import Triangle_Red from '../../../assets/Triangle_Red.png';
import Triangle_Black from '../../../assets/Triangle_Black.png';

export default class OdontogramaLegend extends React.Component {

  render() {
    return (
      <React.Fragment>
        <Row className='legenda-odontograma'>
          <Col span={12}>
            <ul>
              <li>
                <Tag onClick={() => this.props.onClick(0)} className='tag-odontograma' color="#fff" />
                <span>HÍGIDO</span>
              </li>
              <li>
                <Tag onClick={() => this.props.onClick(1)} className='tag-odontograma' color="#000" />
                <span>PRÓTESE FIXA</span>
              </li>
              <li>
                <Tag onClick={() => this.props.onClick(2)} className='tag-odontograma' color="#8d8787" />
                <span>MANCHA BRANCA ATIVA</span>
              </li>
              <li>
                <Tag onClick={() => this.props.onClick(3)} className='tag-odontograma' color="#cf1d1d" />
                <span>CARIADO</span>
              </li>
              <li>
                <Tag onClick={() => this.props.onClick(4)} className='tag-odontograma' color="#efd415" />
                <span>RESTAURAÇÃO FRATURADA</span>
              </li>
              <li>
                <Tag onClick={() => this.props.onClick(5)} className='tag-odontograma' color="#043dbe" />
                <span>RESTAURADO</span>
              </li>
            </ul>
          </Col>
          <Col span={12}>
            <ul>
              <li>
                <Tag onClick={() => this.props.onClick(6)} className='tag-odontograma' color="#10c020" />
                <span>RESTAURAÇÃO PROVISÓRIA</span>
              </li>
              <li>
                <img onClick={() => this.props.onClick(7)} className='tag-odontograma' src={X_Removed_Inside} alt="X_Removed_Inside"/>
                <span>EXODONTIA REALIZADA</span>
              </li>
              <li>
                <img onClick={() => this.props.onClick(8)} className='tag-odontograma' src={X_Removed_Out} alt="X_Removed_Out"/>
                <span>DENTE AUSENTE</span>
              </li>
              <li>
                <img onClick={() => this.props.onClick(9)} className='tag-odontograma' src={X_Removal_Todo} alt="X_Removal_Todo"/>
                <span>EXODONTIA INDICADA</span>
              </li>
              <li>
                <img onClick={() => this.props.onClick(10)} className='tag-odontograma' src={Triangle_Red} alt="Triangle_Red"/>
                <span>ENDODONTIA INDICADA</span>
              </li>
              <li>
                <img onClick={() => this.props.onClick(11)} className='tag-odontograma' src={Triangle_Black} alt="Triangle_Black"/>
                <span>ENDODONTIA REALIZADA</span>
              </li>
            </ul>
          </Col>
        </Row>
      </React.Fragment>
    );
  }

}
