const INITIAL_STATE = {
  cargo: {},
  list: { content: [] },
  loading: true
}
export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'CARGO_FETCHED':
      return { 
        ...state, 
        list: action.payload.data ? action.payload.data : { content: [] }, 
        loading: false 
      }
    case 'CARGO_ADDED':
      return { ...state }
    case 'CARGO_FETCH':
      return { ...state, cargo: action.payload }
    case 'CARGO_CLEAR':
      return { ...state, cargo: action.payload }
    case 'CARGO_SEARCH':
      return { ...state, list: action.payload.data }
    case 'CARGO_FETCH_ID':
      return { ...state, cargo: action.payload.data}
    
    default:
      return state
  }
}