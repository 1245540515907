import React from 'react'
import { Popover } from 'antd';

import './Odontograma.css'

import OdontogramaSubtitle from './OdontogramaSubtitle';

import Tooth_Removed_Inside from '../../../assets/Tooth/Tooth_Removed_Inside.png';
import Tooth_Removed_Out from '../../../assets/Tooth/Tooth_Removed_Out.png';
import Tooth_Removal_Todo from '../../../assets/Tooth/Tooth_Removal_Todo.png';
import Tooth_Red_Endodontia_Indicada from '../../../assets/Tooth/Tooth_Red_Endodontia_Indicada.png';
import Tooth_Black_Endodontia_Realizada from '../../../assets/Tooth/Tooth_Black_Endodontia_Realizada.png';

export default class ToothPopover extends React.Component {

  state = {
    visible: false,
    tooth: '',
    color: ''
  }

  verifyColor = (color) => {
    switch (color) {
      case 7:
        return Tooth_Removed_Inside;
      case 8:
        return Tooth_Removed_Out;
      case 9:
        return Tooth_Removal_Todo;
      case 10:
        return Tooth_Red_Endodontia_Indicada;
      case 11:
        return Tooth_Black_Endodontia_Realizada;

      default:
        break;
    }
  }

  handleChooseColor = (color) => {
    this.setState({ ...this.state, color, visible: false });
    this.props.toothAndColor({ tooth: this.state.tooth, color, position: 'centro' });
  }

  handleTooth = (tooth) => {
    this.setState({ ...this.state, tooth });
  }

  handleVisibleChange = (visible, tooth) => {
    this.setState({ ...this.state, visible, tooth });
  }

  render() {
    const { toothNumber, src, click } = this.props;
    const content = <OdontogramaSubtitle onClick={(color) => this.handleChooseColor(color)} />
    return (
      <React.Fragment>
        <Popover
          content={content}
          title="Escolha uma opção"
          trigger={click ? "click" : ""}
          visible={this.state.visible}
          onVisibleChange={(visible) => this.handleVisibleChange(visible, "d" + toothNumber)}
        >
          <img className="tooth-x" src={this.verifyColor(src)} alt={"d" + toothNumber} />
        </Popover>
      </React.Fragment>
    );
  }

}
