import React from 'react'
import { Card } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import ReactToPrint from 'react-to-print';

import { ContentTitle } from '../UI/ContentTitle/ContentTitle';
import { getAtendimentoById, clearAtendimento } from './Redux/actions';
import { ButtonPrint } from '../UI/ButtonPrint/ButtonPrint';
import { PrintHeader } from '../UI/PrintHeader/PrintHeader';

class DetailsAtendimento extends React.Component {

  componentDidMount() {
    const id = this.atendimentoId;

    if (!this.props.atendimento.paciente.id) {
      this.props.getAtendimentoById(id);      
    }
  }

  componentWillUnmount() {
    this.props.clearAtendimento();
  }

  get atendimentoId() {
    return this.props.match.params.id;
  }

  render() {
    const { atendimento, consultorioActive } = this.props;
    const servicetitle = atendimento.servicos.length <= 1 ? 'Serviço' : 'Serviços';
    const buttonPrint = (<ReactToPrint
                          key="ReactToPrint"
                          trigger={() => <ButtonPrint style={{ fontSize: '25px', height: '1em' }} />}
                          content={() => this}
                        />)
    const date = 'Data: ' + moment(atendimento.dataAtendimento).format('DD/MM/YYYY');
    return (
      <center>
        <ContentTitle hasBack />
        <PrintHeader consultorio={consultorioActive} />
        <h1>Detalhes do Atendimento</h1>
        <Card
          title={`Paciente: ${atendimento.paciente.nome}`}
          extra={[date].concat(' ', [buttonPrint])}
          style={{ width: 900, textAlign: 'justify' }}  >
          <Card title='Dados Pessoais' bordered={false}>
            <p><strong>CPF: </strong>{atendimento.paciente.cpf}</p>
            <p><strong>Data de Nascimento: </strong>{moment(atendimento.paciente.dataNascimento).format('DD/MM/YYYY')}</p>
            <p><strong>Idade: </strong>{moment().diff(atendimento.paciente.dataNascimento, 'years') + ' anos'}</p>
          </Card>
          <Card title={servicetitle} bordered={false}>
            {
              atendimento.servicos.map(servico => {
                return (
                  <ul key={servico.id}>
                    <li>{servico.nome}</li>
                  </ul>
                );
              })
            }
          </Card>
          <Card title='Valor' bordered={false}>
            { atendimento.valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }
          </Card>
        </Card>
      </center>

    );
  }


}

const mapStateToProps = state => ({ 
  atendimento: state.atendimento.atendimento,
  consultorioActive: state.login.consultorioActive
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
  getAtendimentoById,
  clearAtendimento
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(DetailsAtendimento);
withRouter(DetailsAtendimento)