import React from 'react';
import { Popover, Avatar, Button } from 'antd';
import PropTypes from 'prop-types';

import Content from './Content';
import MaleBlankAvatar from '../../assets/Male_Blank_Avatar.jpg';
import FemaleBlankAvatar from '../../assets/Female_Blank_Avatar.jpg';
import { abbreviateName } from '../UI/util/abbreviateName';

export default class Profile extends React.Component {

  state = {
    visible: false
  }

  handleVisibleChange = visible => {
    this.setState({ visible });
  };

  render() {
    const { user } = this.props;
    const avatarGenre = user.pessoa.genero === "MASCULINO" ? MaleBlankAvatar : FemaleBlankAvatar;
    return (
      <Popover
        content={<Content onClick={this.props.onClick} user={user} />}
        placement="bottom"
        trigger="click"
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
      >
        <Button style={{ height: '64px', width: '180px' }} >
          <Avatar src={!user.imageUrl ? avatarGenre : user.imageUrl} />
          {abbreviateName(user.pessoa.nome)}
        </Button>
      </Popover>
    );
  }
}

Profile.propTypes = {
  user: PropTypes.object.isRequired
};
