import React from 'react'
import { Modal, Skeleton } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import './Odontograma.css'
import { getPacienteById, updateOdontograma } from '../Redux/actions';
import Odontograma from './Odontograma'; 

class OdontogramaModal extends React.Component {

  componentDidUpdate(prevProps) {
    const { getPacienteById, paciente } = this.props;
    const changed = paciente.id !== prevProps.paciente.id;

    if (changed) {
      getPacienteById(paciente.id);
    }
  }

  handleOdontograma = (values) => {
    this.props.updateOdontograma(values);
  }

  render() {
    const { visible, handleOk, handleCancel, paciente, loadingOdontograma, loadingButtonOdonto } = this.props;
    const { odontograma } = paciente;
    //console.log('odontograma', odontograma)
    return (
      <React.Fragment>
        <Modal
          width='595px'
          destroyOnClose={true}
          title={`Odontograma do paciente: ${paciente.nome || paciente.pessoa.nome}`}
          visible={visible}
          onOk={!loadingButtonOdonto ? handleOk : () => {}}
          onCancel={handleCancel}
          confirmLoading={loadingButtonOdonto}
          okText='Confirmar'>
          <Skeleton loading={loadingOdontograma} active>
            <Odontograma click odontograma={odontograma} toothAndColor={(obj) => this.handleOdontograma(obj)} />
          </Skeleton>
        </Modal>
      </React.Fragment>
    );
  }

}

const mapStateToProps = state => ({
  //paciente: state.paciente.paciente,
  loadingOdontograma: state.paciente.loadingOdontograma,
  loadingButtonOdonto: state.paciente.loadingButtonOdonto,
  user: state.login.user
});
const mapDispatchToProps = dispatch => bindActionCreators({
  getPacienteById,
  updateOdontograma
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OdontogramaModal);
