const INITIAL_STATE = {
  user: {
    id: null,
    isSuper: false,
    isAdmin: false,
    isAtendente: false,
    pessoa: {
      id: null,
      nome: '',
      cpf: '',
      genero: ''
    },
    consultorios: []
  },
  consultorioActive: {
    id: null
  },
  token: null,
  signed: false,
  loading: false
}
export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SIGN_IN_SUCCSESS':
      localStorage.setItem('token', action.payload.token);
      localStorage.setItem('userId', action.payload.userId);
      return {
        ...state, 
        token: action.payload.token, 
        signed: true,
        user: {
          ...state.user,
          id: action.payload.userId 
        },
        loading: false
      }
    case 'LOGOUT':
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      return {
        ...state,
        user: INITIAL_STATE.user,
        token: null,
        signed: false
      }
    case 'VALIDATE_TOKEN':
      if (action.payload.token) {
        return {
          ...state,
          signed: true,
          token: action.payload.token,
          user: { ...state.user, id: action.payload.userId }
        }
      } else {
        return {
          ...state,
          signed: false,
          token: null,
          user: INITIAL_STATE.user
        }
      }
    case 'LOADING_LOGIN':
      return {
        ...state,
        loading: action.payload
      }
    case 'USER_FETCHED':
        return {
          ...state,
          user: {
            ...state.user,
            ...action.payload,
            ...action.payload.perfis.forEach(perfil => {
              if(perfil === 1) return state.user.isSuper = true;
              if(perfil === 2) return state.user.isAdmin = true;
              if(perfil === 3) return state.user.isAtendente = true;
            })
          }
        }
      case 'UPDATE_CONSULTORIO_LOGGED':
        localStorage.setItem('consultorioId', action.payload ? action.payload.id : '');
        return {
          ...state,
          consultorioActive: {
            ...action.payload
          }
        }

    default:
      return state
  }
}