import React from 'react'
import { Card } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ContentTitle } from '../UI/ContentTitle/ContentTitle';

class ConsultorioDetails extends React.Component {

  renderPJ() {
    const { consultorio } = this.props;
    const { endereco } = consultorio;
    const { cidade } = endereco;
    const { estado } = cidade;
    return (
      <center>
        <ContentTitle hasBack />
        <h1>Detalhes do Consultório</h1>
        <Card
          title={<b>{consultorio.nomeFantasia}</b>}
          style={{ width: 900, textAlign: 'justify' }} >

          <Card title='Dados do Consultório' bordered={false}>
            <p><b>{consultorio.matriz ? 'Matriz' : 'Filial'}</b></p>
            <p><b>CNPJ: </b>{consultorio.cnpj}</p>
            <p><b>Razão Social: </b>{consultorio.razaoSocial}</p>
          </Card>
          <Card title='Contato' bordered={false}>
            <p><b>Telefone: </b>{consultorio.telefone2}</p>
            <p><b>Celular: </b>{consultorio.celular}</p>
            <p><b>E-mail: </b>{consultorio.email}</p>
          </Card>
          <Card title='Endereço' bordered={false}>
            <p><b>Logradouro: </b>{endereco.logradouro}</p>
            <p><b>Número: </b>{endereco.numero}</p>
            <p><b>Complemento: </b>{endereco.complemento}</p>
            <p><b>Bairro: </b>{endereco.bairro}</p>
            <p><b>Cidade: </b>{cidade.nome}</p>
            <p><b>Estado: </b>{estado.nome}</p>
            <p><b>CEP: </b>{endereco.cep}</p>
          </Card>
        </Card>
        <br />
        <br />
      </center>
    );
  }

  renderPF() {
    const { consultorio } = this.props;
    const { endereco } = consultorio;
    const { cidade } = endereco;
    const { estado } = cidade;
    return (
      <center>
        <ContentTitle hasBack />
        <h1>Detalhes do Consultório</h1>
        <Card
          title={<b>{consultorio.nome}</b>}
          style={{ width: 900, textAlign: 'justify' }} >

          <Card title='Dados do Consultório' bordered={false}>
            <p><b>CRO: </b>{consultorio.cro}</p>
            <p><b>CPF: </b>{consultorio.cpf}</p>
          </Card>
          <Card title='Contato' bordered={false}>
            <p><b>Celular: </b>{consultorio.celular}</p>
            <p><b>E-mail: </b>{consultorio.email}</p>
          </Card>
          <Card title='Endereço' bordered={false}>
            <p><b>Logradouro: </b>{endereco.logradouro}</p>
            <p><b>Número: </b>{endereco.numero}</p>
            <p><b>Complemento: </b>{endereco.complemento}</p>
            <p><b>Bairro: </b>{endereco.bairro}</p>
            <p><b>Cidade: </b>{cidade.nome}</p>
            <p><b>Estado: </b>{estado.nome}</p>
            <p><b>CEP: </b>{endereco.cep}</p>
          </Card>
        </Card>
        <br />
        <br />
      </center>
    );
  }

  render() {
    const { consultorio } = this.props;
    const pj = consultorio.pj;
    return (
      <React.Fragment>
        { pj ?
          this.renderPJ()
          :
          this.renderPF()
        }
      </React.Fragment>

    );
  }

}

const mapStateToProps = state => ({ consultorio: state.consultorio.consultorio, });
const mapDispatchToProps = dispatch => bindActionCreators({  }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ConsultorioDetails);
