import React from 'react';
import { Input, Form, Button, Card } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { updateUsuario, getUsuario } from './Redux/actions';
import { ContentTitle } from '../UI/ContentTitle/ContentTitle';

const FormItem = Form.Item;

class UserForm extends React.Component {

  componentDidMount() {
    const { usuario, form } = this.props;
      
      form.setFieldsValue({
        nome: usuario.pessoa.nome,
        login: usuario.login,
        cpf: usuario.pessoa.cpf
      })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { usuario } = this.props;
        let user = JSON.parse(JSON.stringify(usuario));

        user.senha = values.senha
        this.props.updateUsuario(user);
        this.handleBack();
      }
    });
  }

  handleBack = () => {
    this.props.history.goBack();
  }

  get userId() {
    return this.props.match.params.id;
  }

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('senha')) {
      callback('Senhas não são iguais!');
    } else {
      callback();
    }
  };

  render() {
    const id = this.userId;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 7 },
      wrapperCol: { span: 10 }
    };
    
    return (
      <div>
        <ContentTitle hasBack>
          Atualizar Usuário
        </ContentTitle>
        <Form onSubmit={this.handleSubmit}>
          <Card title='Dados do Usuário' bordered={false}>
          <FormItem
            {...formItemLayout}
            label="Nome"
          >
            {getFieldDecorator('nome', {
              rules: [{ required: false, message: 'Por favor o nome.' }],
            })(
              <Input disabled={true} />
            )}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label="Login"
          >
            {getFieldDecorator('login', {
              rules: [{ required: false, message: 'Por favor informe o login.' }],
            })(
              <Input disabled={true} />
            )}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label="CPF"
          >
            {getFieldDecorator('cpf', {
              rules: [{ required: false, message: 'Por favor informe o CPF.' }],
            })(
              <Input disabled={true} />
            )}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label="Nova senha"
          >
            {getFieldDecorator('senha', {
              rules: [{ required: true, message: 'Por favor informe a nova senha.' }],
            })(
              <Input.Password maxLength={10} />
            )}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label="Confirme a senha"
          >
            {getFieldDecorator('confirm', {
              rules: [
                { required: true, message: 'Por favor confirme a nova senha.' 
              },
              {
                validator: this.compareToFirstPassword,
              },
            ],
            })(
              <Input.Password maxLength={10} />
            )}
          </FormItem>
          </Card>
          
          <FormItem>
            <center>
              <Button type="primary" htmlType="submit">{id ? 'Atualizar' : 'Salvar'}</Button>
              {'    '}
              <Button onClick={this.handleBack}>Cancelar</Button>
            </center>
          </FormItem>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => ({ 
  usuario: state.user.usuario,
  user: state.login.user
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
  updateUsuario, 
  getUsuario
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(UserForm));
