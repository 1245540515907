const prefixByType = (type) => {
  switch (type) {
    case 'image/png':
      return 'data:image/png;base64,'
    case 'image/jpeg':
      return 'data:image/jpeg;base64,' 
    case 'application/pdf':
      return 'data:application/pdf;base64,'

    default:
      break;
  }
}

export const base64src = (base64, type) => `${prefixByType(type)}${base64}`;