import React from 'react';
import { ConfigProvider } from 'antd';
import pt_BR from 'antd/lib/locale-provider/pt_BR'
import 'moment/locale/pt-br'
import 'antd/dist/antd.css';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { applyMiddleware, createStore } from 'redux';
import { Provider } from 'react-redux';
import promise from 'redux-promise';
import multi from 'redux-multi';
import thunk from 'redux-thunk';

import reducers from './main/reducers';
import App from './main/App';

const store = applyMiddleware(multi, thunk, promise)(createStore)(reducers)
ReactDOM.render(
	<Provider store={store}>
		<ConfigProvider locale={pt_BR}>
			<App />
		</ConfigProvider>
	</Provider>
	, document.getElementById('root'));

serviceWorker.unregister();
