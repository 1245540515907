import React from 'react';
import { Modal, Checkbox, Button, List, Divider, Tooltip, Icon, message } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { verifyProfile } from '../../UI/util/verifyProfile';
import { updateUsuario } from '../Redux/actions';

const CheckboxGroup = Checkbox.Group;
const profiles = [2, 3];

class ManageProfileModal extends React.Component {

  static propTypes = {
    usuario: PropTypes.object
  };

  state = {
    checked: []
  }

  onChange = (checked) => {
    this.setState({ checked });
  }

  addProfile = (e) => {
    const { usuario, updateUsuario, user } = this.props;
    const { checked } = this.state;
    let values = JSON.parse(JSON.stringify(usuario));

    if (checked === undefined || checked.length === 0) return message.warning('Nenhum perfil selecionado!');
    if(usuario.id === user.id && user.isAdmin) return message.warning("Você já tem perfil de acesso geral ao sistema!");

    values.perfis.push(...checked);
    updateUsuario(values);
    this.setState({ checked: [] });
  }

  removeProfile(position) {
    const { usuario, updateUsuario } = this.props;
    const values = JSON.parse(JSON.stringify(usuario));
    values.perfis.splice(position, 1);
    updateUsuario(values);
  }

  render() {
    const { visible, handleOk, handleCancel, usuario, user } = this.props;
    const profilesCheckBox = profiles.map(p => (
      { label: verifyProfile(p), value: p }
      ));
    return (
      <React.Fragment>
        <Modal
          //width='600'
          destroyOnClose={true}
          title={`Gerenciador de perfis de acesso`}
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          cancelButtonProps={{ disabled: true }}
          okText='OK'
        >
          <CheckboxGroup
            options={profilesCheckBox} 
            onChange={this.onChange}
          />
          <br/>
          <Button 
            style={{ marginLeft: '420px' }} 
            type="primary" 
            shape="circle" 
            icon="plus" 
            onClick={this.addProfile}/>
          <Divider type="horizontal" />

          <h3>Perfis do {usuario.pessoa.nome}:</h3>
          <List
            className="demo-loadmore-list"
            itemLayout="horizontal"
            dataSource={usuario.perfis}
            renderItem={(item, index) => (
              <List.Item actions={[
                user.id !== usuario.id &&
                <Tooltip title={`Remover`}>
                    <Icon type="delete" theme="twoTone" onClick={() => this.removeProfile(index)}/>
                </Tooltip> 
              ]}>
                <List.Item.Meta
                  title={verifyProfile(item)}
                />
              </List.Item>
            )}
          /> 
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({ 
  user: state.login.user
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
  updateUsuario
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ManageProfileModal);
