import React from 'react';
import { Form, Button, Card, message, DatePicker, Select } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import debounce from 'lodash.debounce';

import { addAtendimento, updateAtendimento, clearAtendimento } from './Redux/actions';
import { getPacientes, searchPaciente } from '../Paciente/Redux/actions';
import { getServicos } from '../Servico/Redux/actions';
import { ContentTitle } from '../UI/ContentTitle/ContentTitle';
import { InputCurrencyBRL } from '../UI/NumericInput/InputCurrencyBRL';
import { formatToUsd } from '../UI/util/FormatToUsd';

const FormItem = Form.Item;
const { Option } = Select;

class CargoForm extends React.Component {

  state = {
    dataAtendimento: '',
    services: [],
    searchText: ''
  }
  
  componentDidMount() {    
    const { form, atendimento, getServicos, getPacientes, consultorioActive } = this.props;
    const consultorio = consultorioActive.id ? consultorioActive.id : '';
    getServicos({ consultorio, page: 0, linesPerPage: 45 });
    getPacientes({ consultorio, page: 0, linesPerPage: 24 });
    const id = this.atendimentoId;

    if (id) {
      form.setFieldsValue({
        dataAtendimento: moment(atendimento.dataAtendimento),
        paciente: atendimento.paciente.id,
        servicos: (atendimento.servicos || []).map(s => s.id),
        valor: atendimento.valor
      });
      
    }

  }

  componentWillUnmount() {      
    this.props.clearAtendimento();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { atendimento, consultorioActive } = this.props;

        values.consultorio = {id: consultorioActive.id};

        values.paciente = {id: values.paciente};
        values.servicos = values.servicos.map(servico => ({id: servico}));
        values.valor = formatToUsd(values.valor)

        const atendimentoId = this.atendimentoId;
        if (atendimentoId) {
          values.id = atendimentoId;
          values.createdDate = atendimento.createdDate;
          this.props.updateAtendimento(values, consultorioActive);
        } else {
          this.props.addAtendimento(values, consultorioActive);
        }
        this.props.history.replace('/atendimento/list');
      }
    });
  }

  handleBack = () => {
    this.props.history.goBack();
  }

  get atendimentoId() {
    return this.props.match.params.id;
  }

  handleDate = (dataAtendimento) => {
    const today = moment(new Date(), 'DD/MM/YYYY');
    if (dataAtendimento > today) {
      message.warning('Não pode lançar um atendimento no futuro!');
      this.setState({ dataAtendimento: today });
      setTimeout(() => {
        this.props.form.setFieldsValue({ dataAtendimento: today });
      }, 400);
    } else this.setState({ dataAtendimento });
  }

  handleChangeServices = (services) => {
    //console.log('services', services)
  }

  handleSearch = (value) => {
    this.setState({ searchText: value }, () => {
      if (this.state.searchText)
        this.searchPacientes();
    });
  };

  searchPacientes = debounce(() => {
    const { searchText } = this.state;
    const { consultorioActive } = this.props;
    const consultorio = consultorioActive.id ? consultorioActive.id : '';
    this.props.searchPaciente({ text: searchText, consultorio });
  }, 500);

  render() {
    const id = this.cargoId;
    const { pacientes, servicos } = this.props;
    const title = id ? 'Atualizar Atendimento' : 'Novo Atendimento'
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 7 },
      wrapperCol: { span: 10 }
    };
    
    return (
      <React.Fragment>
        <ContentTitle hasBack>
          { title }
        </ContentTitle>
        <Form onSubmit={this.handleSubmit}>
          <Card title='Dados do Atendimento' bordered={false}>
          <FormItem
            {...formItemLayout}
            label="Data"
          >
            {getFieldDecorator('dataAtendimento', {
              initialValue: moment(new Date(), 'DD/MM/YYYY'),
              rules: [{ required: true, message: 'Por favor informe a data.' }],
            })(
              <DatePicker onChange={this.handleDate} format="DD/MM/YYYY" />
            )}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label="Paciente"
          >
            {getFieldDecorator('paciente', {
              rules: [{ required: true, message: 'Por favor informe o(s) serviço(s)' }],
            })(
              <Select
                showSearch
                filterOption
                optionFilterProp="children"
                notFoundContent="Pacientes não encontrado"
                onSearch={this.handleSearch}
                placeholder="Selecione o paciente">
                { (pacientes.content || []).map(p => 
                  <Option 
                    key={p.id}
                    value={p.id}>
                    {p.nome}
                  </Option> 
                ) }
              </Select>
            )}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label="Serviço(s)"
          >
            {getFieldDecorator('servicos', {
              rules: [{ required: true, message: 'Por favor informe o(s) serviço(s)' }],
            })(
              <Select
                showSearch
                filterOption
                optionFilterProp="children"
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Selecione o(s) serviço(s)"
                onChange={this.handleChangeServices}>
                { (servicos.content || []).map(s => 
                    <Option 
                      key={s.id}
                      value={s.id}>
                      {s.nome}
                    </Option> 
                  ) }
              </Select>
            )}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label="Valor"
          >
            {getFieldDecorator('valor', {
              rules: [{ required: true, message: 'Por favor informe o valor' }],
            })(
              <InputCurrencyBRL />
            )}
          </FormItem>
          </Card>
          <FormItem>
            <center>
              <Button type="primary" htmlType="submit">{id ? 'Atualizar' : 'Salvar'}</Button>
              {'    '}
              <Button onClick={this.handleBack}>Cancelar</Button>
            </center>
          </FormItem>
        </Form>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({ 
  atendimento: state.atendimento.atendimento,
  user: state.login.user,
  pacientes: state.paciente.list,
  servicos: state.servico.list,
  consultorioActive: state.login.consultorioActive
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
  addAtendimento, 
  updateAtendimento, 
  clearAtendimento,
  getPacientes,
  getServicos,
  searchPaciente
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(CargoForm));
