import React from 'react';
import { Table, Divider, Icon, Popconfirm, Tooltip, Button, Input } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import debounce from 'lodash.debounce';

import { getCargo, getCargos, removeCargo, searchCargo } from './Redux/actions';
import { Link } from 'react-router-dom';

const Search = Input.Search;

const PAGE_SIZE = 15;

class ListCargos extends React.Component {
  
    state = {
      searchText: '',
      currentPage: 0
    }
    
    componentDidMount() {      
      this.getFirstPage();
    }

    handleSearch = (event) => {
      const searchText = event.target.value;
      this.setState({ searchText }, () => {
        if (this.state.searchText)
          this.searchCargos();
        else
          this.getFirstPage();
      });
    };

    searchCargos = debounce(() => {
      const { searchText } = this.state;
      const { consultorioActive } = this.props;
      const consultorio = consultorioActive.id ? consultorioActive.id : '';
      this.props.searchCargo({ text: searchText, consultorio });
    }, 500);

    handlePaginationChange = (page, pageSize) => {
      this.setState({ currentPage: page });
      this.props.getCargos({page: page - 1, linesPerPage: pageSize});
    };

    getFirstPage = () => {
      const { consultorioActive } = this.props;
      const consultorio = consultorioActive.id ? consultorioActive.id : '';
      this.props.getCargos({ consultorio, page: 0, linesPerPage: PAGE_SIZE });
    }
    
    render() {
      const { searchText, currentPage } = this.state;
      const { cargos, loading, getCargo, removeCargo, consultorioActive } = this.props;
      const data = (cargos.content || []).map(i => ({...i, key: i.id}));

      const columns = [{
        title: 'Nome',
        dataIndex: 'nome',
        key: 'nome',
        render: (_, cargo) => <Link to={`/cargo/detalhes/${cargo.id}`} onClick={() => getCargo(cargo)}>{cargo.nome}</Link>,
      }, {
        title: 'Ações',
        key: 'action',
        render: (_, cargo, record) => 
          <span>
            <Tooltip 
              title={`Editar ${cargo.nome}`}
              placement="bottom">
              <Link to={`/cargo/form/edit/${cargo.id}`}>
                <Icon type="edit" theme="twoTone" onClick={() => getCargo(cargo)}/>
              </Link>
            </Tooltip>
            <Divider type="vertical" />
            <Popconfirm
              title="Deseja remover?"
              okText="Remover"
              cancelText="Cancelar"
              onConfirm={() => removeCargo(cargo, consultorioActive)}>
              <Tooltip 
                placement="bottom"
                title={`Remover ${cargo.nome}`}>
                <Icon type="delete" theme="twoTone" />
              </Tooltip>
            </Popconfirm>
          </span>
        
      }];

      const pagination = (!searchText && 
        { 
          current: currentPage,
          defaultPageSize: PAGE_SIZE,
          pageSize: PAGE_SIZE,
          total: cargos.totalElements || 0,
          onChange: this.handlePaginationChange
        }
      ) || false;

      return (
          <React.Fragment>
            <Link to="/cargo/form/new">
              <Button icon="plus-square">
                Nova Cargo
              </Button>
            </Link>
              <h1 style={{ textAlign: 'center' }}>Cargos</h1>
              <Search
                placeholder="Buscar cargos"
                onChange={this.handleSearch} />
              <Table 
                pagination={pagination} 
                columns={columns} 
                dataSource={data} 
                loading={loading} 
              />
          </React.Fragment>
      );
  }

}

const mapStateToProps = state => ({ 
  cargos: state.cargo.list, 
  loading: state.cargo.loading,
  user: state.login.user,
  consultorioActive: state.login.consultorioActive,
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
  getCargo, 
  getCargos, 
  removeCargo, 
  searchCargo 
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ListCargos);
