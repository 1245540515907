//import { message } from 'antd';
import api from '../../../services/api';

export function getEstados() {
  const request = api.get(`estados`);
  return {
    type: 'ESTADOS_FETCHED',
    payload: request
  }
}

export function getCidadesFromEstado(id) {
  const request = api.get(`estados/${id}/cidades`);
  return {
    type: 'CIDADES_FROM_ESTADOS_FETCHED',
    payload: request
  }
}

export function getCep(cep) {
  const request = api.get(`https://viacep.com.br/ws/${cep}/json/`);
  return {
    type: 'CEP_FETCH',
    payload: request
  }
}