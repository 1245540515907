import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Icon, Tooltip } from 'antd';
import { withRouter } from 'react-router-dom';
import "./ContentTitle.css";
import '../PrintHeader/PrintHeader.css';

export const ContentTitle = 

//router
withRouter(

  class extends PureComponent {
    
    static propTypes = {
      hasBack: PropTypes.bool,
      onBack: PropTypes.func
    };

    render() {
      const { children, hasBack } = this.props;
      return (
        <h1 className="content-title">
          { hasBack && this.renderBack() }
          { children }
        </h1>
      );
    }

    renderBack() {
      return (
        <Tooltip 
          title="Voltar" 
          placement="bottom">
          <span className="content-title__back-icon">
            <Icon 
              className="no-print"
              onClick={this.handleBackClick}
              type="arrow-left" />
          </span>
        </Tooltip>
      );
    }

    handleBackClick = () => {
      const { onBack } = this.props;
      if (onBack) onBack();
      else this.back(); 
    };

    back = () => this.props.history.goBack();

  }
) //router
  