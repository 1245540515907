export const abbreviateName = (fullName) => {
  const nameSplit = fullName.split(" ");

  const firstName = nameSplit.shift();
  const middleName = nameSplit;
  const lastName = nameSplit.pop();

  const middleNameAbbreviate = middleName.map(name => name.length <= 3 ? null : `${name[0]}. `).join("")

  return firstName.concat(" ", middleNameAbbreviate, lastName)
}