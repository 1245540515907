const INITIAL_STATE = {
  estados: [],
  cidadesFromEstado: [],
  cep: {},
  loading: true
}
export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'ESTADOS_FETCHED':
      return { ...state, estados: action.payload.data, loading: false }
    case 'CIDADES_FROM_ESTADOS_FETCHED':
      return { ...state, cidadesFromEstado: action.payload.data, loading: false }
    case 'CEP_FETCH':
      return { ...state, cep: action.payload.data }

    default:
      return state
  }
}