import api from '../../../services/api';

export function getPeriodAtendimentos({ consultorio, inicio, fim } = {}) {
  const request = api.get(`atendimentos/financeiro`, {
    params: {
      consultorio,
      inicio,
      fim
    }
  });
  return {
    type: 'ATENDIMENTO_PERIOD',
    payload: request
  }
}

export function loadingFinanceiro(status) {
  return {
    type: 'LOADING_FINANCEIRO',
    payload: status
  }
}

export function clearFinanceiro() {
  return {
    type: 'FINANCEIRO_CLEAR',
    payload: null
  }
}
