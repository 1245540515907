import React from 'react'
import { Table, Divider, Icon, Popconfirm, Tooltip, Button, Input } from 'antd';
import { Link } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getConsultorios, removeConsultorio, searchConsultorio, getConsultorio } from './Redux/actions';

const Search = Input.Search;

const PAGE_SIZE = 15;

class ConsultorioList extends React.Component {
  
    state = {
      searchtext: '',
      currentPage: 0
    }

    componentDidMount() {
      this.getFirstPage();
    }

    handleSearch = (event) => {
      const searchText = event.target.value;
      this.setState({ searchText }, () => {
        if (this.state.searchText)
          this.searchConsultorio();
        else
          this.getFirstPage();
      });
    };

    searchConsultorio = debounce(() => {
    const { searchText } = this.state;
    this.props.searchConsultorio({ text: searchText })
  }, 500);

  handlePaginationChange = (page, pageSize) => {
    const { user } = this.props;
    this.setState({ currentPage: page });
    this.props.getConsultorios({ usuario: user.id, page: page - 1, linesPerPage: pageSize });
  };

  getFirstPage = () => {
    const { user } = this.props;
    this.props.getConsultorios({ usuario: user.id, page: 0, linesPerPage: PAGE_SIZE });
  }
    
    render() {
      const { searchText, currentPage } = this.state;
      const { consultorios, loading, removeConsultorio, getConsultorio, user } = this.props;
      const pj = (consultorios.content || []).map(i => i.pj)[0];
      const data = (consultorios.content || []).map(i => ({...i, key: i.id}));
      const { isSuper } = user;
      const title = isSuper ? 'Consultórios' : 'Consultório';
      const searchTitle = pj ?'Busca por razão social, nome fantasia ou CNPJ' : 'Busca por nome ou CPF'

      const columnsPJ = [{
        title: 'Razão Social',
        dataIndex: 'razaoSocial',
        key: 'razaoSocial',
        render: (_, consultorio) => {
            return <Link 
                    to={`/consultorio/detalhes/${consultorio.id}`} 
                    onClick={() => getConsultorio(consultorio)}>
                    {consultorio.razaoSocial}
                  </Link>
        },
      }, {
        title: 'Nome Fantasia',
        dataIndex: 'nomeFantasia',
        key: 'nomeFantasia',
      }, {
        title: 'CNPJ',
        dataIndex: 'cnpj',
        key: 'cnpj',
      },{
        title: 'E-mail',
        dataIndex: 'email',
        key: 'email',
      }, {
        title: 'Ações',
        key: 'action',
        render: (_, consultorio, record) => 
          <span>
            <Tooltip 
              title={`Editar`}
              placement="bottom">
              <Link to={`/consultorio/form/edit/${consultorio.id}`} onClick={() => getConsultorio(consultorio)}>
                <Icon type="edit" theme="twoTone"/>
              </Link>
            </Tooltip>
              <Divider type="vertical" />
              <Popconfirm
                title="Tem certeza que quer remover?"
                okText="Remover"
                cancelText="Cancelar"
                onConfirm={() => removeConsultorio(consultorio)}>
                <Tooltip 
                  placement="bottom"
                  title={`Remover`}>
                  <Icon type="delete" theme="twoTone" style={{ cursor: "pointer" }}/>
                </Tooltip>
              </Popconfirm>
          </span>
        
      }];

      const columnsPF = [{
        title: 'Nome',
        dataIndex: 'nome',
        key: 'nome',
        render: (_, consultorio) => {
            return <Link 
                    to={`/consultorio/detalhes/${consultorio.id}`} 
                    onClick={() => getConsultorio(consultorio)}>
                    {consultorio.nome}
                  </Link>
        },
      }, {
        title: 'CRO',
        dataIndex: 'cro',
        key: 'cro',
      }, {
        title: 'E-mail',
        dataIndex: 'email',
        key: 'email',
      }, {
        title: 'Ações',
        key: 'action',
        render: (_, consultorio, record) => 
          <span>
            <Tooltip 
              title={`Editar`}
              placement="bottom">
              <Link to={`/consultorio/form/edit/${consultorio.id}`} onClick={() => getConsultorio(consultorio)}>
                <Icon type="edit" theme="twoTone"/>
              </Link>
            </Tooltip>
              <Divider type="vertical" />
              <Popconfirm
                title="Tem certeza que quer remover?"
                okText="Remover"
                cancelText="Cancelar"
                onConfirm={() => removeConsultorio(consultorio)}>
                <Tooltip 
                  placement="bottom"
                  title={`Remover`}>
                  <Icon type="delete" theme="twoTone" style={{ cursor: "pointer" }}/>
                </Tooltip>
              </Popconfirm>
          </span>
        
      }];

      const pagination = (!searchText && 
        { 
          current: currentPage,
          defaultPageSize: PAGE_SIZE,
          pageSize: PAGE_SIZE,
          total: consultorios.totalElements || 0,
          onChange: this.handlePaginationChange
        }
      ) || false;

      return (
          <React.Fragment>
            { isSuper &&
              <Link to="/consultorio/form/new">
                <Button icon="plus-square">
                    Novo Consultório
                </Button>
              </Link>

            }
            <h1 style={{ textAlign: 'center' }}>{ title }</h1>
            { isSuper &&
              <Search
                placeholder={searchTitle}
                onChange={this.handleSearch} />
            }
              <Table 
                pagination={pagination} 
                columns={pj ? columnsPJ : columnsPF} 
                dataSource={data} 
                loading={loading} 
              />
          </React.Fragment>
      );
  }

}

const mapStateToProps = state => ({ 
  consultorios: state.consultorio.list,
  loading: state.consultorio.loading,
  user: state.login.user,
  consultorioActive: state.login.consultorioActive
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
  getConsultorios,
  removeConsultorio,
  searchConsultorio,
  getConsultorio
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ConsultorioList);
