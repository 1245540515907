import axios from 'axios';
import { message } from 'antd';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

api.defaults.headers.Authorization = localStorage.getItem('token');

api.interceptors.response.use(response => {
    // Do something with response data
    return response;
  }, (error) => {
      
    const status = error.response.data.status;
    const msg = error.response.data.message;
    
    if (status === 404) {
      if(msg === 'CPF') return;
        message.warning(msg);
    } else if(status === 401) {
      message.warning(error.response.data.message);
    } else if (status === 403) {
      message.warning(error.response.data.message);
    } else if (status === 500) {
        message.error('Ops, tivemos um problema. Tente novamente mais tarde!');
    }
    // Do something with response error
    return Promise.reject(error);
  });

export default api;