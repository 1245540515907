import React from 'react'
import { Card, Tag, Avatar } from 'antd';
import { connect } from 'react-redux';

import { ContentTitle } from '../UI/ContentTitle/ContentTitle';
import { verifyProfile } from '../UI/util/verifyProfile';
import MaleBlankAvatar from '../../assets/Male_Blank_Avatar.jpg';
import FemaleBlankAvatar from '../../assets/Female_Blank_Avatar.jpg';

class DetailsUser extends React.Component {

  render() {
    const { usuario } = this.props;
    const ativo = usuario.ativo ? <Tag color="blue">Ativo</Tag> : <Tag color="grey">Inativo</Tag>;
    const avatarGenre = usuario.pessoa.genero === "MASCULINO" ? MaleBlankAvatar : FemaleBlankAvatar;
    const avatar = <Avatar key={usuario.login} size={64} src={!usuario.imageUrl ? avatarGenre : usuario.imageUrl} />
    return (
      <center>
        <ContentTitle hasBack />
        <h1>Detalhes do Usuário</h1>
        <Card
          title={[avatar].concat(usuario.pessoa.nome)}
          extra={ativo}
          style={{ width: 900, textAlign: 'justify' }} >
          <p><b>Login: </b>{usuario.login}</p>
          <p><b>CPF: </b>{usuario.pessoa.cpf}</p>
          <p><b>Perfis: </b>{usuario.perfis.map(perfil => <Tag key={perfil} color="blue">{verifyProfile(perfil)}</Tag>)}</p>
        </Card>
      </center>

    );
  }

}

const mapStateToProps = state => ({ usuario: state.user.usuario });
export default connect(mapStateToProps)(DetailsUser);
