import React from 'react';
import { Input, Form, Button, Card, Select } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { addServico, updateServico, clearServico } from './Redux/actions';
import { getConsultorios } from '../Consultorio/Redux/actions';
import { ContentTitle } from '../UI/ContentTitle/ContentTitle';
import { toUpperCase } from '../UI/util/toUpperCase';

const FormItem = Form.Item;
const Option = Select.Option;

class ServicoForm extends React.Component {
  
  componentDidMount() {
    const { form, servico, user } = this.props;
    
    form.setFieldsValue({
      nome: servico.nome
    });

    if(user.isSuper) {
      this.props.getConsultorios();
    }
  }

  componentWillUnmount() {      
    this.props.clearServico();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { servico, consultorioActive } = this.props;
        
        values.consultorio = {id: consultorioActive.id};

        const servicoId = this.servicoId;
        if (servicoId) {
          values.id = servicoId;
          values.createdDate = servico.createdDate;
          this.props.updateServico(values, consultorioActive);
        } else {
          this.props.addServico(values, consultorioActive);
        }
        this.props.history.replace('/servico/list');
      }
    });
  }

  handleBack = () => {
    this.props.history.goBack();
  }

  get servicoId() {
    return this.props.match.params.id;
  }

  render() {
    const id = this.servicoId;
    const { user, consultorios } = this.props;
    const title = id ? 'Atualizar Serviço' : 'Cadastro de Serviço';
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 7 },
      wrapperCol: { span: 10 }
    };
    const consultorioList = consultorios.content || [];
    
    return (
      <React.Fragment>
        <ContentTitle hasBack>
          { title }
        </ContentTitle>
        <Form onSubmit={this.handleSubmit}>
          <Card title='Dados do Serviço' bordered={false}>
          <FormItem
            {...formItemLayout}
            label="Nome"
          >
            {getFieldDecorator('nome', {
              rules: [{ required: true, message: 'Por favor informe o serviço.' }],
            })(
              <Input onChange={toUpperCase} maxLength={35} placeholder="Ex.: Restauração" />
            )}
          </FormItem>
          { user.isSuper &&
            <FormItem
              {...formItemLayout}
              label="Consultório"
            >
              {getFieldDecorator('consultorio', {
                rules: [{ required: true, message: 'Por favor informe o consultório desse cargo!' }],
              })(
                <Select
                  notFoundContent="Consultórios não encontrado" 
                  placeholder="Selecione o consultório">
                  { consultorioList.map(i => 
                      <Option 
                        key={i.id}
                        value={i.id}>
                        {i.razaoSocial || i.nome}
                      </Option> 
                    ) }
                </Select>
              )}
            </FormItem>
          }
          </Card>
          
          <FormItem>
            <center>
              <Button type="primary" htmlType="submit">{id ? 'Atualizar' : 'Salvar'}</Button>
              {'    '}
              <Button onClick={this.handleBack}>Cancelar</Button>
            </center>
          </FormItem>
        </Form>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({ 
  servico: state.servico.servico,
  user: state.login.user,
  consultorios: state.consultorio.list,
  consultorioActive: state.login.consultorioActive
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
  addServico, 
  updateServico, 
  clearServico,
  getConsultorios
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(ServicoForm));
