import React from 'react'
import { Card, Skeleton } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getPacienteById, loadingOdontogramaFunc } from './Redux/actions'
import { ContentTitle } from '../UI/ContentTitle/ContentTitle';
import Odontograma from './Odontograma/Odontograma';
import ReactToPrint from 'react-to-print';
import { ButtonPrint } from '../UI/ButtonPrint/ButtonPrint';
import { PrintHeader } from '../UI/PrintHeader/PrintHeader';

let array = [];
for (let i = 0; i < 22; i++) {
  array.push(800);
}

class DetailsPaciente extends React.Component {

  componentDidMount() {
    const id = this.pacienteId;
    this.props.loadingOdontogramaFunc(true);
    this.props.getPacienteById(id);
  }

  get pacienteId() {
    return this.props.match.params.id;
  }

  verificaGenero() {
    const { paciente } = this.props;
    const { pessoa } = paciente;

    return pessoa.genero === 'MASCULINO' ? 'Masculino' : 'Feminino';
  }

  formatReal = (int) => {
    let tmp = int + '';
    tmp = tmp.replace(/([0-9]{2})$/g, ",$1");
    if (tmp.length > 6)
      tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");

    return tmp;
  }

  render() {
    const { paciente, loadingOdontograma, consultorioActive } = this.props;
    const { pessoa, arquivos, odontograma } = paciente;
    const { endereco } = pessoa;
    const { cidade } = endereco;
    const { estado } = cidade;
    return (
      <center>
        <ContentTitle hasBack />
        <PrintHeader consultorio={consultorioActive} />
        <h1>Detalhes do Paciente</h1>
        <Skeleton paragraph={{ rows: 15, width: array }} loading={loadingOdontograma} active>
          <Card
            title={<strong>{pessoa.nome}</strong>}
            style={{ width: 900, textAlign: 'justify' }}
            extra={
              <ReactToPrint
                trigger={() => <ButtonPrint style={{ fontSize: '25px', height: '1em' }} />}
                content={() => this}
              />
            }>
            <Card title='Dados Pessoais' bordered={false}>
              <p><strong>CPF: </strong>{pessoa.cpf}</p>
              <p><strong>Data de Nascimento: </strong>{moment(pessoa.dataNascimento).format('DD/MM/YYYY')}</p>
              <p><strong>Idade: </strong>{moment().diff(pessoa.dataNascimento, 'years') + ' anos'}</p>
              <p><strong>Gênero: </strong>{this.verificaGenero(pessoa.genero)}</p>
            </Card>
            <Card title='Contato' bordered={false}>
              <p><strong>Telefone: </strong>{pessoa.telefone}</p>
              <p><strong>Celular: </strong>{pessoa.celular}</p>
              <p><strong>E-mail: </strong>{pessoa.email}</p>
            </Card>
            <Card title='Endereço' bordered={false}>
              <p><strong>Rua: </strong>{endereco.logradouro}</p>
              <p><strong>Número: </strong>{endereco.numero}</p>
              <p><strong>Complemento: </strong>{endereco.complemento}</p>
              <p><strong>Bairro: </strong>{endereco.bairro}</p>
              <p><strong>Cidade: </strong>{cidade.nome}</p>
              <p><strong>Estado: </strong>{estado.nome}</p>
              <p><strong>CEP: </strong>{endereco.cep}</p>
            </Card>
            <div className="page-break">
              <center>
                <PrintHeader consultorio={consultorioActive} />
              </center>
              <Card title='Odontograma' bordered={false}>
                  <Odontograma odontograma={odontograma} toothAndColor={() => {}} />
              </Card>
            </div>
            <Card title='Arquivos' bordered={false}>
              {
                arquivos.map(arquivo => {
                  return (
                    <p key={arquivo.id}><strong>Nome: </strong>
                      <a 
                        key={arquivo.id} 
                        href={arquivo.arquivoUrl} 
                        target="_blank" rel="noopener noreferrer">{arquivo.nome}</a>
                    </p>
                  );
                })
              }
            </Card>
          </Card>
        </Skeleton>
        <br />
      </center>

    );
  }
}

const mapStateToProps = state => ({ 
  paciente: state.paciente.paciente,
  loadingOdontograma: state.paciente.loadingOdontograma,
  consultorioActive: state.login.consultorioActive
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
  getPacienteById,
  loadingOdontogramaFunc
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(DetailsPaciente);
