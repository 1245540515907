export const formatToUsd = (value) => {

  if (typeof value === 'number') {
    return value;
  }

  value = value.replace('.', '');
  value = value.replace(',', '.');
  value = parseFloat(value)
    
  return value;
}