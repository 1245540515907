import api from '../../../services/api';
import { message } from 'antd';

export function signIn(values) {
  return dispatch => {
    dispatch([
      loadingUpdate(true),
      submit(values, 'post')
    ])
  }
}

export function forgot(values) {
  return dispatch => {
    api.post(`auth/forgot`, values).then(resp => {
      message.success('Uma nova senha foi enviada para seu e-mail!')  
      dispatch([
        loadingUpdate(false)
        ])
      })
  }
}

export function getUser(id) {
  return dispatch => {
    api.get(`usuarios/${id}`).then(resp => {
        dispatch([
          userUpdate(resp.data)
        ])
      }).catch(error => {
        if (error.response.status === 403) {
          message.warning('Seu login expirou!')
          dispatch([
            logout()
          ])
        }
      })
  }
}

function userUpdate(values) {
  return {
    type: 'USER_FETCHED',
    payload: values
  }
}

export function logout() {
  return {
    type: 'LOGOUT',
    payload: null
  }
}

export function signInSuccess(data) {
  return {
    type: 'SIGN_IN_SUCCSESS',
    payload: data
  }
}

export function signFailure() {
  return {
    type: 'SIGN_IN_FAILURE'
  }
}

export function validateToken(values) {
  return {
    type: 'VALIDATE_TOKEN',
    payload: values
  }
}

function submit(values, method) {
  return dispatch => {
    api[method](`login`, values)
      .then(resp => {
        api.defaults.headers.Authorization = resp.headers.authorization
        dispatch([
          signInSuccess({token: resp.headers.authorization, userId: resp.data})
        ])
      }).catch(error => {
        dispatch([
          loadingUpdate(false)
        ])
      })
  }
}

export function loadingUpdate(status) {
  return {
    type: 'LOADING_LOGIN',
    payload: status
  }
}

export function getConsultorio(value) {
  return {
    type: 'UPDATE_CONSULTORIO_LOGGED',
    payload: value
  }
}