import React from 'react'

import './Odontograma.css'

import ToothPopoverTop from './ToothPopoverTop';
import ToothPopoverBottom from './ToothPopoverBottom';
import ToothPopoverLeft from './ToothPopoverLeft';
import ToothPopoverMiddle from './ToothPopoverMiddle';
import ToothPopoverRight from './ToothPopoverRight';

import ToothPopoverX from './ToothPopoverX';

export default class ToothPopover extends React.Component {

  render() {
    const { left, toothNumber, src, numberDown, click } = this.props;

    if (numberDown) {
      if (src.centro === 7 || src.centro === 8 || src.centro === 9 || src.centro === 10 || src.centro === 11) {
        return (
          <div className="tooth-content">
            <ToothPopoverX 
              click={click}
              toothNumber={toothNumber}
              toothAndColor={(obj) => this.props.toothAndColor(obj)} 
              src={src.centro} />
            <span>{toothNumber}</span>
          </div>
        );
      }
      return (
        <React.Fragment>
          <div className="tooth-content">
            <ToothPopoverTop 
              titleTooltip="Vestibular"
              click={click}
              toothNumber={toothNumber}
              toothAndColor={(obj) => this.props.toothAndColor(obj)} 
              src={src.superior} />
            <ToothPopoverLeft 
              titleTooltip={left ? "Distal" : "Mesial"}
              click={click}
              toothNumber={toothNumber}
              toothAndColor={(obj) => this.props.toothAndColor(obj)} 
              src={src.esquerdo} />
            <ToothPopoverMiddle 
              titleTooltip="Oclusal"
              click={click}
              toothNumber={toothNumber}
              toothAndColor={(obj) => this.props.toothAndColor(obj)}
              src={src.centro} />
            <ToothPopoverRight
              titleTooltip={left ? "Mesial" : "Distal"}
              click={click}
              toothNumber={toothNumber}
              toothAndColor={(obj) => this.props.toothAndColor(obj)}
              src={src.direito} />
            <ToothPopoverBottom 
              titleTooltip="Palatina/Lingual"
              click={click}
              toothNumber={toothNumber}
              toothAndColor={(obj) => this.props.toothAndColor(obj)}
              src={src.baixo} />
          </div>
          <span>{toothNumber}</span>
        </React.Fragment>
      );
    }

    if (src.centro === 7 || src.centro === 8 || src.centro === 9 || src.centro === 10 || src.centro === 11) {
      return (
        <div className="tooth-content">
          <span>{toothNumber}</span>
          <ToothPopoverX 
            click={click}
            toothNumber={toothNumber}
            toothAndColor={(obj) => this.props.toothAndColor(obj)} 
            src={src.centro} />
        </div>
      );
    }
    return (
      <React.Fragment>
        <span>{toothNumber}</span>
        <div className="tooth-content">
          <ToothPopoverTop 
            titleTooltip="Vestibular"
            click={click}
            toothNumber={toothNumber}
            toothAndColor={(obj) => this.props.toothAndColor(obj)} 
            src={src.superior} />
          <ToothPopoverLeft 
            titleTooltip={left ? "Distal" : "Mesial"}
            click={click}
            toothNumber={toothNumber}
            toothAndColor={(obj) => this.props.toothAndColor(obj)} 
            src={src.esquerdo} />
          <ToothPopoverMiddle 
            titleTooltip="Oclusal"
            click={click}
            toothNumber={toothNumber}
            toothAndColor={(obj) => this.props.toothAndColor(obj)}
            src={src.centro} />
          <ToothPopoverRight
            titleTooltip={left ? "Mesial" : "Distal"}
            click={click}
            toothNumber={toothNumber}
            toothAndColor={(obj) => this.props.toothAndColor(obj)}
            src={src.direito} />
          <ToothPopoverBottom 
            titleTooltip="Palatina/Lingual"
            click={click}
            toothNumber={toothNumber}
            toothAndColor={(obj) => this.props.toothAndColor(obj)}
            src={src.baixo} />
        </div>
      </React.Fragment>
    );
  }

}
