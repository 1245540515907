import React from 'react';
import { Switch } from 'react-router-dom';

import LayoutApp from '../template/LayoutApp';

import Route from './Route';

import SignIn from '../components/Login/SignIn';
import Forgot from '../components/Login/Forgot';

import PageNotFound from '../components/PageNotFound/PageNotFound';

import Dashboard from '../components/Home/Dashboard';

import ConsultorioList from '../components/Consultorio/List';
import ConsultorioForm from '../components/Consultorio/Form';
import ConsultorioDetails from '../components/Consultorio/Details';

import FuncionariosList from '../components/Funcionario/List';
import FuncionarioForm from '../components/Funcionario/Form';
import FuncionarioDetails from '../components/Funcionario/Details';

import CargosList from '../components/Cargo/List';
import CargoForm from '../components/Cargo/Form';
import DetailsCargo from '../components/Cargo/Details';

import ServicosList from '../components/Servico/List';
import ServicoForm from '../components/Servico/Form';
import ServicoDetails from '../components/Servico/Details';

import PacienteList from '../components/Paciente/List';
import PacienteForm from '../components/Paciente/Form';
import PacienteDetails from '../components/Paciente/Details';
import PacientePrint from '../components/Paciente/Print';

import AtendimentoList from '../components/Atendimento/List';
import AtendimentoForm from '../components/Atendimento/Form';
import AtendimentoDetails from '../components/Atendimento/Details';

import FinanceiroList from '../components/Financeiro/List';

import UsuarioList from '../components/User/List';
import UsuarioForm from '../components/User/Form';
import UsuarioDetails from '../components/User/Details';

export default props =>
  <Switch>
    <Route exact path="/" component={SignIn} />
    <Route path="/esqueci-senha" component={Forgot} />

    <LayoutApp>
      <Route path="/dashboard" component={Dashboard} isPrivate />

      <Route path="/consultorio/list/" component={ConsultorioList} isPrivate />
      <Route path="/consultorio/detalhes/:id" component={ConsultorioDetails} isPrivate />
      <Route path="/consultorio/form/(new|edit)/:id?" component={ConsultorioForm} isPrivate />

      <Route path="/funcionario/form/(new|edit)/:id?" component={FuncionarioForm} isPrivate />
      <Route path="/funcionarios/list/" component={FuncionariosList} isPrivate />
      <Route path="/funcionario/detalhes/:id" component={FuncionarioDetails} isPrivate />

      <Route path="/cargo/list/" component={CargosList} isPrivate />
      <Route path="/cargo/detalhes/:id" component={DetailsCargo} isPrivate />
      <Route path="/cargo/form/(new|edit)/:id?" component={CargoForm} isPrivate />

      <Route path="/servico/list/" component={ServicosList} isPrivate />
      <Route path="/servico/detalhes/:id" component={ServicoDetails} isPrivate />
      <Route path="/servico/form/(new|edit)/:id?" component={ServicoForm} isPrivate />

      <Route path="/paciente/list/" component={PacienteList} isPrivate />
      <Route path="/paciente/detalhes/:id" component={PacienteDetails} isPrivate />
      <Route path="/paciente/form/(new|edit)/:id?" component={PacienteForm} isPrivate />
      <Route path="/paciente/impressao/:id" component={PacientePrint} isPrivate />

      <Route path="/atendimento/list/" component={AtendimentoList} isPrivate />
      <Route path="/atendimento/detalhes/:id" component={AtendimentoDetails} isPrivate />
      <Route path="/atendimento/form/(new|edit)/:id?" component={AtendimentoForm} isPrivate />

      <Route path="/financeiro/consulta/" component={FinanceiroList} isPrivate />

      <Route path="/usuario/list/" component={UsuarioList} isPrivate />
      <Route path="/usuario/detalhes/:id" component={UsuarioDetails} isPrivate />
      <Route path="/usuario/form/(new|edit)/:id?" component={UsuarioForm} isPrivate />


      <Route path="/" component={PageNotFound} />
    </LayoutApp>

  </Switch>
