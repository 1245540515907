import { message } from 'antd';

import api from '../../../services/api';

export function getFuncionarioById(id) {
  const request = api.get(`funcionarios/${id}`);
  return {
    type: 'FUNCIONARIO_FETCH_ID',
    payload: request
  }
}

export function findPessoaByCpf({ cpf }) {
  return dispatch => {
    api.get(`pessoas/cpf?cpf=${cpf}`)
      .then(resp => {
        dispatch([
          findPessoa(resp.data)
        ])
      }).catch(error => {});
  }
}

export function findPessoa(values) {
  return {
    type: 'FUNCIONARIO_PESSOA_FETCHED',
    payload: values
  }
}

export function getFuncionario(values) {
  return {
    type: 'FUNCIONARIO_FETCH',
    payload: values
  }
}

export function getFuncionarios({ consultorio, page, linesPerPage } = {}) {
  const request = api.get(`funcionarios`, {
    params: {
      consultorio,
      page,
      linesPerPage
    }
  });
  return {
    type: 'FUNCIONARIO_FETCHED',
    payload: request
  }
}

export function searchFuncionario({ text, consultorio } = {}) {
  const request = api.get(`funcionarios/search`, {
    params: {
      text,
      consultorio
    }
  });
  return {
    type: 'FUNCIONARIO_SEARCH',
    payload: request
  }
}

export function addFuncionario(values, param) {
  return submit(values, 'post', param)
}

export function updateFuncionario(values, param) {
  return submit(values, 'put', param)
}

export function removeFuncionario(values, param) {
  return submit(values, 'delete', param)
}

function submit(values, method, param) {
  return dispatch => {
    const id = values.id ? values.id : ''
    const consultorio = param.id ? param.id : ''
    api[method](`funcionarios/${id}`, values)
      .then(resp => {
        message.success('Operação realizada com sucesso!')
        dispatch([
          getFuncionarios({ consultorio, page: 0, linesPerPage: 15 }),
          //updateStateFuncionario(resp.data)
        ])
      });
  }
}

export function clearFuncionario() {
  return {
    type: 'FUNCIONARIO_CLEAR',
    payload: null
  }
}

export function updateStateFuncionario(values) {
  return {
    type: 'UPDATE_FUNCIONARIO',
    payload: values
  }
}