import React from 'react'
import { Table, Divider, Icon, Popconfirm, Tooltip, Button, Input, message  } from 'antd';
import { Link } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getFuncionarios, getFuncionario, getFuncionarioById, searchFuncionario, removeFuncionario, updateFuncionario } from './Redux/actions'

const Search = Input.Search;

const PAGE_SIZE = 15;

class ListFuncionarios extends React.Component {
  
    state = {
        visible: false,
        marcados: {},
        searchText: '',
        currentPage: 0
    }

    componentDidMount() {
      this.getFirstPage();
    }

    handleSearch = (event) => {
      const searchText = event.target.value;
      this.setState({ searchText }, () => {
        if (this.state.searchText)
          this.searchFuncionarios();
        else
          this.getFirstPage();
      });
    };

    searchFuncionarios = debounce(() => {
      const { consultorioActive } = this.props;
      const consultorio = consultorioActive.id ? consultorioActive.id : '';
      const { searchText } = this.state;
      this.props.searchFuncionario({ text: searchText, consultorio });
    }, 500);

    onChange = (checkedValues) => {
      const checked = checkedValues.map(checked => ({ id: checked }));
      this.setState({ marcados: checked });
    }

    formatReal = (int) => {
      let tmp = int+'';
      tmp = tmp.replace(/([0-9]{2})$/g, ",$1");
      if( tmp.length > 6 )
          tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
  
      return tmp;
    }

    handlePaginationChange = (page, pageSize) => {
      const { consultorioActive } = this.props;
      const consultorio = consultorioActive.id ? consultorioActive.id : '';
      this.setState({ currentPage: page });
      this.props.getFuncionarios({ consultorio, page: page - 1, linesPerPage: pageSize });
    };

    getFirstPage = () => {
      const { consultorioActive } = this.props;
      const consultorio = consultorioActive.id ? consultorioActive.id : '';
      this.props.getFuncionarios({ consultorio, page: 0, linesPerPage: PAGE_SIZE });
    }

    handleRemoveFuncioario = (funcionario, user) => {
      if(funcionario.pessoa.id === user.pessoa.id) return message.warning("Você não pode excluir a si próprio!");
      this.props.removeFuncionario(funcionario, user);
    }
    
    render() {
      const { searchText, currentPage } = this.state;
      const { loading, funcionarios, getFuncionario, consultorioActive } = this.props;
      const data = (funcionarios.content || []).map(i => ({...i, key: i.id}));

      const columns = [{
        title: 'Nome',
        dataIndex: 'pessoa.nome',
        key: 'pessoa.nome',
        render: (_, funcionario) => <Link to={`/funcionario/detalhes/${funcionario.id}`} onClick={() => getFuncionario(funcionario)}>{funcionario.pessoa.nome}</Link>,
      }, {
        title: 'Cargo',
        dataIndex: 'cargo.nome',
        key: 'cargo.nome',
      }, {
        title: 'CPF',
        dataIndex: 'pessoa.cpf',
        key: 'pessoa.cpf',
      }, {
        title: 'Salário',
        dataIndex: 'valorSalario',
        key: 'valorSalario',
        render: (_, funcionario) => funcionario.valorSalario.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
      }, {
        title: 'Ações',
        key: 'action',
        render: (_, funcionario, record) => 
          <span>
            <Tooltip 
              title={`Editar`}
              placement="bottom">
              <Link to={`/funcionario/form/edit/${funcionario.id}`} onClick={() => getFuncionario(funcionario)} >
                <Icon type="edit" theme="twoTone" />
              </Link>
            </Tooltip>
            <Divider type="vertical" />
            <Popconfirm
              title="Tem certeza que quer remover?"
              okText="Remover"
              cancelText="Cancelar"
              onConfirm={() => this.handleRemoveFuncioario(funcionario, consultorioActive)}>
              <Tooltip 
                placement="bottom"
                title={`Remover`}>
                <Icon type="delete" theme="twoTone" style={{ cursor: "pointer" }}/>
              </Tooltip>
            </Popconfirm>
          </span>
        
      }];

      const pagination = (!searchText && 
        { 
          current: currentPage,
          defaultPageSize: PAGE_SIZE,
          pageSize: PAGE_SIZE,
          total: funcionarios.totalElements || 0,
          onChange: this.handlePaginationChange
        }
      ) || false;

        return (
            <div>
              <Link to="/funcionario/form/new">
                <Button icon="plus-square">
                  Novo Funcionário
                </Button>
              </Link>
                <h1 style={{ textAlign: 'center' }}>Funcionários</h1>
                <Search
                  placeholder="Buscar funcionário por Nome ou CPF"
                  onChange={this.handleSearch} />
                <Table 
                  pagination={pagination} 
                  columns={columns} 
                  dataSource={data} 
                  loading={loading} />
            </div>
        );
    }

}

const mapStateToProps = state => ({ 
  funcionarios: state.funcionario.list,
  funcionario: state.funcionario.funcionario,
  loading: state.funcionario.loading,
  user: state.login.user,
  consultorioActive: state.login.consultorioActive
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
  getFuncionarios,
  getFuncionario,
  getFuncionarioById,
  updateFuncionario,
  searchFuncionario,
  removeFuncionario
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ListFuncionarios);
