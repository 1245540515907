const INITIAL_STATE = {
  usuario: {
    pessoa: {
      nome: ''
    },
    perfis: []
  },
  list: { content: [] },
  loading: true,
  visible: false,
  visibleClinic: false
}
export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'USERS_FETCHED':
      return { 
        ...state, 
        list: action.payload.data ? action.payload.data : { content: [] }, 
        loading: false 
      }
    case 'USUARIO_FETCH':
      return { ...state, usuario: action.payload }
    case 'USERS_SEARCH':
      return { 
        ...state, 
        list: {
          ...state.list,
          content: [
            ...action.payload.data.content.filter(user => user.pessoa.nome !== 'Super Usuário')
          ]
        } 
      }
    case 'UPADTE_LIST_USERS':
      return { 
        ...state, 
        list: {
          ...state.list,
          content: [
            ...state.list.content.map(user => (user.id === action.payload.id) ? user = action.payload : user)
          ]
        } 
      }
    case 'MANAGE_PROFILE_MODAL':
      return {
        ...state,
        visible: action.payload
      }
    case 'MANAGE_CLINIC_MODAL':
      return {
        ...state,
        visibleClinic: action.payload
      }
    case 'CLEAR_USUARIO':
      return {
        ...state,
        usuario: INITIAL_STATE.usuario
      }
    case 'UPADTE_USER':
      return {
        ...state,
        usuario: action.payload
      }

    default:
      return state
  }
}