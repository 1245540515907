import { message } from 'antd';

import api from '../../../services/api';

export function getCargoById(id) {
  const request = api.get(`cargos/${id}`);
  return {
    type: 'CARGO_FETCH_ID',
    payload: request
  }
}

export function getCargo(values) {
  return {
    type: 'CARGO_FETCH',
    payload: values
  }
}

export function getCargos({ consultorio, page, linesPerPage } = {}) {
  const request = api.get(`cargos`, {
    params: {
      consultorio,
      page,
      linesPerPage
    }
  });
  return {
    type: 'CARGO_FETCHED',
    payload: request
  }
}

export function searchCargo({ text, consultorio } = {}) {
  const request = api.get(`cargos/search`, {
    params: {
      text,
      consultorio
    }
  });
  return {
    type: 'CARGO_SEARCH',
    payload: request
  }
}

export function addCargo(values, param) {
  return submit(values, 'post', param)
}

export function updateCargo(values, param) {
  return submit(values, 'put', param)
}

export function removeCargo(values, param) {
  return submit(values, 'delete', param)
}

function submit(values, method, param) {
  return dispatch => {
    const id = values.id ? values.id : ''
    const consultorio = param.id ? param.id : ''
    api[method](`cargos/${id}`, values)
      .then(resp => {
        message.success('Operação realizada com sucesso!')
        dispatch([
          getCargos({ consultorio, page: 0, linesPerPage: 15 })
        ])
      });
  }
}

export function clearCargo() {
  return {
    type: 'CARGO_CLEAR',
    payload: {}
  }
}
