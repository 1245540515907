import React from 'react';
import { Modal, Checkbox, Button, List, Divider, Tooltip, Icon, message } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { updateUsuario } from '../Redux/actions';
import { getConsultorios } from '../../Consultorio/Redux/actions';

const CheckboxGroup = Checkbox.Group;

class ManageClinicModal extends React.Component {

  static propTypes = {
    usuario: PropTypes.object
  };

  state = {
    checked: []
  }

  componentDidMount() {
    const { user } = this.props;
    if(user.isSuper) this.props.getConsultorios();
  }

  onChange = (checked) => {
    const id = checked.map(c => ({ id: c }));
    this.setState({ checked: id });
  }

  addProfile = (e) => {
    const { usuario, updateUsuario } = this.props;
    const { checked } = this.state;
    let values = JSON.parse(JSON.stringify(usuario));

    if (checked === undefined || checked.length === 0) return message.warning('Nenhum consultório selecionado!');

    values.consultorios.push(...checked);
    updateUsuario(values);
    this.setState({ checked: [] });
  }

  removeClinic(position) {
    const { usuario, updateUsuario } = this.props;
    const values = JSON.parse(JSON.stringify(usuario));
    values.consultorios.splice(position, 1);
    updateUsuario(values);
  }

  render() {
    const { visibleClinic, handleOk, handleCancel, usuario, user, consultorios } = this.props;
    const clinics = user.isSuper ? consultorios.content : usuario.consultorios;
    const clinicsCheckBox = (clinics || []).map(c => (
      { label: c.pj ? c.razaoSocial + ' - ' + c.endereco.cidade.nome : c.nome + ' - ' + c.endereco.cidade.nome, value: c.id }
      ));
    const genre = usuario.pessoa.genero === 'MASCULINO' ? 'do usuário' : 'da usuária';
    return (
      <React.Fragment>
        <Modal
          //width='600'
          destroyOnClose={true}
          title={`Gerenciador de acesso a consultórios`}
          visible={visibleClinic}
          onOk={handleOk}
          onCancel={handleCancel}
          cancelButtonProps={{ disabled: true }}
          okText='OK'
        >
          <CheckboxGroup
            options={clinicsCheckBox} 
            onChange={this.onChange}
          />
          <br/>
          <Button 
            style={{ marginLeft: '420px' }} 
            type="primary" 
            shape="circle" 
            icon="plus" 
            onClick={this.addProfile}/>
          <Divider type="horizontal" />

          <h3>{`Consultórios ${genre} ${usuario.pessoa.nome}:`}</h3>
          <List
            className="demo-loadmore-list"
            itemLayout="horizontal"
            dataSource={usuario.consultorios}
            renderItem={(item, index) => (
              <List.Item actions={[
                user.id !== usuario.id &&
                <Tooltip title={`Remover`}>
                    <Icon type="delete" theme="twoTone" onClick={() => this.removeClinic(index)}/>
                </Tooltip> 
              ]}>
                <List.Item.Meta
                  title={item.pj ? item.razaoSocial + ' - ' + item.endereco.cidade.nome : item.nome + ' - ' + item.endereco.cidade.nome}
                />
              </List.Item>
            )}
          /> 
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({ 
  user: state.login.user,
  consultorios: state.consultorio.list
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
  updateUsuario,
  getConsultorios
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ManageClinicModal);
