import React from 'react';
import { Form, Icon, Input, Button, Typography } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import logo from '../../assets/logo_dente.png';
import { signIn, validateToken } from './Redux/actions';

const { Title } = Typography;

class SignIn extends React.Component {

  componentDidMount() {
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
    this.props.validateToken({ token, userId });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.signIn(values);
      }
    });
  }

  handleStringToNumber = (event) => {
    let number = this.convertNumber(event.target.value);
    event.target.value = number;
  }

  convertNumber(string) {
    return string.replace(/[^0-9]/g, '');
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { loading } = this.props;
    return (
      <div style={{ 
            background: '#f2f2f2', 
            height: '100%', 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            justifyContent: 'center', 
            flexWrap: 'wrap' 
          }}>
        <div style={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center', 
              justifyContent: 'center', 
              background: '#fff', 
              borderRadius: '10px', 
              padding: '77px 55px 33px 55px', 
              width: '438px', 
              border: '1px solid #e0e0e0', 
              boxShadow: '0px 0px 8px 2px #d2c9c9' 
            }}>
            <img style={{ marginBottom: '20px' }} src={logo} alt="sysprod_logo"/>
            <Title style={{ color: 'rgba(0, 0, 0, 0.65)' }} level={4}>SYSPROD</Title>
            <Title style={{ marginTop: '0px', color: 'rgba(0, 0, 0, 0.65)' }} level={4}>Sistema de Prontuário de Dentista</Title>
            
            <Form onSubmit={this.handleSubmit} className="login-form">
                <Form.Item>
                {getFieldDecorator('login', {
                    rules: [{ required: true, message: 'Por favor, informe seu CPF sem pontos e sem dígito!' }],
                })(
                <Input
                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder="CPF sem pontos e sem dígito"
                    onChange={this.handleStringToNumber}
                />
                )}
                </Form.Item>
                <Form.Item>
                {getFieldDecorator('senha', {
                    rules: [{ required: true, message: 'Por favor, informe sua senha!' }],
                })(
                <Input
                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="password"
                    placeholder="Senha"
                />
                )}
                </Form.Item>
                <Form.Item>
                  {
                    !loading ?
                    <Button 
                      loading={loading} 
                      style={{ width: '100%' }} 
                      type="primary" 
                      htmlType="submit" 
                      className="login-form-button"
                    >
                      { !loading ? 'Entrar' : 'Entrando...' }
                    </Button>
                    :
                    <Button 
                      loading={loading} 
                      style={{ width: '100%' }} 
                      type="primary" 
                      className="login-form-button"
                    >
                      { !loading ? 'Entrar' : 'Entrando...' }
                    </Button>
                  }
                  <Link style={{ textAlign: 'center' }} to="/esqueci-senha">Esqueci a senha</Link>
                </Form.Item>
            </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({ 
    loading: state.login.loading,
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
    signIn,
    validateToken
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(SignIn));
