import React from 'react'
import { Card } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactToPrint from 'react-to-print';

import { getFuncionarioById } from './Redux/actions'
import { ContentTitle } from '../UI/ContentTitle/ContentTitle';
import { ButtonPrint } from '../UI/ButtonPrint/ButtonPrint';
import { PrintHeader } from '../UI/PrintHeader/PrintHeader';

class DetailsFuncionario extends React.Component {

  componentDidMount() {
    const id = this.funcionarioId;
    this.props.getFuncionarioById(id);
  }

  get funcionarioId() {
    return this.props.match.params.id;
  }

  verificaGenero() {
    const { funcionario } = this.props;
    const { pessoa } = funcionario;

    return pessoa.genero === 'MASCULINO' ? 'Masculino' : 'Feminino';
  }

  render() {
    const { funcionario, consultorioActive } = this.props;
    const { pessoa } = funcionario;
    const { endereco } = pessoa;
    const { cidade } = endereco;
    const { estado } = cidade;

    return (
      <center>
        <ContentTitle hasBack />
        <PrintHeader consultorio={consultorioActive} />
        <h1>Detalhes do Funcionário</h1>
        <Card
          title={<strong>{pessoa.nome}</strong>}
          style={{ width: 900, textAlign: 'justify' }} 
          extra={
            <ReactToPrint
              trigger={() => <ButtonPrint style={{ fontSize: '25px', height: '1em' }} />}
              content={() => this}
            />
          }>

          <Card title='Dados Pessoais' bordered={false}>
            <p><strong>CPF: </strong>{pessoa.cpf}</p>
            <p><strong>Data de Nascimento: </strong>{moment(pessoa.dataNascimento).format('DD/MM/YYYY')}</p>
            <p><strong>Idade: </strong>{moment().diff(pessoa.dataNascimento, 'years') + ' anos'}</p>
            <p><strong>Gênero: </strong>{this.verificaGenero(pessoa.genero)}</p>
          </Card>
          <Card title='Financeiro' bordered={false}>
            <p><strong>Cargo: </strong>{funcionario.cargo.nome}</p>
            <p><strong>Salário: </strong>{funcionario.valorSalario.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
          </Card>
          <Card title='Contato' bordered={false}>
            <p><strong>Telefone: </strong>{pessoa.telefone}</p>
            <p><strong>Celular: </strong>{pessoa.celular}</p>
            <p><strong>Telefone Comercial: </strong>{pessoa.telComercial}</p>
            <p><strong>E-mail: </strong>{pessoa.email}</p>
          </Card>
          <Card title='Endereço' bordered={false}>
            <p><strong>Rua: </strong>{endereco.logradouro}</p>
            <p><strong>Número: </strong>{endereco.numero}</p>
            <p><strong>Complemento: </strong>{endereco.complemento}</p>
            <p><strong>Bairro: </strong>{endereco.bairro}</p>
            <p><strong>Cidade: </strong>{cidade.nome}</p>
            <p><strong>Estado: </strong>{estado.nome}</p>
            <p><strong>CEP: </strong>{endereco.cep}</p>
          </Card>
        </Card>
        <br />
      </center>

    );
  }
}

const mapStateToProps = state => ({ 
  funcionario: state.funcionario.funcionario,
  consultorioActive: state.login.consultorioActive
});
const mapDispatchToProps = dispatch => bindActionCreators({ getFuncionarioById }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(DetailsFuncionario);
