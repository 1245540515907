import { message } from 'antd';

import api from '../../../services/api';

export function getCargoById(id) {
  const request = api.get(`cargos/${id}`);
  return {
    type: 'CARGO_FETCH_ID',
    payload: request
  }
}

export function getServico(values) {
  return {
    type: 'SERVICO_FETCH',
    payload: values
  }
}

export function loadingServico(status) {
  return {
    type: 'LOADING_SERVICO',
    payload: status
  }
}

export function getServicos({ consultorio, page, linesPerPage } = {}) {
  const request = api.get(`servicos`, {
    params: {
      consultorio,
      page,
      linesPerPage
    }
  });
  return {
    type: 'SERVICO_FETCHED',
    payload: request
  }
}

export function searchServico({ text, consultorio } = {}) {
  const request = api.get(`servicos/search`, {
    params: {
      text,
      consultorio
    }
  });
  return {
    type: 'SERVICO_SEARCH',
    payload: request
  }
}

export function addServico(values, param) {
  return submit(values, 'post', param)
}

export function updateServico(values, param) {
  return submit(values, 'put', param)
}

export function removeServico(values, param) {
  return submit(values, 'delete', param)
}

function submit(values, method, param) {
  return dispatch => {
    const id = values.id ? values.id : ''
    const consultorio = param.id ? param.id : ''
    api[method](`servicos/${id}`, values)
      .then(resp => {
        message.success('Operação realizada com sucesso!')
        dispatch([
          getServicos({ consultorio, page: 0, linesPerPage: 15 })
        ])
      });
  }
}

export function clearServico() {
  return {
    type: 'SERVICO_CLEAR',
    payload: {}
  }
}
