import React from 'react'
import { Modal, Upload, Button, Icon, Spin, message } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import './Upload.css';
import { getPacienteById, upload, updatePacienteFiles, removeFile, loadingFile } from '../Redux/actions';
import { base64src } from '../../UI/util/file';

export const getBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result));
    reader.addEventListener("error", reject);
    reader.readAsDataURL(file);
  });
};

class UploadModal extends React.Component {

  state = {
    titleSpin: ''
  }

  componentDidUpdate(prevProps) {
    const { getPacienteById, paciente } = this.props;
    const changed = paciente.id !== prevProps.paciente.id;

    if (changed) {
      getPacienteById(paciente.id);
    }
  }

  handleUpload(pacienteId, file) {
    const sizeInMB = file.size / 1024 / 1024;
    const type = file.type.split("/");
    if(sizeInMB > 10) return message.warn("O tamanho máximo permitido é de 10MB");
    if(type[0] === 'video') return message.warn("Vídeos não podem ser anexados!");

    const arquivo = {};
    this.setState({ titleSpin: 'Carregando arquivo...' });
    this.props.loadingFile(true);
    
    getBase64(file).then(base64 => {
      const base = base64.split(",");

      arquivo.nomeOriginal = file.name;
      arquivo.tipo = file.type;
      arquivo.base64 = base[1];
      arquivo.tamanho = file.size;

      this.props.upload(pacienteId, arquivo);
    });
  }

  handleRemoveFile = (file, pacienteId) => {
    this.setState({ titleSpin: 'Removendo...' });
    this.props.loadingFile(true);
    const id = +file.uid;
    this.props.removeFile(id, pacienteId);
  }

  render() {
    const { titleSpin } = this.state;
    const { visible, handleOk, handleCancel, paciente, loadingFileStatus } = this.props;
    //console.log(paciente)
    const files = (paciente.arquivos || []).map(file => ({
      uid: file.id.toString(),
      createdDate: file.createdDate,
      nomeOriginal: file.nomeOriginal,
      name: file.nomeOriginal,
      tipo: file.tipo,
      url: base64src(file.base64, file.tipo),
      thumbUrl: base64src(file.base64, file.tipo)
    }));
    const preposition = paciente.genero === 1 ? 'do' : 'da';
    return (
      <React.Fragment>
        <Modal
          //width='600'
          destroyOnClose={true}
          title={`Arquivos ${preposition} paciente: ${paciente.nome || paciente.pessoa.nome}`}
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          okText='Ok'
        >
          { loadingFileStatus ?
            <Spin tip={titleSpin}>
              <Upload
                disabled={loadingFileStatus}
                action={(file) => this.handleUpload(paciente.id, file)}
                listType='picture'
                fileList={[...files]}
                className='upload-list-inline'
                onRemove={(file) => this.handleRemoveFile(file, paciente.id)}>
                <Button disabled={loadingFileStatus}>
                  <Icon type="upload" /> Anexo
                </Button>
                <br/>
                <span className="texto-info">Tamanho máximo do arquivo 10MB</span>
              </Upload>
            </Spin>
            :
            <Upload
              disabled={loadingFileStatus}
              action={(file) => this.handleUpload(paciente.id, file)}
              listType='picture'
              fileList={[...files]}
              className='upload-list-inline'
              onRemove={(file) => this.handleRemoveFile(file, paciente.id)}>
              <Button disabled={loadingFileStatus}>
                <Icon type="upload" /> Anexo
              </Button>
              <br/>
              <span className="texto-info">Tamanho máximo do arquivo 10MB</span>
            </Upload>
          }
        </Modal>
      </React.Fragment>
    );
  }

}

const mapStateToProps = state => ({
  //paciente: state.paciente.paciente,
  loadingFileStatus: state.paciente.loadingFileStatus,
  user: state.login.user
});
const mapDispatchToProps = dispatch => bindActionCreators({
  getPacienteById,
  upload,
  updatePacienteFiles,
  removeFile,
  loadingFile
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UploadModal);
withRouter(UploadModal);