import React from 'react';
import { Tooltip, Icon } from 'antd';
import '../PrintHeader/PrintHeader.css';


export class ButtonPrint extends React.Component {

  render() {
    return (
      <React.Fragment>
        <Tooltip
          title='Imprimir'
          placement="bottom">
          <Icon
            {...this.props}
            className="no-print"
            type="printer"
            theme="twoTone"
          />
        </Tooltip>
      </React.Fragment>
    );
  }
}