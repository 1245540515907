import React from 'react';
import { Input, Form, Select, DatePicker, Button, Card, Modal } from 'antd';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { addPaciente, updatePaciente, clearPaciente, getPacienteById, findPessoaByCpf } from './Redux/actions';
import { ContentTitle } from '../UI/ContentTitle/ContentTitle';
import EnderecoFullForm from '../Endereco/EnderecoFull';
import { InputTelephone } from '../UI/TelefoneInput/InputTelephone';
import { InputCellPhone } from '../UI/TelefoneInput/InputCellPhone';
import { toUpperCase } from '../UI/util/toUpperCase';

const FormItem = Form.Item;
const Option = Select.Option;

class PacienteForm extends React.Component {

  componentDidMount() {
    const id = this.pacienteId;
    const { form, paciente, getPacienteById } = this.props;
    
    if (id) {
      getPacienteById(id);
      form.setFieldsValue({
        'pessoa.nome': paciente.pessoa.nome,
        'pessoa.cpf': paciente.pessoa.cpf,
        'pessoa.dataNascimento': moment(paciente.pessoa.dataNascimento),
        'pessoa.genero': paciente.pessoa.genero === 'MASCULINO' ? 0 : 1,
        'pessoa.telefone': paciente.pessoa.telefone,
        'pessoa.celular': paciente.pessoa.celular,
        'pessoa.email': paciente.pessoa.email
      });
    } //else this.props.form.resetFields();
  }

  componentDidUpdate(prevProps) {
    const id = this.pacienteId;
    const { form, paciente } = this.props;
    const changed = this.props.paciente !== prevProps.paciente;

    if(changed) {
      //this.confirmModal();
      if (id) {
        form.setFieldsValue({
          'pessoa.nome': paciente.pessoa.nome,
          'pessoa.cpf': paciente.pessoa.cpf,
          'pessoa.dataNascimento': moment(paciente.pessoa.dataNascimento),
          'pessoa.genero': paciente.pessoa.genero === 'MASCULINO' ? 0 : 1,
          'pessoa.telefone': paciente.pessoa.telefone,
          'pessoa.celular': paciente.pessoa.celular,
          'pessoa.email': paciente.pessoa.email
        });
      }

    }
    
  }

  componentWillUnmount() {      
    this.props.clearPaciente();
  }

  confirmModal = () => {
    const { form, paciente } = this.props;

    Modal.confirm({
      title: 'Já existe uma pessoa cadastrada com esse CPF!',
      content: 'Deseja autocompletar os campos?',
      okText: 'Sim',
      cancelText: 'Não',
      onOk() {
        form.setFieldsValue({
          'pessoa.nome': paciente.pessoa.nome,
          'pessoa.cpf': paciente.pessoa.cpf,
          'pessoa.dataNascimento': moment(paciente.pessoa.dataNascimento),
          'pessoa.genero': paciente.pessoa.genero === 'MASCULINO' ? 0 : 1,
          'pessoa.telefone': paciente.pessoa.telefone,
          'pessoa.celular': paciente.pessoa.celular,
          'pessoa.email': paciente.pessoa.email
        });
      },
      onCancel() {},
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { paciente, addPaciente, updatePaciente, consultorioActive } = this.props;
        
        values.pessoa.consultorio = {id: consultorioActive.id};
        values.pessoa.endereco = values.endereco;
        values.endereco.cidade = {id: values.endereco.cidade};

        const pacienteId = this.pacienteId;
        if (pacienteId) {
          values.id = Number(pacienteId);
          values.pessoa.id = paciente.pessoa.id;
          values.pessoa.endereco.id = paciente.pessoa.endereco.id;
          values.odontograma = paciente.odontograma;
          values.arquivos = paciente.arquivos;
          updatePaciente(values, consultorioActive);
        } else {
          values.pessoa.id = paciente.pessoa.id ? paciente.pessoa.id : null;
          addPaciente(values, consultorioActive);
        }
        this.handleBack();
      }
    });
  }

  handleBack = () => {
    this.props.history.goBack();
  }

  handleformatCpf = (event) => {
    let cpfDigitado = this.convertNumber(event.target.value);
    if (cpfDigitado.length > 11) {
      cpfDigitado = cpfDigitado.slice(0, 11);
    }
    event.target.value = this.formatCpf(cpfDigitado);

    // if (event.target.value.length === 14) {
    //   const cpf = event.target.value;
    //   this.props.findPessoaByCpf({ cpf });
    // }
  }

  validateCPF = (rule, value, callback) => {
    if (value) {
      let soma, resto, cpf = value;
      let strCPF = this.convertNumber(cpf);
      soma = 0;
      if (strCPF === "00000000000") callback('CPF inválido!');

      for (let i = 1; i <= 9; i++) soma = soma + parseInt(strCPF.substring(i - 1, i), 10) * (11 - i);
      resto = (soma * 10) % 11;

      if ((resto === 10) || (resto === 11)) resto = 0;
      if (resto !== parseInt(strCPF.substring(9, 10), 10)) callback('CPF inválido!');

      soma = 0;
      for (let i = 1; i <= 10; i++) soma = soma + parseInt(strCPF.substring(i - 1, i), 10) * (12 - i);
      resto = (soma * 10) % 11;

      if ((resto === 10) || (resto === 11)) resto = 0;
      if (resto !== parseInt(strCPF.substring(10, 11), 10)) callback('CPF inválido!');
    }
    callback();
  }

  convertNumber(string) {
    return string.replace(/[^0-9]/g, '');
  }

  formatCpf(cpf) {
    cpf = cpf.replace(/\D/g, "");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    return cpf;
  }

  get pacienteId() {
    return this.props.match.params.id;
  }

  render() {
    const { form, paciente } = this.props;
    const { pessoa } = paciente;
    const { endereco } = pessoa;
    const { id } = this.props.match.params;
    const title = id ? "Atualização de Paciente" : "Cadastro de Paciente";
    const { getFieldDecorator } = form;
    const formItemLayout = {
      labelCol: { span: 7 },
      wrapperCol: { span: 10 }
    };

    return (
      <div>
        <ContentTitle hasBack>
          {title}
        </ContentTitle>
        <Form onSubmit={this.handleSubmit}>
          <Card title='Dados Pessoais' bordered={false}>
            <FormItem
              {...formItemLayout}
              label="Nome"
            >
              {getFieldDecorator('pessoa.nome', {
                rules: [{ required: true, message: 'Por favor informe o nome completo do paciente!' }],
              })(
                <Input onChange={toUpperCase} maxLength={80} placeholder="Nome completo" />
              )}
            </FormItem>
            <FormItem
              {...formItemLayout}
              label="CPF"
            >
              {getFieldDecorator('pessoa.cpf', {
                rules: [{ required: false, message: 'Por favor informe o CPF do paciente!' },
                {
                  validator: this.validateCPF,
                }
                ],
              })(
                <Input
                  style={{ width: 140 }}
                  onChange={this.handleformatCpf} />
              )}
            </FormItem>
            <FormItem
              {...formItemLayout}
              label="Data de nascimento"
            >
              {getFieldDecorator('pessoa.dataNascimento', {
                rules: [{ required: true, message: 'Por favor informe a data de nascimento do paciente!' }],
              })(
                <DatePicker format="DD/MM/YYYY" />
              )}
            </FormItem>
            <FormItem
              {...formItemLayout}
              label="Gênero"
            >
              {getFieldDecorator('pessoa.genero', {
                rules: [{ required: true, message: 'Por favor informe o gênero do paciente!' }],
              })(
                <Select placeholder="Selecione">
                  <Option value={0}>Masculino</Option>
                  <Option value={1}>Feminino</Option>
                </Select>
              )}
            </FormItem>
          </Card>
          <Card title='Contato' bordered={false}>
            <FormItem
              {...formItemLayout}
              label="Telefone"
            >
              {getFieldDecorator('pessoa.telefone', {
                rules: [{ required: false, message: 'Por favor informe um telefone de contato do paciente!' }],
              })(
                <InputTelephone />
              )}
            </FormItem>
            <FormItem
              {...formItemLayout}
              label="Celular"
            >
              {getFieldDecorator('pessoa.celular', {
                rules: [{ required: false }],
              })(
                <InputCellPhone />
              )}
            </FormItem>
            <FormItem
              {...formItemLayout}
              label="E-mail"
            >
              {getFieldDecorator('pessoa.email', {
                rules: [{
                  required: false,
                  type: 'email', message: 'O e-mail não é válido!',
                }],
              })(
                <Input maxLength={45} />
              )}
            </FormItem>
          </Card>
          <Card title='Endereço' bordered={false}>
            <FormItem>
              <EnderecoFullForm form={form} endereco={endereco} />
            </FormItem>
          </Card>
          <FormItem>
            <center>
              <Button type="primary" htmlType="submit">{id ? 'Atualizar' : 'Salvar'}</Button>
              {'    '}
              <Button onClick={this.handleBack}>Cancelar</Button>
            </center>
          </FormItem>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  paciente: state.paciente.paciente,
  user: state.login.user,
  consultorioActive: state.login.consultorioActive
});
const mapDispatchToProps = dispatch => bindActionCreators({
  addPaciente, 
  updatePaciente, 
  clearPaciente,
  getPacienteById,
  findPessoaByCpf
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(PacienteForm));
withRouter(PacienteForm)