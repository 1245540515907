import React from 'react'
import { Table, Divider, Icon, Popconfirm, Tooltip, Button, Input  } from 'antd';
import { Link } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';

import { getPacientes, getPaciente, getPacienteById, searchPaciente, removePaciente, updatePaciente, modal, clearPaciente, modalOdontograma, loadingPaciente } from './Redux/actions';
import { FaTooth, FaFileUpload } from 'react-icons/fa';
import UploadModal from './Upload/UploadModal';
import OdontogramaModal from './Odontograma/OdontogramaModal';

const Search = Input.Search;

const PAGE_SIZE = 15;

class ListFuncionarios extends React.Component {
  
    state = {
      marcados: {},
      searchText: '',
      currentPage: 0
    }

    componentDidMount() {
      this.getFirstPage();
    }

    handleSearch = (event) => {
      const searchText = event.target.value;
      this.setState({ searchText }, () => {
        if (this.state.searchText)
          this.searchPacientes();
        else
          this.getFirstPage();
      });
    };

    searchPacientes = debounce(() => {
      const { searchText } = this.state;
      const { consultorioActive } = this.props;
      const consultorio = consultorioActive.id ? consultorioActive.id : '';
      this.props.searchPaciente({ text: searchText, consultorio });
    }, 500);

    formatReal = (int) => {
      let tmp = int+'';
      tmp = tmp.replace(/([0-9]{2})$/g, ",$1");
      if( tmp.length > 6 )
          tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
  
      return tmp;
    }

    handlePaginationChange = (page, pageSize) => {
      const { consultorioActive, loadingPaciente } = this.props;
      loadingPaciente(true);
      const consultorio = consultorioActive.id ? consultorioActive.id : '';
      this.setState({ currentPage: page });
      this.props.getPacientes({ consultorio, page: page - 1, linesPerPage: pageSize });
    };

    getFirstPage = () => {
      const { consultorioActive } = this.props;
      const consultorio = consultorioActive.id ? consultorioActive.id : '';
      this.props.getPacientes({ consultorio, page: 0, linesPerPage: PAGE_SIZE });
    }

    showModal = () => {
      this.props.modal(true);
    };
  
    handleOk = e => {
      this.props.modal(false);
      //this.props.clearPaciente();
    };
  
    handleCancel = e => {
      this.props.modal(false);
      //this.props.clearPaciente();
    };

    showModalOdontograma = () => {
      this.props.modalOdontograma(true);
    };
  
    handleOdontogramaOk = e => {
      const { paciente, updatePaciente, consultorioActive } = this.props;
      updatePaciente(paciente, consultorioActive);
    };
  
    handleOdontogramaCancel = e => {
      this.props.modalOdontograma(false);
      //this.props.clearPaciente();
    };
    
    
    render() {
      const { searchText, currentPage } = this.state;
      const { loading, pacientes, paciente, getPaciente, removePaciente, visible, visibleOdontograma, consultorioActive } = this.props;
      const data = (pacientes.content || []).map(i => ({...i, key: i.id}));
      const today = moment(new Date()).format("YYYY-MM-DD");

      const columns = [{
        title: 'Nome',
        dataIndex: 'nome',
        key: 'nome',
        render: (_, paciente) => <Link to={`/paciente/detalhes/${paciente.id}`}>{paciente.nome}</Link>,
      }, {
        title: 'Último atendimento',
        dataIndex: 'ultimoAtendimento',
        key: 'ultimoAtendimento',
        align: 'center',
        render: (ultimoAtendimento) => (
          ultimoAtendimento ? 
          <Link to={`/atendimento/detalhes/${ultimoAtendimento.id}`}>
            { ultimoAtendimento.dataAtendimento === today ? 'Hoje' 
            : 
            moment(ultimoAtendimento.dataAtendimento, "YYYYMMDD").fromNow() + " em " + moment(ultimoAtendimento.dataAtendimento).format('DD/MM/YYYY') }
          </Link> 
          : '-'
        )
      }, {
        title: 'CPF',
        dataIndex: 'cpf',
        key: 'cpf',
        align: 'center'
      }, {
        title: 'Odontograma',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (_, paciente) => (
          <Tooltip 
            placement="bottom"
            title={`Atualizar odontograma`}>
            <Link to={`/paciente/list`} onClick={() => getPaciente(paciente)}>
              <FaTooth onClick={() => this.showModalOdontograma()} />
            </Link>
          </Tooltip>
        )
      }, {
        title: 'Ações',
        key: 'action',
        render: (_, paciente, record) => 
          <span>
            <Tooltip 
                placement="bottom"
                title={`Add arquivos`}>
              <Link to={`/paciente/list`} onClick={() => getPaciente(paciente)}>
                <FaFileUpload onClick={() => this.showModal()} />
              </Link>
            </Tooltip>
            <Divider type="vertical" />
            <Tooltip 
              title={`Editar`}
              placement="bottom">
              <Link to={`/paciente/form/edit/${paciente.id}`} >
                <Icon type="edit" theme="twoTone" />
              </Link>
            </Tooltip>
            <Divider type="vertical" />
            <Popconfirm
              title="Tem certeza que quer remover?"
              okText="Remover"
              cancelText="Cancelar"
              onConfirm={() => removePaciente(paciente, consultorioActive)}>
              <Tooltip 
                placement="bottom"
                title={`Remover`}>
                <Icon type="delete" theme="twoTone" style={{ cursor: "pointer" }}/>
              </Tooltip>
            </Popconfirm>
          </span>
        
      }];

      const pagination = (!searchText && 
        { 
          current: currentPage,
          defaultPageSize: PAGE_SIZE,
          pageSize: PAGE_SIZE,
          total: pacientes.totalElements || 0,
          onChange: this.handlePaginationChange
        }
      ) || false;

        return (
            <React.Fragment>
              <Link to="/paciente/form/new">
                <Button icon="plus-square">
                  Novo Paciente
                </Button>
              </Link>
                <h1 style={{ textAlign: 'center' }}>Pacientes</h1>
                <Search
                  placeholder="Buscar paciente por Nome ou CPF"
                  onChange={this.handleSearch} />
                <Table 
                  pagination={pagination} 
                  columns={columns} 
                  dataSource={data} 
                  loading={loading} />
                <UploadModal
                  visible={visible} 
                  handleOk={this.handleOk}
                  handleCancel={this.handleCancel}
                  paciente={paciente}
                />
                <OdontogramaModal
                  visible={visibleOdontograma} 
                  handleOk={this.handleOdontogramaOk}
                  handleCancel={this.handleOdontogramaCancel}
                  paciente={paciente}
                />
            </React.Fragment>
        );
    }

}

const mapStateToProps = state => ({ 
  pacientes: state.paciente.list,
  paciente: state.paciente.paciente,
  visible: state.paciente.visible,
  visibleOdontograma: state.paciente.visibleOdontograma,
  loading: state.paciente.loading,
  user: state.login.user,
  consultorioActive: state.login.consultorioActive
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
  getPacientes, 
  getPaciente, 
  getPacienteById, 
  searchPaciente, 
  removePaciente, 
  updatePaciente,
  modal,
  clearPaciente,
  modalOdontograma,
  loadingPaciente
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ListFuncionarios);
