import React from 'react'
import ReactToPrint from "react-to-print";

import DetailsPaciente from './Details';
import { ButtonPrint } from '../UI/ButtonPrint/ButtonPrint';

export default class PrintPaciente extends React.Component {

  get pacienteId() {
    return this.props.match.params.id;
  }

  render() {
    return (
      <React.Fragment>
        <ReactToPrint
            trigger={() => <ButtonPrint />}
            content={() => this.componentRef}
          />
          <DetailsPaciente match={this.props.match} ref={el => (this.componentRef = el)} />
      </React.Fragment>
    );
  }
}
