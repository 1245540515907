import React from 'react';
import { DatePicker, Form, Button, Card, Spin } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import ReactToPrint from 'react-to-print';

import { getPeriodAtendimentos, loadingFinanceiro, clearFinanceiro } from './Redux/actions';
import PieChart from '../Home/Chart/PieChart';
import { ButtonPrint } from '../UI/ButtonPrint/ButtonPrint';
import { PrintHeader } from '../UI/PrintHeader/PrintHeader';

const { RangePicker } = DatePicker;
const FormItem = Form.Item;

class FinanceiroList extends React.Component {

  state = {
    visible: false,
    inicio: '',
    fim: ''
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { consultorioActive } = this.props;
        const consultorio = consultorioActive.id ? consultorioActive.id : '';
  
        this.setState({ visible: true, inicio: moment(values.period[0]).format("DD/MM/YYYY"), fim: moment(values.period[1]).format("DD/MM/YYYY") });
        this.props.loadingFinanceiro(true);

        const inicio = moment(values.period[0]).format("YYYY/MM/DD");
        const fim = moment(values.period[1]).format("YYYY/MM/DD");

        this.props.getPeriodAtendimentos({ consultorio, inicio, fim });

      }
    });
  }

  handleCancel = () => {
    this.setState({ visible: false, inicio: '', fim: '' });
    this.props.form.resetFields();
    this.props.clearFinanceiro();
  }

  renderContent() {
    const { user, financeiro, loading, consultorioActive } = this.props;
    if (loading) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Spin />
        </div>
      );
    }
    return (
      <div ref={el => (this.componentRef = el)}>
        <center className="print-only">
          <PrintHeader consultorio={consultorioActive} />
          <h1 >Financeiro</h1>
          <h2>{`Período da consulta: de ${this.state.inicio} à ${this.state.fim}`}</h2>
        </center>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Card title={`Valor total: ${financeiro.valorTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}`} style={{ width: 300 }}>
            {
              financeiro.atendimentos.map(v => {
                return (
                  <ul key={v.id}>
                    <li>{v.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} - {moment(v.dataAtendimento).format("DD/MM/YYYY")}</li>
                  </ul>
                );
              })
            }
          </Card>
          <PieChart is3D title='Resultado para o período consultado' user={user} values={financeiro} />
        </div>

      </div>
    );
  }

  render() {
    const { visible } = this.state;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 7 },
      wrapperCol: { span: 10 }
    };
    return (
      <React.Fragment>
        <h1 style={{ textAlign: 'center' }}>Financeiro</h1>
        <br />
        <Form onSubmit={this.handleSubmit}>
          <FormItem
            {...formItemLayout}
            label="Informe o período da consulta"
          >
            {getFieldDecorator('period', {
              rules: [{ required: true, message: 'Por favor informe o período de consulta' }],
            })(
              <RangePicker format="DD/MM/YYYY" />
            )}
            {'    '}
            <Button type="primary" htmlType="submit" icon="search" size="small">Consultar</Button>
            {'    '}
            <Button onClick={this.handleCancel} icon="close" size="small">Limpar</Button>
            {'    '}
            <ReactToPrint
              trigger={() => <ButtonPrint style={{ fontSize: '25px', height: '1em' }} />}
              content={() => this.componentRef}
            />
          </FormItem>
        </Form>
        { visible &&
          this.renderContent()
        }
      </React.Fragment>
    );
  }

}

const mapStateToProps = state => ({
  financeiro: state.financeiro.financeiro,
  loading: state.financeiro.loading,
  user: state.login.user,
  consultorioActive: state.login.consultorioActive
});
const mapDispatchToProps = dispatch => bindActionCreators({
  getPeriodAtendimentos,
  loadingFinanceiro,
  clearFinanceiro
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(FinanceiroList));
