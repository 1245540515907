const INITIAL_STATE = {
  cargo: {},
  financeiro: {
    atendimentos: [],
    valorTotal: 0.0
  },
  loading: true
}
export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'ATENDIMENTO_PERIOD':
      return {
        ...state,
        financeiro: action.payload.data,
        loading: false
      }
    case 'LOADING_FINANCEIRO':
      return {
        ...state,
        loading: action.payload
      }
    case 'FINANCEIRO_CLEAR':
      return { ...state, financeiro: INITIAL_STATE.financeiro }

    default:
      return state
  }
}