import api from '../../../services/api';

export function currentMonthYields({ consultorio }) {
  const request = api.get(`atendimentos/financeiro/mes_atual`, {
    params: {
      consultorio
    }
  });
  return {
    type: 'CURRENT_MONTH_YELDS',
    payload: request
  }
}

export function currentMonthYieldsClear() {
  return {
    type: 'CURRENT_MONTH_YELDS_CLEAR',
    payload: {}
  }
}

export function aniversariantesDoDia({ consultorio }) {
  const request = api.get(`pacientes/aniversariates_do_dia`, {
    params: {
      consultorio
    }
  });
  return {
    type: 'ANIVERSARIANTES_DO_DIA',
    payload: request
  }
}

export function getTotalDePacientes({ consultorio }) {
  const request = api.get(`pacientes/quantidade_total`, {
    params: {
      consultorio
    }
  });
  return {
    type: 'QUANTIDADE_TOTAL_PACIENTES',
    payload: request
  }
}
