import React from 'react';
import { Row, Col, Card, Tag, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';

import { currentMonthYields, aniversariantesDoDia, getTotalDePacientes } from './Redux/actions';
import PieChart from './Chart/PieChart';
import { FaBirthdayCake } from 'react-icons/fa';

class Dashboard extends React.Component {

  componentDidMount() {
    const { user, currentMonthYields, consultorioActive, aniversariantesDoDia, getTotalDePacientes } = this.props;
    if (user.id) {
      const consultorio = consultorioActive.id ? consultorioActive.id : '';
      currentMonthYields({ consultorio });
      aniversariantesDoDia({ consultorio });
      getTotalDePacientes({ consultorio });
    }    
  }

  componentDidUpdate(prevProps) {
    const { currentMonthYields, consultorioActive, aniversariantesDoDia, getTotalDePacientes } = this.props;
    const changed = this.props.user !== prevProps.user;
    if (changed) {
      const consultorio = consultorioActive.id ? consultorioActive.id : '';
      currentMonthYields({ consultorio });
      aniversariantesDoDia({ consultorio });
      getTotalDePacientes({ consultorio });
    }
  }

  render() {
    const { currentMonthValues, user, aniversariantes, totalPacientes } = this.props;
    const today = new Date().getDate().toString();
    return (
      <React.Fragment>
        <h1>::Dashboard::</h1>
        <Row gutter={16}>
          <Col span={14}>
            <Card bordered>
              <PieChart user={user} values={currentMonthValues} />
            </Card>
          </Col>
          <Col span={4}>
          <Card title={<b>Aniversariantes do mês de {moment().format('MMMM')}</b>} style={{ width: 430 }}>
            <ul>
              {
                aniversariantes.map(paciente => {
                  const dataNas = paciente.dataNascimento.split('-');
                  return (
                    <li key={paciente.id}>
                       <Tooltip 
                        title={moment(paciente.dataNascimento).format('DD/MM/YYYY')}
                        placement="right">
                        {paciente.nome} 
                        <FaBirthdayCake />
                        {'  '}
                        {today === dataNas[2] ? <Tag color="blue">Hoje</Tag> : ''} 
                      </Tooltip>
                    </li>
                  );
                })
              }
            </ul>
          </Card>
          </Col>
        </Row>
        <br/>
        <Row gutter={16}>
          <Col span={14}>
            <Card title={<b>Quantidade de pacientes</b>} style={{ width: 400 }}>
              {`Total: ${totalPacientes.totalElements}`}
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }

}

const mapStateToProps = state => ({
  user: state.login.user,
  currentMonthValues: state.dashboard.currentMonthValues,
  consultorioActive: state.login.consultorioActive,
  aniversariantes: state.dashboard.aniversariantes,
  totalPacientes: state.dashboard.totalPacientes
});
const mapDispatchToProps = dispatch => bindActionCreators({
  currentMonthYields,
  aniversariantesDoDia,
  getTotalDePacientes
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
