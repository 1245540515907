import { message } from 'antd';

import api from '../../../services/api';

export function getAtendimentoById(id) {
  const request = api.get(`atendimentos/${id}`);
  return {
    type: 'ATENDIMENTO_FETCH_ID',
    payload: request
  }
}

export function getAtendimento(values) {
  return {
    type: 'ATENDIMENTO_FETCH',
    payload: values
  }
}

export function getAtendimentos({ consultorio, page, linesPerPage } = {}) {
  const request = api.get(`atendimentos`, {
    params: {
      consultorio,
      page,
      linesPerPage
    }
  });
  return {
    type: 'ATENDIMENTO_FETCHED',
    payload: request
  }
}

export function searchAtendimento({ text, consultorio } = {}) {
  const request = api.get(`atendimentos/search`, {
    params: {
      text,
      consultorio
    }
  });
  return {
    type: 'ATENDIMENTO_SEARCH',
    payload: request
  }
}

export function searchAtendimentoByDate({ consultorio, data } = {}) {
  const request = api.get(`atendimentos/search/data`, {
    params: {
      consultorio,
      data
    }
  });
  return {
    type: 'ATENDIMENTO_SEARCH_BY_DATE',
    payload: request
  }
}

export function addAtendimento(values, param) {
  return submit(values, 'post', param)
}

export function updateAtendimento(values, param) {
  return submit(values, 'put', param)
}

export function removeAtendimento(values, param) {
  return submit(values, 'delete', param)
}

function submit(values, method, param) {
  return dispatch => {
    const id = values.id ? values.id : '';
    const consultorio = param.id ? param.id : '';
    api[method](`atendimentos/${id}`, values)
      .then(resp => {
        message.success('Operação realizada com sucesso!')
        dispatch([
          getAtendimentos({ consultorio, page: 0, linesPerPage: 15 })
        ])
      });
  }
}

export function clearAtendimento() {
  return {
    type: 'ATENDIMENTO_CLEAR',
    payload: null
  }
}

export function loadingAtendimento(status) {
  return {
    type: 'LOADING_ATENDIMENTO',
    payload: status
  }
}
