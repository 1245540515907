import React from 'react';
import { Table, Divider, Icon, Popconfirm, Tooltip, Button, Input } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import debounce from 'lodash.debounce';

import { getServico, getServicos, removeServico, searchServico, loadingServico } from './Redux/actions';
import { Link } from 'react-router-dom';

const Search = Input.Search;

const PAGE_SIZE = 15;

class ListServico extends React.Component {
  
    state = {
      searchText: '',
      currentPage: 0
    }
    
    componentDidMount() {      
      this.getFirstPage();
    }

    handleSearch = (event) => {
      const searchText = event.target.value;
      this.setState({ searchText }, () => {
        if (this.state.searchText)
          this.searchServicos();
        else
          this.getFirstPage();
      });
    };

    searchServicos = debounce(() => {
      const { searchText } = this.state;
      const { consultorioActive } = this.props;
      const consultorio = consultorioActive.id ? consultorioActive.id : '';
      this.props.searchServico({ text: searchText, consultorio });
    }, 500);

    handlePaginationChange = (page, pageSize) => {
      const { consultorioActive, loadingServico } = this.props;
      loadingServico(true);
      this.setState({ currentPage: page });
      const consultorio = consultorioActive.id ? consultorioActive.id : '';
      this.props.getServicos({ consultorio, page: page - 1, linesPerPage: pageSize });
    };

    getFirstPage = () => {
      const { consultorioActive } = this.props;
      const consultorio = consultorioActive.id ? consultorioActive.id : '';
      this.props.getServicos({ consultorio, page: 0, linesPerPage: PAGE_SIZE });
    }
    
    render() {
      const { searchText, currentPage } = this.state;
      const { servicos, loading, getServico, removeServico, consultorioActive } = this.props;
      const data = (servicos.content || []).map(i => ({...i, key: i.id}));

      const columns = [{
        title: 'Nome',
        dataIndex: 'nome',
        key: 'nome',
        render: (_, servico) => <Link to={`/servico/detalhes/${servico.id}`} onClick={() => getServico(servico)}>{servico.nome}</Link>,
      }, {
        title: 'Ações',
        key: 'action',
        render: (_, servico, record) => 
          <span>
            <Tooltip 
              title={`Editar ${servico.nome}`}
              placement="bottom">
              <Link to={`/servico/form/edit/${servico.id}`}>
                <Icon type="edit" theme="twoTone" onClick={() => getServico(servico)}/>
              </Link>
            </Tooltip>
            <Divider type="vertical" />
            <Popconfirm
              title="Deseja remover?"
              okText="Remover"
              cancelText="Cancelar"
              onConfirm={() => removeServico(servico, consultorioActive)}>
              <Tooltip 
                placement="bottom"
                title={`Remover ${servico.nome}`}>
                <Icon type="delete" theme="twoTone" />
              </Tooltip>
            </Popconfirm>
          </span>
        
      }];

      const pagination = (!searchText && 
        { 
          current: currentPage,
          defaultPageSize: PAGE_SIZE,
          pageSize: PAGE_SIZE,
          total: servicos.totalElements || 0,
          onChange: this.handlePaginationChange
        }
      ) || false;

      return (
          <React.Fragment>
            <Link to="/servico/form/new">
              <Button icon="plus-square">
                Nova Serviço
              </Button>
            </Link>
              <h1 style={{ textAlign: 'center' }}>Serviços</h1>
              <Search
                placeholder="Buscar serviço"
                onChange={this.handleSearch} />
              <Table 
                pagination={pagination} 
                columns={columns} 
                dataSource={data} 
                loading={loading} 
              />
          </React.Fragment>
      );
  }

}

const mapStateToProps = state => ({ 
  servicos: state.servico.list, 
  loading: state.servico.loading,
  user: state.login.user,
  consultorioActive: state.login.consultorioActive
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
  getServico, 
  getServicos, 
  removeServico, 
  searchServico,
  loadingServico
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ListServico);
