import React from 'react'
import { Card } from 'antd';
import { connect } from 'react-redux';

import { ContentTitle } from '../UI/ContentTitle/ContentTitle';

class DetailsCargo extends React.Component {

  render() {
    const { servico } = this.props;
    return (
      <center>
        <ContentTitle hasBack />
        <h1>Detalhes do Serviço</h1>
        <Card
          title={servico.nome}
          style={{ width: 900, textAlign: 'justify' }} >
        </Card>
      </center>

    );
  }


}

const mapStateToProps = state => ({ servico: state.servico.servico });
export default connect(mapStateToProps)(DetailsCargo);
