import React from 'react'
import { Popover, Tooltip } from 'antd';

import './Odontograma.css'

import OdontogramaSubtitle from './OdontogramaSubtitle';

import Tooth_Black_Top from '../../../assets/Tooth/Tooth_Black_Top.png';
import Tooth_Blank_Top from '../../../assets/Tooth/Tooth_Blank_Top.png';
import Tooth_Blue_Top from '../../../assets/Tooth/Tooth_Blue_Top.png';
import Tooth_Green_Top from '../../../assets/Tooth/Tooth_Green_Top.png';
import Tooth_Grey_Top from '../../../assets/Tooth/Tooth_Grey_Top.png';
import Tooth_Red_Top from '../../../assets/Tooth/Tooth_Red_Top.png';
import Tooth_Yellow_Top from '../../../assets/Tooth/Tooth_Yellow_Top.png';
import Tooth_Removed_Inside from '../../../assets/Tooth/Tooth_Removed_Inside.png';
import Tooth_Removed_Out from '../../../assets/Tooth/Tooth_Removed_Out.png';
import Tooth_Removal_Todo from '../../../assets/Tooth/Tooth_Removal_Todo.png';

export default class ToothPopover extends React.Component {

  state = {
    visible: false,
    tooth: '',
    color: ''
  }

  verifyColor = (color) => {
    switch (color) {
      case 0:
        return Tooth_Blank_Top;
      case 1:
        return Tooth_Black_Top;
      case 2:
        return Tooth_Grey_Top;
      case 3:
        return Tooth_Red_Top;
      case 4:
        return Tooth_Yellow_Top;
      case 5:
        return Tooth_Blue_Top;
      case 6:
        return Tooth_Green_Top;
      case 7:
        return Tooth_Removed_Inside;
      case 8:
        return Tooth_Removed_Out;
      case 9:
        return Tooth_Removal_Todo;

      default:
        break;
    }
  }

  handleChooseColor = (color) => {
    this.setState({ ...this.state, color, visible: false });
    this.props.toothAndColor({ tooth: this.state.tooth, color, position: 'superior' });
  }

  handleTooth = (tooth) => {
    this.setState({ ...this.state, tooth });
  }


  handleVisibleChange = (visible, tooth) => {
    this.setState({ ...this.state, visible, tooth });
  }

  render() {
    const { toothNumber, src, click, titleTooltip } = this.props;
    const content = <OdontogramaSubtitle onClick={(color) => this.handleChooseColor(color)} />
    return (
      <React.Fragment>
        <Popover
          content={content}
          title="Escolha uma opção"
          trigger={click ? "click" : ""}
          visible={this.state.visible}
          onVisibleChange={(visible) => this.handleVisibleChange(visible, "d" + toothNumber)}
        >
          <Tooltip
            title={titleTooltip}>
            <img className="tooth-top" src={this.verifyColor(src)} alt={"d" + toothNumber} />
          </Tooltip>
        </Popover>
      </React.Fragment>
    );
  }

}
