const INITIAL_STATE = {
  paciente: {
    id: null,
    arquivos: [],
    pessoa: {
      id: null,
      endereco: {
        id: null,
        cidade: {
          estado: {}
        }
      }
    },
    odontograma: {
      d11: {
        superior: '',
        direito: '',
        centro: '',
        baixo: '',
        esquerdo: ''
      },
      d12: {
        superior: '',
        direito: '',
        centro: '',
        baixo: '',
        esquerdo: ''
      },
      d13: {
        superior: '',
        direito: '',
        centro: '',
        baixo: '',
        esquerdo: ''
      },
      d14: {
        superior: '',
        direito: '',
        centro: '',
        baixo: '',
        esquerdo: ''
      },
      d15: {
        superior: '',
        direito: '',
        centro: '',
        baixo: '',
        esquerdo: ''
      },
      d16: {
        superior: '',
        direito: '',
        centro: '',
        baixo: '',
        esquerdo: ''
      },
      d17: {
        superior: '',
        direito: '',
        centro: '',
        baixo: '',
        esquerdo: ''
      },
      d18: {
        superior: '',
        direito: '',
        centro: '',
        baixo: '',
        esquerdo: ''
      },
      d21: {
        superior: '',
        direito: '',
        centro: '',
        baixo: '',
        esquerdo: ''
      },
      d22: {
        superior: '',
        direito: '',
        centro: '',
        baixo: '',
        esquerdo: ''
      },
      d23: {
        superior: '',
        direito: '',
        centro: '',
        baixo: '',
        esquerdo: ''
      },
      d24: {
        superior: '',
        direito: '',
        centro: '',
        baixo: '',
        esquerdo: ''
      },
      d25: {
        superior: '',
        direito: '',
        centro: '',
        baixo: '',
        esquerdo: ''
      },
      d26: {
        superior: '',
        direito: '',
        centro: '',
        baixo: '',
        esquerdo: ''
      },
      d27: {
        superior: '',
        direito: '',
        centro: '',
        baixo: '',
        esquerdo: ''
      },
      d28: {
        superior: '',
        direito: '',
        centro: '',
        baixo: '',
        esquerdo: ''
      },
      d31: {
        superior: '',
        direito: '',
        centro: '',
        baixo: '',
        esquerdo: ''
      },
      d32: {
        superior: '',
        direito: '',
        centro: '',
        baixo: '',
        esquerdo: ''
      },
      d33: {
        superior: '',
        direito: '',
        centro: '',
        baixo: '',
        esquerdo: ''
      },
      d34: {
        superior: '',
        direito: '',
        centro: '',
        baixo: '',
        esquerdo: ''
      },
      d35: {
        superior: '',
        direito: '',
        centro: '',
        baixo: '',
        esquerdo: ''
      },
      d36: {
        superior: '',
        direito: '',
        centro: '',
        baixo: '',
        esquerdo: ''
      },
      d37: {
        superior: '',
        direito: '',
        centro: '',
        baixo: '',
        esquerdo: ''
      },
      d38: {
        superior: '',
        direito: '',
        centro: '',
        baixo: '',
        esquerdo: ''
      },
      d41: {
        superior: '',
        direito: '',
        centro: '',
        baixo: '',
        esquerdo: ''
      },
      d42: {
        superior: '',
        direito: '',
        centro: '',
        baixo: '',
        esquerdo: ''
      },
      d43: {
        superior: '',
        direito: '',
        centro: '',
        baixo: '',
        esquerdo: ''
      },
      d44: {
        superior: '',
        direito: '',
        centro: '',
        baixo: '',
        esquerdo: ''
      },
      d45: {
        superior: '',
        direito: '',
        centro: '',
        baixo: '',
        esquerdo: ''
      },
      d46: {
        superior: '',
        direito: '',
        centro: '',
        baixo: '',
        esquerdo: ''
      },
      d47: {
        superior: '',
        direito: '',
        centro: '',
        baixo: '',
        esquerdo: ''
      },
      d48: {
        superior: '',
        direito: '',
        centro: '',
        baixo: '',
        esquerdo: ''
      },
      d51: {
        superior: '',
        direito: '',
        centro: '',
        baixo: '',
        esquerdo: ''
      },
      d52: {
        superior: '',
        direito: '',
        centro: '',
        baixo: '',
        esquerdo: ''
      },
      d53: {
        superior: '',
        direito: '',
        centro: '',
        baixo: '',
        esquerdo: ''
      },
      d54: {
        superior: '',
        direito: '',
        centro: '',
        baixo: '',
        esquerdo: ''
      },
      d55: {
        superior: '',
        direito: '',
        centro: '',
        baixo: '',
        esquerdo: ''
      },
      d61: {
        superior: '',
        direito: '',
        centro: '',
        baixo: '',
        esquerdo: ''
      },
      d62: {
        superior: '',
        direito: '',
        centro: '',
        baixo: '',
        esquerdo: ''
      },
      d63: {
        superior: '',
        direito: '',
        centro: '',
        baixo: '',
        esquerdo: ''
      },
      d64: {
        superior: '',
        direito: '',
        centro: '',
        baixo: '',
        esquerdo: ''
      },
      d65: {
        superior: '',
        direito: '',
        centro: '',
        baixo: '',
        esquerdo: ''
      },
      d71: {
        superior: '',
        direito: '',
        centro: '',
        baixo: '',
        esquerdo: ''
      },
      d72: {
        superior: '',
        direito: '',
        centro: '',
        baixo: '',
        esquerdo: ''
      },
      d73: {
        superior: '',
        direito: '',
        centro: '',
        baixo: '',
        esquerdo: ''
      },
      d74: {
        superior: '',
        direito: '',
        centro: '',
        baixo: '',
        esquerdo: ''
      },
      d75: {
        superior: '',
        direito: '',
        centro: '',
        baixo: '',
        esquerdo: ''
      },
      d81: {
        superior: '',
        direito: '',
        centro: '',
        baixo: '',
        esquerdo: ''
      },
      d82: {
        superior: '',
        direito: '',
        centro: '',
        baixo: '',
        esquerdo: ''
      },
      d83: {
        superior: '',
        direito: '',
        centro: '',
        baixo: '',
        esquerdo: ''
      },
      d84: {
        superior: '',
        direito: '',
        centro: '',
        baixo: '',
        esquerdo: ''
      },
      d85: {
        superior: '',
        direito: '',
        centro: '',
        baixo: '',
        esquerdo: ''
      }
    }
  },
  list: { content: [] },
  loading: true,
  visible: false,
  visibleOdontograma: false,
  loadingOdontograma: true,
  loadingFileStatus: false,
  loadingButtonOdonto: false
}
export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'PACIENTE_FETCHED':
      return {
        ...state,
        list: action.payload.data ? action.payload.data : { content: [] },
        loading: false
      }
    case 'LOADING_PACIENTE':
      return {
        ...state,
        loading: action.payload
      }
    case 'PACIENTE_FETCH':
      return { ...state, paciente: action.payload }
    case 'PACIENTE_CLEAR':
      return { ...state, paciente: INITIAL_STATE.paciente, loadingOdontograma: true }
    case 'PACIENTE_SEARCH':
      return { ...state, list: action.payload.data }
    case 'PACIENTE_FETCH_ID':
      return { ...state, paciente: action.payload.data, loadingOdontograma: false }
    case 'PACIENTE_PESSOA_FETCHED':
      return {
        ...state,
        paciente: {
          ...state.paciente,
          pessoa: action.payload
        }
      }
    case 'PACIENTE_FUNCIONARIO':
      return { ...state, paciente: action.payload }
    case 'MODAL_UPLOAD':
      return { ...state, visible: action.payload }
    case 'LOADING_FILE':
      return { ...state, loadingFileStatus: action.payload }
    case 'LOADING_BUTTON_ODONTOGRAMA':
      return { ...state, loadingButtonOdonto: action.payload }
    case 'LOADING_ODONTOGRAMA':
      return { ...state, loadingOdontograma: action.payload }
    case 'MODAL_ODONTOGRAMA':
      return { ...state, visibleOdontograma: action.payload }
    case 'TOOTH_11_TOP':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d11: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d11: {
              ...state.paciente.odontograma.d11,
              superior: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_11_RIGHT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d11: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d11: {
              ...state.paciente.odontograma.d11,
              direito: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_11_BOTTOM':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d11: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d11: {
              ...state.paciente.odontograma.d11,
              baixo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_11_LEFT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d11: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d11: {
              ...state.paciente.odontograma.d11,
              esquerdo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_11_MIDDLE':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d11: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d11: {
              ...state.paciente.odontograma.d11,
              centro: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_12_TOP':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d12: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d12: {
              ...state.paciente.odontograma.d12,
              superior: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_12_RIGHT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d12: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d12: {
              ...state.paciente.odontograma.d12,
              direito: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_12_BOTTOM':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d12: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d12: {
              ...state.paciente.odontograma.d12,
              baixo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_12_LEFT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d12: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d12: {
              ...state.paciente.odontograma.d12,
              esquerdo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_12_MIDDLE':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d12: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d12: {
              ...state.paciente.odontograma.d12,
              centro: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_13_TOP':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d13: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d13: {
              ...state.paciente.odontograma.d13,
              superior: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_13_RIGHT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d13: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d13: {
              ...state.paciente.odontograma.d13,
              direito: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_13_BOTTOM':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d13: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d13: {
              ...state.paciente.odontograma.d13,
              baixo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_13_LEFT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d13: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d13: {
              ...state.paciente.odontograma.d13,
              esquerdo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_13_MIDDLE':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d13: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d13: {
              ...state.paciente.odontograma.d13,
              centro: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_14_TOP':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d14: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d14: {
              ...state.paciente.odontograma.d14,
              superior: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_14_RIGHT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d14: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d14: {
              ...state.paciente.odontograma.d14,
              direito: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_14_BOTTOM':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d14: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d14: {
              ...state.paciente.odontograma.d14,
              baixo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_14_LEFT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d14: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d14: {
              ...state.paciente.odontograma.d14,
              esquerdo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_14_MIDDLE':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d14: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d14: {
              ...state.paciente.odontograma.d14,
              centro: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_15_TOP':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d15: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d15: {
              ...state.paciente.odontograma.d15,
              superior: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_15_RIGHT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d15: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d15: {
              ...state.paciente.odontograma.d15,
              direito: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_15_BOTTOM':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d15: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d15: {
              ...state.paciente.odontograma.d15,
              baixo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_15_LEFT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d15: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d15: {
              ...state.paciente.odontograma.d15,
              esquerdo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_15_MIDDLE':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d15: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d15: {
              ...state.paciente.odontograma.d15,
              centro: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_16_TOP':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d16: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d16: {
              ...state.paciente.odontograma.d16,
              superior: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_16_RIGHT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d16: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d16: {
              ...state.paciente.odontograma.d16,
              direito: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_16_BOTTOM':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d16: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d16: {
              ...state.paciente.odontograma.d16,
              baixo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_16_LEFT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d16: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d16: {
              ...state.paciente.odontograma.d16,
              esquerdo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_16_MIDDLE':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d16: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d16: {
              ...state.paciente.odontograma.d16,
              centro: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_17_TOP':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d17: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d17: {
              ...state.paciente.odontograma.d17,
              superior: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_17_RIGHT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d17: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d17: {
              ...state.paciente.odontograma.d17,
              direito: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_17_BOTTOM':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d17: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d17: {
              ...state.paciente.odontograma.d17,
              baixo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_17_LEFT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d17: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d17: {
              ...state.paciente.odontograma.d17,
              esquerdo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_17_MIDDLE':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d17: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d17: {
              ...state.paciente.odontograma.d17,
              centro: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_18_TOP':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d18: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d18: {
              ...state.paciente.odontograma.d18,
              superior: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_18_RIGHT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d18: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d18: {
              ...state.paciente.odontograma.d18,
              direito: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_18_BOTTOM':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d18: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d18: {
              ...state.paciente.odontograma.d18,
              baixo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_18_LEFT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d18: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d18: {
              ...state.paciente.odontograma.d18,
              esquerdo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_18_MIDDLE':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d18: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d18: {
              ...state.paciente.odontograma.d18,
              centro: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_21_TOP':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d21: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d21: {
              ...state.paciente.odontograma.d21,
              superior: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_21_RIGHT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d21: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d21: {
              ...state.paciente.odontograma.d21,
              direito: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_21_BOTTOM':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d21: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d21: {
              ...state.paciente.odontograma.d21,
              baixo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_21_LEFT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d21: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d21: {
              ...state.paciente.odontograma.d21,
              esquerdo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_21_MIDDLE':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d21: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d21: {
              ...state.paciente.odontograma.d21,
              centro: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_22_TOP':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d22: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d22: {
              ...state.paciente.odontograma.d22,
              superior: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_22_RIGHT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d22: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d22: {
              ...state.paciente.odontograma.d22,
              direito: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_22_BOTTOM':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d22: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d22: {
              ...state.paciente.odontograma.d22,
              baixo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_22_LEFT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d22: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d22: {
              ...state.paciente.odontograma.d22,
              esquerdo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_22_MIDDLE':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d22: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d22: {
              ...state.paciente.odontograma.d22,
              centro: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_23_TOP':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d23: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d23: {
              ...state.paciente.odontograma.d23,
              superior: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_23_RIGHT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d23: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d23: {
              ...state.paciente.odontograma.d23,
              direito: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_23_BOTTOM':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d23: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d23: {
              ...state.paciente.odontograma.d23,
              baixo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_23_LEFT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d23: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d23: {
              ...state.paciente.odontograma.d23,
              esquerdo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_23_MIDDLE':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d23: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d23: {
              ...state.paciente.odontograma.d23,
              centro: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_24_TOP':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d24: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d24: {
              ...state.paciente.odontograma.d24,
              superior: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_24_RIGHT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d24: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d24: {
              ...state.paciente.odontograma.d24,
              direito: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_24_BOTTOM':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d24: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d24: {
              ...state.paciente.odontograma.d24,
              baixo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_24_LEFT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d24: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d24: {
              ...state.paciente.odontograma.d24,
              esquerdo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_24_MIDDLE':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d24: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d24: {
              ...state.paciente.odontograma.d24,
              centro: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_25_TOP':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d25: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d25: {
              ...state.paciente.odontograma.d25,
              superior: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_25_RIGHT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d25: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d25: {
              ...state.paciente.odontograma.d25,
              direito: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_25_BOTTOM':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d25: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d25: {
              ...state.paciente.odontograma.d25,
              baixo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_25_LEFT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d25: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d25: {
              ...state.paciente.odontograma.d25,
              esquerdo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_25_MIDDLE':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d25: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d25: {
              ...state.paciente.odontograma.d25,
              centro: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_26_TOP':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d26: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d26: {
              ...state.paciente.odontograma.d26,
              superior: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_26_RIGHT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d26: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d26: {
              ...state.paciente.odontograma.d26,
              direito: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_26_BOTTOM':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d26: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d26: {
              ...state.paciente.odontograma.d26,
              baixo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_26_LEFT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d26: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d26: {
              ...state.paciente.odontograma.d26,
              esquerdo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_26_MIDDLE':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d26: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d26: {
              ...state.paciente.odontograma.d26,
              centro: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_27_TOP':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d27: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d27: {
              ...state.paciente.odontograma.d27,
              superior: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_27_RIGHT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d27: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d27: {
              ...state.paciente.odontograma.d27,
              direito: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_27_BOTTOM':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d27: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d27: {
              ...state.paciente.odontograma.d27,
              baixo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_27_LEFT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d27: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d27: {
              ...state.paciente.odontograma.d27,
              esquerdo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_27_MIDDLE':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d27: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d27: {
              ...state.paciente.odontograma.d27,
              centro: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_28_TOP':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d28: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d28: {
              ...state.paciente.odontograma.d28,
              superior: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_28_RIGHT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d28: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d28: {
              ...state.paciente.odontograma.d28,
              direito: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_28_BOTTOM':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d28: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d28: {
              ...state.paciente.odontograma.d28,
              baixo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_28_LEFT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d28: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d28: {
              ...state.paciente.odontograma.d28,
              esquerdo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_28_MIDDLE':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d28: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d28: {
              ...state.paciente.odontograma.d28,
              centro: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_31_TOP':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d31: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d31: {
              ...state.paciente.odontograma.d31,
              superior: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_31_RIGHT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d31: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d31: {
              ...state.paciente.odontograma.d31,
              direito: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_31_BOTTOM':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d31: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d31: {
              ...state.paciente.odontograma.d31,
              baixo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_31_LEFT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d31: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d31: {
              ...state.paciente.odontograma.d31,
              esquerdo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_31_MIDDLE':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d31: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d31: {
              ...state.paciente.odontograma.d31,
              centro: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_32_TOP':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d32: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d32: {
              ...state.paciente.odontograma.d32,
              superior: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_32_RIGHT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d32: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d32: {
              ...state.paciente.odontograma.d32,
              direito: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_32_BOTTOM':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d32: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d32: {
              ...state.paciente.odontograma.d32,
              baixo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_32_LEFT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d32: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d32: {
              ...state.paciente.odontograma.d32,
              esquerdo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_32_MIDDLE':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d32: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d32: {
              ...state.paciente.odontograma.d32,
              centro: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_33_TOP':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d33: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d33: {
              ...state.paciente.odontograma.d33,
              superior: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_33_RIGHT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d33: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d33: {
              ...state.paciente.odontograma.d33,
              direito: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_33_BOTTOM':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d33: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d33: {
              ...state.paciente.odontograma.d33,
              baixo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_33_LEFT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d33: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d33: {
              ...state.paciente.odontograma.d33,
              esquerdo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_33_MIDDLE':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d33: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d33: {
              ...state.paciente.odontograma.d33,
              centro: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_34_TOP':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d34: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d34: {
              ...state.paciente.odontograma.d34,
              superior: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_34_RIGHT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d34: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d34: {
              ...state.paciente.odontograma.d34,
              direito: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_34_BOTTOM':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d34: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d34: {
              ...state.paciente.odontograma.d34,
              baixo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_34_LEFT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d34: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d34: {
              ...state.paciente.odontograma.d34,
              esquerdo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_34_MIDDLE':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d34: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d34: {
              ...state.paciente.odontograma.d34,
              centro: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_35_TOP':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d35: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d35: {
              ...state.paciente.odontograma.d35,
              superior: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_35_RIGHT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d35: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d35: {
              ...state.paciente.odontograma.d35,
              direito: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_35_BOTTOM':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d35: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d35: {
              ...state.paciente.odontograma.d35,
              baixo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_35_LEFT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d35: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d35: {
              ...state.paciente.odontograma.d35,
              esquerdo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_35_MIDDLE':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d35: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d35: {
              ...state.paciente.odontograma.d35,
              centro: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_36_TOP':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d36: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d36: {
              ...state.paciente.odontograma.d36,
              superior: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_36_RIGHT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d36: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d36: {
              ...state.paciente.odontograma.d36,
              direito: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_36_BOTTOM':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d36: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d36: {
              ...state.paciente.odontograma.d36,
              baixo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_36_LEFT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d36: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d36: {
              ...state.paciente.odontograma.d36,
              esquerdo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_36_MIDDLE':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d36: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d36: {
              ...state.paciente.odontograma.d36,
              centro: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_37_TOP':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d37: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d37: {
              ...state.paciente.odontograma.d37,
              superior: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_37_RIGHT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d37: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d37: {
              ...state.paciente.odontograma.d37,
              direito: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_37_BOTTOM':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d37: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d37: {
              ...state.paciente.odontograma.d37,
              baixo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_37_LEFT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d37: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d37: {
              ...state.paciente.odontograma.d37,
              esquerdo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_37_MIDDLE':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d37: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d37: {
              ...state.paciente.odontograma.d37,
              centro: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_38_TOP':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d38: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d38: {
              ...state.paciente.odontograma.d38,
              superior: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_38_RIGHT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d38: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d38: {
              ...state.paciente.odontograma.d38,
              direito: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_38_BOTTOM':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d38: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d38: {
              ...state.paciente.odontograma.d38,
              baixo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_38_LEFT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d38: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d38: {
              ...state.paciente.odontograma.d38,
              esquerdo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_38_MIDDLE':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d38: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d38: {
              ...state.paciente.odontograma.d38,
              centro: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_41_TOP':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d41: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d41: {
              ...state.paciente.odontograma.d41,
              superior: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_41_RIGHT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d41: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d41: {
              ...state.paciente.odontograma.d41,
              direito: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_41_BOTTOM':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d41: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d41: {
              ...state.paciente.odontograma.d41,
              baixo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_41_LEFT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d41: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d41: {
              ...state.paciente.odontograma.d41,
              esquerdo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_41_MIDDLE':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d41: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d41: {
              ...state.paciente.odontograma.d41,
              centro: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_42_TOP':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d42: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d42: {
              ...state.paciente.odontograma.d42,
              superior: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_42_RIGHT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d42: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d42: {
              ...state.paciente.odontograma.d42,
              direito: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_42_BOTTOM':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d42: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d42: {
              ...state.paciente.odontograma.d42,
              baixo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_42_LEFT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d42: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d42: {
              ...state.paciente.odontograma.d42,
              esquerdo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_42_MIDDLE':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d42: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d42: {
              ...state.paciente.odontograma.d42,
              centro: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_43_TOP':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d43: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d43: {
              ...state.paciente.odontograma.d43,
              superior: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_43_RIGHT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d43: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d43: {
              ...state.paciente.odontograma.d43,
              direito: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_43_BOTTOM':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d43: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d43: {
              ...state.paciente.odontograma.d43,
              baixo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_43_LEFT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d43: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d43: {
              ...state.paciente.odontograma.d43,
              esquerdo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_43_MIDDLE':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d43: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d43: {
              ...state.paciente.odontograma.d43,
              centro: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_44_TOP':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d44: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d44: {
              ...state.paciente.odontograma.d44,
              superior: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_44_RIGHT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d44: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d44: {
              ...state.paciente.odontograma.d44,
              direito: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_44_BOTTOM':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d44: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d44: {
              ...state.paciente.odontograma.d44,
              baixo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_44_LEFT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d44: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d44: {
              ...state.paciente.odontograma.d44,
              esquerdo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_44_MIDDLE':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d44: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d44: {
              ...state.paciente.odontograma.d44,
              centro: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_45_TOP':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d45: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d45: {
              ...state.paciente.odontograma.d45,
              superior: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_45_RIGHT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d45: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d45: {
              ...state.paciente.odontograma.d45,
              direito: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_45_BOTTOM':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d45: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d45: {
              ...state.paciente.odontograma.d45,
              baixo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_45_LEFT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d45: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d45: {
              ...state.paciente.odontograma.d45,
              esquerdo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_45_MIDDLE':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d45: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d45: {
              ...state.paciente.odontograma.d45,
              centro: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_46_TOP':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d46: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d46: {
              ...state.paciente.odontograma.d46,
              superior: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_46_RIGHT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d46: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d46: {
              ...state.paciente.odontograma.d46,
              direito: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_46_BOTTOM':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d46: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d46: {
              ...state.paciente.odontograma.d46,
              baixo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_46_LEFT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d46: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d46: {
              ...state.paciente.odontograma.d46,
              esquerdo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_46_MIDDLE':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d46: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d46: {
              ...state.paciente.odontograma.d46,
              centro: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_47_TOP':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d47: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d47: {
              ...state.paciente.odontograma.d47,
              superior: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_47_RIGHT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d47: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d47: {
              ...state.paciente.odontograma.d47,
              direito: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_47_BOTTOM':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d47: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d47: {
              ...state.paciente.odontograma.d47,
              baixo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_47_LEFT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d47: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d47: {
              ...state.paciente.odontograma.d47,
              esquerdo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_47_MIDDLE':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d47: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d47: {
              ...state.paciente.odontograma.d47,
              centro: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_48_TOP':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d48: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d48: {
              ...state.paciente.odontograma.d48,
              superior: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_48_RIGHT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d48: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d48: {
              ...state.paciente.odontograma.d48,
              direito: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_48_BOTTOM':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d48: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d48: {
              ...state.paciente.odontograma.d48,
              baixo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_48_LEFT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d48: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d48: {
              ...state.paciente.odontograma.d48,
              esquerdo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_48_MIDDLE':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d48: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d48: {
              ...state.paciente.odontograma.d48,
              centro: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_51_TOP':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d51: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d51: {
              ...state.paciente.odontograma.d51,
              superior: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_51_RIGHT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d51: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d51: {
              ...state.paciente.odontograma.d51,
              direito: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_51_BOTTOM':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d51: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d51: {
              ...state.paciente.odontograma.d51,
              baixo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_51_LEFT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d51: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d51: {
              ...state.paciente.odontograma.d51,
              esquerdo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_51_MIDDLE':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d51: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d51: {
              ...state.paciente.odontograma.d51,
              centro: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_52_TOP':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d52: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d52: {
              ...state.paciente.odontograma.d52,
              superior: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_52_RIGHT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d52: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d52: {
              ...state.paciente.odontograma.d52,
              direito: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_52_BOTTOM':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d52: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d52: {
              ...state.paciente.odontograma.d52,
              baixo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_52_LEFT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d52: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d52: {
              ...state.paciente.odontograma.d52,
              esquerdo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_52_MIDDLE':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d52: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d52: {
              ...state.paciente.odontograma.d52,
              centro: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_53_TOP':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d53: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d53: {
              ...state.paciente.odontograma.d53,
              superior: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_53_RIGHT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d53: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d53: {
              ...state.paciente.odontograma.d53,
              direito: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_53_BOTTOM':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d53: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d53: {
              ...state.paciente.odontograma.d53,
              baixo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_53_LEFT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d53: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d53: {
              ...state.paciente.odontograma.d53,
              esquerdo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_53_MIDDLE':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d53: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d53: {
              ...state.paciente.odontograma.d53,
              centro: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_54_TOP':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d54: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d54: {
              ...state.paciente.odontograma.d54,
              superior: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_54_RIGHT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d54: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d54: {
              ...state.paciente.odontograma.d54,
              direito: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_54_BOTTOM':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d54: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d54: {
              ...state.paciente.odontograma.d54,
              baixo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_54_LEFT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d54: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d54: {
              ...state.paciente.odontograma.d54,
              esquerdo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_54_MIDDLE':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d54: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d54: {
              ...state.paciente.odontograma.d54,
              centro: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_55_TOP':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d55: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d55: {
              ...state.paciente.odontograma.d55,
              superior: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_55_RIGHT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d55: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d55: {
              ...state.paciente.odontograma.d55,
              direito: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_55_BOTTOM':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d55: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d55: {
              ...state.paciente.odontograma.d55,
              baixo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_55_LEFT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d55: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d55: {
              ...state.paciente.odontograma.d55,
              esquerdo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_55_MIDDLE':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d55: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d55: {
              ...state.paciente.odontograma.d55,
              centro: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_61_TOP':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d61: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d61: {
              ...state.paciente.odontograma.d61,
              superior: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_61_RIGHT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d61: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d61: {
              ...state.paciente.odontograma.d61,
              direito: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_61_BOTTOM':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d61: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d61: {
              ...state.paciente.odontograma.d61,
              baixo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_61_LEFT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d61: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d61: {
              ...state.paciente.odontograma.d61,
              esquerdo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_61_MIDDLE':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d61: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d61: {
              ...state.paciente.odontograma.d61,
              centro: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_62_TOP':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d62: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d62: {
              ...state.paciente.odontograma.d62,
              superior: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_62_RIGHT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d62: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d62: {
              ...state.paciente.odontograma.d62,
              direito: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_62_BOTTOM':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d62: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d62: {
              ...state.paciente.odontograma.d62,
              baixo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_62_LEFT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d62: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d62: {
              ...state.paciente.odontograma.d62,
              esquerdo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_62_MIDDLE':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d62: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d62: {
              ...state.paciente.odontograma.d62,
              centro: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_63_TOP':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d63: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d63: {
              ...state.paciente.odontograma.d63,
              superior: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_63_RIGHT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d63: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d63: {
              ...state.paciente.odontograma.d63,
              direito: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_63_BOTTOM':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d63: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d63: {
              ...state.paciente.odontograma.d63,
              baixo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_63_LEFT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d63: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d63: {
              ...state.paciente.odontograma.d63,
              esquerdo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_63_MIDDLE':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d63: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d63: {
              ...state.paciente.odontograma.d63,
              centro: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_64_TOP':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d64: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d64: {
              ...state.paciente.odontograma.d64,
              superior: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_64_RIGHT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d64: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d64: {
              ...state.paciente.odontograma.d64,
              direito: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_64_BOTTOM':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d64: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d64: {
              ...state.paciente.odontograma.d64,
              baixo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_64_LEFT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d64: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d64: {
              ...state.paciente.odontograma.d64,
              esquerdo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_64_MIDDLE':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d64: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d64: {
              ...state.paciente.odontograma.d64,
              centro: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_65_TOP':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d65: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d65: {
              ...state.paciente.odontograma.d65,
              superior: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_65_RIGHT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d65: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d65: {
              ...state.paciente.odontograma.d65,
              direito: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_65_BOTTOM':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d65: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d65: {
              ...state.paciente.odontograma.d65,
              baixo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_65_LEFT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d65: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d65: {
              ...state.paciente.odontograma.d65,
              esquerdo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_65_MIDDLE':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d65: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d65: {
              ...state.paciente.odontograma.d65,
              centro: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_71_TOP':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d71: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d71: {
              ...state.paciente.odontograma.d71,
              superior: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_71_RIGHT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d71: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d71: {
              ...state.paciente.odontograma.d71,
              direito: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_71_BOTTOM':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d71: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d71: {
              ...state.paciente.odontograma.d71,
              baixo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_71_LEFT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d71: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d71: {
              ...state.paciente.odontograma.d71,
              esquerdo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_71_MIDDLE':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d71: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d71: {
              ...state.paciente.odontograma.d71,
              centro: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_72_TOP':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d72: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d72: {
              ...state.paciente.odontograma.d72,
              superior: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_72_RIGHT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d72: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d72: {
              ...state.paciente.odontograma.d72,
              direito: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_72_BOTTOM':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d72: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d72: {
              ...state.paciente.odontograma.d72,
              baixo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_72_LEFT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d72: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d72: {
              ...state.paciente.odontograma.d72,
              esquerdo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_72_MIDDLE':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d72: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d72: {
              ...state.paciente.odontograma.d72,
              centro: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_73_TOP':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d73: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d73: {
              ...state.paciente.odontograma.d73,
              superior: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_73_RIGHT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d73: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d73: {
              ...state.paciente.odontograma.d73,
              direito: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_73_BOTTOM':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d73: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d73: {
              ...state.paciente.odontograma.d73,
              baixo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_73_LEFT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d73: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d73: {
              ...state.paciente.odontograma.d73,
              esquerdo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_73_MIDDLE':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d73: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d73: {
              ...state.paciente.odontograma.d73,
              centro: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_74_TOP':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d74: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d74: {
              ...state.paciente.odontograma.d74,
              superior: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_74_RIGHT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d74: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d74: {
              ...state.paciente.odontograma.d74,
              direito: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_74_BOTTOM':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d74: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d74: {
              ...state.paciente.odontograma.d74,
              baixo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_74_LEFT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d74: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d74: {
              ...state.paciente.odontograma.d74,
              esquerdo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_74_MIDDLE':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d74: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d74: {
              ...state.paciente.odontograma.d74,
              centro: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_75_TOP':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d75: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d75: {
              ...state.paciente.odontograma.d75,
              superior: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_75_RIGHT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d75: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d75: {
              ...state.paciente.odontograma.d75,
              direito: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_75_BOTTOM':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d75: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d75: {
              ...state.paciente.odontograma.d75,
              baixo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_75_LEFT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d75: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d75: {
              ...state.paciente.odontograma.d75,
              esquerdo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_75_MIDDLE':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d75: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d75: {
              ...state.paciente.odontograma.d75,
              centro: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_81_TOP':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d81: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d81: {
              ...state.paciente.odontograma.d81,
              superior: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_81_RIGHT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d81: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d81: {
              ...state.paciente.odontograma.d81,
              direito: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_81_BOTTOM':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d81: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d81: {
              ...state.paciente.odontograma.d81,
              baixo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_81_LEFT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d81: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d81: {
              ...state.paciente.odontograma.d81,
              esquerdo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_81_MIDDLE':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d81: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d81: {
              ...state.paciente.odontograma.d81,
              centro: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_82_TOP':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d82: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d82: {
              ...state.paciente.odontograma.d82,
              superior: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_82_RIGHT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d82: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d82: {
              ...state.paciente.odontograma.d82,
              direito: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_82_BOTTOM':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d82: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d82: {
              ...state.paciente.odontograma.d82,
              baixo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_82_LEFT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d82: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d82: {
              ...state.paciente.odontograma.d82,
              esquerdo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_82_MIDDLE':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d82: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d82: {
              ...state.paciente.odontograma.d82,
              centro: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_83_TOP':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d83: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d83: {
              ...state.paciente.odontograma.d83,
              superior: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_83_RIGHT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d83: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d83: {
              ...state.paciente.odontograma.d83,
              direito: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_83_BOTTOM':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d83: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d83: {
              ...state.paciente.odontograma.d83,
              baixo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_83_LEFT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d83: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d83: {
              ...state.paciente.odontograma.d83,
              esquerdo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_83_MIDDLE':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d83: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d83: {
              ...state.paciente.odontograma.d83,
              centro: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_84_TOP':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d84: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d84: {
              ...state.paciente.odontograma.d84,
              superior: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_84_RIGHT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d84: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d84: {
              ...state.paciente.odontograma.d84,
              direito: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_84_BOTTOM':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d84: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d84: {
              ...state.paciente.odontograma.d84,
              baixo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_84_LEFT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d84: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d84: {
              ...state.paciente.odontograma.d84,
              esquerdo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_84_MIDDLE':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d84: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d84: {
              ...state.paciente.odontograma.d84,
              centro: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_85_TOP':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d85: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d85: {
              ...state.paciente.odontograma.d85,
              superior: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_85_RIGHT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d85: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d85: {
              ...state.paciente.odontograma.d85,
              direito: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_85_BOTTOM':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d85: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d85: {
              ...state.paciente.odontograma.d85,
              baixo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_85_LEFT':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d85: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d85: {
              ...state.paciente.odontograma.d85,
              esquerdo: action.payload.color
            }
          }
        }
      }
    case 'TOOTH_85_MIDDLE':
      if (action.payload.color === 7 || action.payload.color === 8 || action.payload.color === 9 || action.payload.color === 10 || action.payload.color === 11) {
        return {
          ...state,
          paciente: {
            ...state.paciente,
            odontograma: {
              ...state.paciente.odontograma,
              d85: {
                superior: 0,
                direito: 0,
                baixo: 0,
                esquerdo: 0,
                centro: action.payload.color
              }
            }
          }
        }
      }
      return {
        ...state,
        paciente: {
          ...state.paciente,
          odontograma: {
            ...state.paciente.odontograma,
            d85: {
              ...state.paciente.odontograma.d85,
              centro: action.payload.color
            }
          }
        }
      }

    default:
      return state
  }
}