import React from 'react';
import PropTypes from "prop-types";
import { Select } from 'antd';

const Option = Select.Option;

export class SelectConsultorio extends React.Component {

  static propTypes = {
    consultorios: PropTypes.array
  };

  render() {
    const { consultorios } = this.props;
    return (
      <div>
        <label htmlFor="Consultorio">Consultório: </label>
        <Select
          {...this.props}
          notFoundContent="Consultórios não encontrado"
          placeholder="Selecione o consultório">
          {consultorios.map(c =>
            <Option
              onClick={() => this.props.onClick(c)}
              key={c.id}
              value={c.id}>
              {c.razaoSocial || c.nome + " - " + c.endereco.cidade.nome}
            </Option>
          )}
        </Select>
      </div>
    );
  }
}