import React from 'react';
import NumberFormat from 'react-number-format';

export class InputCurrencyBRL extends React.Component {
  
  render() {
    return (
      <NumberFormat
        {...this.props}
        className='ant-input'
        thousandSeparator='.'
        decimalSeparator=','
        decimalScale={2}
        fixedDecimalScale={true}
      />
    );
  }
}