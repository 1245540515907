const INITIAL_STATE = {
  atendimento: {
    paciente: {
      id: null
    },
    servicos: [],
    valor: 0
  },
  list: { content: [] },
  loading: true
}
export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'ATENDIMENTO_FETCHED':
      return {
        ...state,
        list: action.payload.data ? action.payload.data : { content: [] },
        loading: false
      }
    case 'CARGO_ADDED':
      return { ...state }
    case 'ATENDIMENTO_FETCH':
      return { ...state, atendimento: action.payload }
    case 'ATENDIMENTO_CLEAR':
      return { ...state, atendimento: INITIAL_STATE.atendimento }
    case 'ATENDIMENTO_SEARCH':
      return { ...state, list: action.payload.data }
    case 'ATENDIMENTO_SEARCH_BY_DATE':
      return { ...state, list: action.payload.data }
    case 'ATENDIMENTO_FETCH_ID':
      return { ...state, atendimento: action.payload.data }
    case 'LOADING_ATENDIMENTO':
      return { ...state, loading: action.payload }

    default:
      return state
  }
}