import React from 'react';
import { Spin, Icon } from 'antd';
import Chart from "react-google-charts";
import moment from 'moment';
import PropTypes from "prop-types";

export default class PieChart extends React.Component {

  static propTypes = {
    values: PropTypes.shape({
      valorTotal: PropTypes.number,
      atendimentos: PropTypes.array,
    })
  };

  static defaultProps = {
    values: PropTypes.shape({
      valorTotal: 0
    })
  };

  render() {
    const { values, user, title, is3D } = this.props;
    const chartTitle = user.isAtendente ? `Volume total do mês de ${moment().format('MMMM')}` : `Volume total do mês de ${moment().format('MMMM')}: ${(values.valorTotal || 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}`;
    const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
    
    let data = [['Serviço', 'Quantidade']];
    const services = [];
    (values.atendimentos || []).map(atend => services.push(...atend.servicos));
    const nome = (services || []).map(serv => serv.nome)
    nome.sort();
    let current = null;
    let cnt = 0;
    (nome || []).forEach(n => {
      if (n !== current) {
        if (cnt > 0) {
          data.push([current, cnt]);
        }
        current = n
        cnt = 1;
      } else {
        cnt++;
      }
    })
    if (cnt > 0) {
      data.push([current, cnt]);
    }
    return (
      <React.Fragment>
        <Chart
          width={'660px'}
          height={'300px'}
          chartType="PieChart"
          loader={<Spin indicator={antIcon} />}
          data={[
            ...data
          ]}
          options={{
            title: title ? title : chartTitle,
            is3D: is3D
          }}
          rootProps={{ 'data-testid': '1' }}
        />
      </React.Fragment>
    );
  }

}
