import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import ReactGA from 'react-ga';

import './App.css';
import Routes from './Routes';
import history from '../services/history';

class App extends Component {
  
  render() {
    if (process.env.NODE_ENV === 'production') {
      history.listen(location => {
        ReactGA.initialize('UA-155297118-1');
        ReactGA.pageview(location.pathname + location.search);
      });
    }
    return (
      <Router history={history}>
        <Routes />
      </Router>
    );
  }
}

export default App;