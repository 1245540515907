const INITIAL_STATE = {
  servico: {},
  list: { content: [] },
  loading: true
}
export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SERVICO_FETCHED':
      return { 
        ...state, 
        list: action.payload.data ? action.payload.data : { content: [] }, 
        loading: false 
      }
    case 'SERVICO_ADDED':
      return { ...state }
    case 'SERVICO_FETCH':
      return { ...state, servico: action.payload }
    case 'SERVICO_CLEAR':
      return { ...state, servico: action.payload }
    case 'SERVICO_SEARCH':
      return { ...state, list: action.payload.data }
    case 'SERVICO_FETCH_ID':
      return { ...state, servico: action.payload.data}
    case 'LOADING_SERVICO':
      return { ...state, loading: action.payload}
    
    default:
      return state
  }
}