import { message } from 'antd';

import api from '../../../services/api';

export function getPacienteById(id) {
  const request = api.get(`pacientes/${id}`);
  return {
    type: 'PACIENTE_FETCH_ID',
    payload: request
  }
}

export function findPessoaByCpf({ cpf }) {
  return dispatch => {
    api.get(`pessoas/cpf?cpf=${cpf}`)
      .then(resp => {
        dispatch([
          findPessoa(resp.data)
        ])
      }).catch(error => {});
  }
}

export function findPessoa(values) {
  return {
    type: 'PACIENTE_PESSOA_FETCHED',
    payload: values
  }
}

export function loadingPaciente(status) {
  return {
    type: 'LOADING_PACIENTE',
    payload: status
  }
}

export function upload(pacienteId, file) {
  return dispatch => {
    api.post(`pacientes/upload?paciente=${pacienteId}`, file)
      .then(resp => {
        message.success('Upload realizado com sucesso!')
        dispatch([
          loadingFile(false),
          getPaciente(resp.data)
        ])
      }).catch(error => {
        loadingFile(false)
      })
  }
}

export function loadingFile(status) {
  return {
    type: 'LOADING_FILE',
    payload: status
  }
}

export function updatePacienteFiles(values) {
  return dispatch => {
    api.put(`pacientes/${values.id}`, values)
      .then(resp => {
        message.success('Operação realizada com sucesso!')
        dispatch([
          getPacienteById(values.id)
        ])
      });
  }
}

export function removeFile(id, pacienteId) {
  return dispatch => {
    api.delete(`pacientes/arquivo/${id}?pacienteId=${pacienteId}`)
      .then(resp => {
        message.success('Operação realizada com sucesso!')
        dispatch([
          loadingFile(false),
          getPaciente(resp.data)
        ])
      }).catch(error => {
        loadingFile(false);
      })
  }
}

export function getPaciente(values) {
  return {
    type: 'PACIENTE_FETCH',
    payload: values
  }
}

export function getPacientes({ consultorio, page, linesPerPage } = {}) {
  const request = api.get(`pacientes`, {
    params: {
      consultorio,
      page,
      linesPerPage
    }
  });
  return {
    type: 'PACIENTE_FETCHED',
    payload: request
  }
}

export function searchPaciente({ text, consultorio } = {}) {
  const request = api.get(`pacientes/search`, {
    params: {
      text,
      consultorio
    }
  });
  return {
    type: 'PACIENTE_SEARCH',
    payload: request
  }
}

export function addPaciente(values, param) {
  return submit(values, 'post', param)
}

// export function updatePaciente(values, param) {
//   return submit(values, 'put', param)
// }

export function updatePaciente(values, param) {
  return dispatch => {
    dispatch([
      loadingButtonOdontograma(true),
      submit(values, 'put', param)
    ])
  }
}

export function removePaciente(values, param) {
  return submit(values, 'delete', param)
}

function submit(values, method, param) {
  return dispatch => {
    const id = values.id ? values.id : ''
    const consultorio = param.id ? param.id : ''
    api[method](`pacientes/${id}`, values)
      .then(resp => {
        message.success('Operação realizada com sucesso!')
        dispatch([
          modalOdontograma(false),
          loadingButtonOdontograma(false),
          getPacientes({ consultorio, page: 0, linesPerPage: 15 }),
          clearPaciente()
          //updateStateFuncionario(resp.data)
        ])
      }).catch(error => {
        loadingButtonOdontograma(false)
      })
  }
}

export function loadingButtonOdontograma(status) {
  return {
    type: 'LOADING_BUTTON_ODONTOGRAMA',
    payload: status
  }
}

export function loadingOdontogramaFunc(status) {
  return {
    type: 'LOADING_ODONTOGRAMA',
    payload: status
  }
}

export function clearPaciente() {
  return {
    type: 'PACIENTE_CLEAR',
    payload: null
  }
}

export function updateStateFuncionario(values) {
  return {
    type: 'UPDATE_FUNCIONARIO',
    payload: values
  }
}

export function modal(status) {
  return {
    type: 'MODAL_UPLOAD',
    payload: status
  }
}

export function modalOdontograma(status) {
  return {
    type: 'MODAL_ODONTOGRAMA',
    payload: status
  }
}

export function updateOdontograma(values) {
  if (values.tooth === 'd11' && values.position === 'superior') return tooth11Top(values);
  if (values.tooth === 'd11' && values.position === 'direito') return tooth11Right(values);
  if (values.tooth === 'd11' && values.position === 'baixo') return tooth11Bottom(values);
  if (values.tooth === 'd11' && values.position === 'esquerdo') return tooth11Left(values);
  if (values.tooth === 'd11' && values.position === 'centro') return tooth11Middle(values);
  if (values.tooth === 'd12' && values.position === 'superior') return tooth12Top(values);
  if (values.tooth === 'd12' && values.position === 'direito') return tooth12Right(values);
  if (values.tooth === 'd12' && values.position === 'baixo') return tooth12Bottom(values);
  if (values.tooth === 'd12' && values.position === 'esquerdo') return tooth12Left(values);
  if (values.tooth === 'd12' && values.position === 'centro') return tooth12Middle(values);
  if (values.tooth === 'd13' && values.position === 'superior') return tooth13Top(values);
  if (values.tooth === 'd13' && values.position === 'direito') return tooth13Right(values);
  if (values.tooth === 'd13' && values.position === 'baixo') return tooth13Bottom(values);
  if (values.tooth === 'd13' && values.position === 'esquerdo') return tooth13Left(values);
  if (values.tooth === 'd13' && values.position === 'centro') return tooth13Middle(values);
  if (values.tooth === 'd14' && values.position === 'superior') return tooth14Top(values);
  if (values.tooth === 'd14' && values.position === 'direito') return tooth14Right(values);
  if (values.tooth === 'd14' && values.position === 'baixo') return tooth14Bottom(values);
  if (values.tooth === 'd14' && values.position === 'esquerdo') return tooth14Left(values);
  if (values.tooth === 'd14' && values.position === 'centro') return tooth14Middle(values);
  if (values.tooth === 'd15' && values.position === 'superior') return tooth15Top(values);
  if (values.tooth === 'd15' && values.position === 'direito') return tooth15Right(values);
  if (values.tooth === 'd15' && values.position === 'baixo') return tooth15Bottom(values);
  if (values.tooth === 'd15' && values.position === 'esquerdo') return tooth15Left(values);
  if (values.tooth === 'd15' && values.position === 'centro') return tooth15Middle(values);
  if (values.tooth === 'd16' && values.position === 'superior') return tooth16Top(values);
  if (values.tooth === 'd16' && values.position === 'direito') return tooth16Right(values);
  if (values.tooth === 'd16' && values.position === 'baixo') return tooth16Bottom(values);
  if (values.tooth === 'd16' && values.position === 'esquerdo') return tooth16Left(values);
  if (values.tooth === 'd16' && values.position === 'centro') return tooth16Middle(values);
  if (values.tooth === 'd17' && values.position === 'superior') return tooth17Top(values);
  if (values.tooth === 'd17' && values.position === 'direito') return tooth17Right(values);
  if (values.tooth === 'd17' && values.position === 'baixo') return tooth17Bottom(values);
  if (values.tooth === 'd17' && values.position === 'esquerdo') return tooth17Left(values);
  if (values.tooth === 'd17' && values.position === 'centro') return tooth17Middle(values);
  if (values.tooth === 'd18' && values.position === 'superior') return tooth18Top(values);
  if (values.tooth === 'd18' && values.position === 'direito') return tooth18Right(values);
  if (values.tooth === 'd18' && values.position === 'baixo') return tooth18Bottom(values);
  if (values.tooth === 'd18' && values.position === 'esquerdo') return tooth18Left(values);
  if (values.tooth === 'd18' && values.position === 'centro') return tooth18Middle(values);
  if (values.tooth === 'd21' && values.position === 'superior') return tooth21Top(values);
  if (values.tooth === 'd21' && values.position === 'direito') return tooth21Right(values);
  if (values.tooth === 'd21' && values.position === 'baixo') return tooth21Bottom(values);
  if (values.tooth === 'd21' && values.position === 'esquerdo') return tooth21Left(values);
  if (values.tooth === 'd21' && values.position === 'centro') return tooth21Middle(values);
  if (values.tooth === 'd22' && values.position === 'superior') return tooth22Top(values);
  if (values.tooth === 'd22' && values.position === 'direito') return tooth22Right(values);
  if (values.tooth === 'd22' && values.position === 'baixo') return tooth22Bottom(values);
  if (values.tooth === 'd22' && values.position === 'esquerdo') return tooth22Left(values);
  if (values.tooth === 'd22' && values.position === 'centro') return tooth22Middle(values);
  if (values.tooth === 'd23' && values.position === 'superior') return tooth23Top(values);
  if (values.tooth === 'd23' && values.position === 'direito') return tooth23Right(values);
  if (values.tooth === 'd23' && values.position === 'baixo') return tooth23Bottom(values);
  if (values.tooth === 'd23' && values.position === 'esquerdo') return tooth23Left(values);
  if (values.tooth === 'd23' && values.position === 'centro') return tooth23Middle(values);
  if (values.tooth === 'd24' && values.position === 'superior') return tooth24Top(values);
  if (values.tooth === 'd24' && values.position === 'direito') return tooth24Right(values);
  if (values.tooth === 'd24' && values.position === 'baixo') return tooth24Bottom(values);
  if (values.tooth === 'd24' && values.position === 'esquerdo') return tooth24Left(values);
  if (values.tooth === 'd24' && values.position === 'centro') return tooth24Middle(values);
  if (values.tooth === 'd25' && values.position === 'superior') return tooth25Top(values);
  if (values.tooth === 'd25' && values.position === 'direito') return tooth25Right(values);
  if (values.tooth === 'd25' && values.position === 'baixo') return tooth25Bottom(values);
  if (values.tooth === 'd25' && values.position === 'esquerdo') return tooth25Left(values);
  if (values.tooth === 'd25' && values.position === 'centro') return tooth25Middle(values);
  if (values.tooth === 'd26' && values.position === 'superior') return tooth26Top(values);
  if (values.tooth === 'd26' && values.position === 'direito') return tooth26Right(values);
  if (values.tooth === 'd26' && values.position === 'baixo') return tooth26Bottom(values);
  if (values.tooth === 'd26' && values.position === 'esquerdo') return tooth26Left(values);
  if (values.tooth === 'd26' && values.position === 'centro') return tooth26Middle(values);
  if (values.tooth === 'd27' && values.position === 'superior') return tooth27Top(values);
  if (values.tooth === 'd27' && values.position === 'direito') return tooth27Right(values);
  if (values.tooth === 'd27' && values.position === 'baixo') return tooth27Bottom(values);
  if (values.tooth === 'd27' && values.position === 'esquerdo') return tooth27Left(values);
  if (values.tooth === 'd27' && values.position === 'centro') return tooth27Middle(values);
  if (values.tooth === 'd28' && values.position === 'superior') return tooth28Top(values);
  if (values.tooth === 'd28' && values.position === 'direito') return tooth28Right(values);
  if (values.tooth === 'd28' && values.position === 'baixo') return tooth28Bottom(values);
  if (values.tooth === 'd28' && values.position === 'esquerdo') return tooth28Left(values);
  if (values.tooth === 'd28' && values.position === 'centro') return tooth28Middle(values);
  if (values.tooth === 'd31' && values.position === 'superior') return tooth31Top(values);
  if (values.tooth === 'd31' && values.position === 'direito') return tooth31Right(values);
  if (values.tooth === 'd31' && values.position === 'baixo') return tooth31Bottom(values);
  if (values.tooth === 'd31' && values.position === 'esquerdo') return tooth31Left(values);
  if (values.tooth === 'd31' && values.position === 'centro') return tooth31Middle(values);
  if (values.tooth === 'd32' && values.position === 'superior') return tooth32Top(values);
  if (values.tooth === 'd32' && values.position === 'direito') return tooth32Right(values);
  if (values.tooth === 'd32' && values.position === 'baixo') return tooth32Bottom(values);
  if (values.tooth === 'd32' && values.position === 'esquerdo') return tooth32Left(values);
  if (values.tooth === 'd32' && values.position === 'centro') return tooth32Middle(values);
  if (values.tooth === 'd33' && values.position === 'superior') return tooth33Top(values);
  if (values.tooth === 'd33' && values.position === 'direito') return tooth33Right(values);
  if (values.tooth === 'd33' && values.position === 'baixo') return tooth33Bottom(values);
  if (values.tooth === 'd33' && values.position === 'esquerdo') return tooth33Left(values);
  if (values.tooth === 'd33' && values.position === 'centro') return tooth33Middle(values);
  if (values.tooth === 'd34' && values.position === 'superior') return tooth34Top(values);
  if (values.tooth === 'd34' && values.position === 'direito') return tooth34Right(values);
  if (values.tooth === 'd34' && values.position === 'baixo') return tooth34Bottom(values);
  if (values.tooth === 'd34' && values.position === 'esquerdo') return tooth34Left(values);
  if (values.tooth === 'd34' && values.position === 'centro') return tooth34Middle(values);
  if (values.tooth === 'd35' && values.position === 'superior') return tooth35Top(values);
  if (values.tooth === 'd35' && values.position === 'direito') return tooth35Right(values);
  if (values.tooth === 'd35' && values.position === 'baixo') return tooth35Bottom(values);
  if (values.tooth === 'd35' && values.position === 'esquerdo') return tooth35Left(values);
  if (values.tooth === 'd35' && values.position === 'centro') return tooth35Middle(values);
  if (values.tooth === 'd36' && values.position === 'superior') return tooth36Top(values);
  if (values.tooth === 'd36' && values.position === 'direito') return tooth36Right(values);
  if (values.tooth === 'd36' && values.position === 'baixo') return tooth36Bottom(values);
  if (values.tooth === 'd36' && values.position === 'esquerdo') return tooth36Left(values);
  if (values.tooth === 'd36' && values.position === 'centro') return tooth36Middle(values);
  if (values.tooth === 'd37' && values.position === 'superior') return tooth37Top(values);
  if (values.tooth === 'd37' && values.position === 'direito') return tooth37Right(values);
  if (values.tooth === 'd37' && values.position === 'baixo') return tooth37Bottom(values);
  if (values.tooth === 'd37' && values.position === 'esquerdo') return tooth37Left(values);
  if (values.tooth === 'd37' && values.position === 'centro') return tooth37Middle(values);
  if (values.tooth === 'd38' && values.position === 'superior') return tooth38Top(values);
  if (values.tooth === 'd38' && values.position === 'direito') return tooth38Right(values);
  if (values.tooth === 'd38' && values.position === 'baixo') return tooth38Bottom(values);
  if (values.tooth === 'd38' && values.position === 'esquerdo') return tooth38Left(values);
  if (values.tooth === 'd38' && values.position === 'centro') return tooth38Middle(values);
  if (values.tooth === 'd41' && values.position === 'superior') return tooth41Top(values);
  if (values.tooth === 'd41' && values.position === 'direito') return tooth41Right(values);
  if (values.tooth === 'd41' && values.position === 'baixo') return tooth41Bottom(values);
  if (values.tooth === 'd41' && values.position === 'esquerdo') return tooth41Left(values);
  if (values.tooth === 'd41' && values.position === 'centro') return tooth41Middle(values);
  if (values.tooth === 'd42' && values.position === 'superior') return tooth42Top(values);
  if (values.tooth === 'd42' && values.position === 'direito') return tooth42Right(values);
  if (values.tooth === 'd42' && values.position === 'baixo') return tooth42Bottom(values);
  if (values.tooth === 'd42' && values.position === 'esquerdo') return tooth42Left(values);
  if (values.tooth === 'd42' && values.position === 'centro') return tooth42Middle(values);
  if (values.tooth === 'd43' && values.position === 'superior') return tooth43Top(values);
  if (values.tooth === 'd43' && values.position === 'direito') return tooth43Right(values);
  if (values.tooth === 'd43' && values.position === 'baixo') return tooth43Bottom(values);
  if (values.tooth === 'd43' && values.position === 'esquerdo') return tooth43Left(values);
  if (values.tooth === 'd43' && values.position === 'centro') return tooth43Middle(values);
  if (values.tooth === 'd44' && values.position === 'superior') return tooth44Top(values);
  if (values.tooth === 'd44' && values.position === 'direito') return tooth44Right(values);
  if (values.tooth === 'd44' && values.position === 'baixo') return tooth44Bottom(values);
  if (values.tooth === 'd44' && values.position === 'esquerdo') return tooth44Left(values);
  if (values.tooth === 'd44' && values.position === 'centro') return tooth44Middle(values);
  if (values.tooth === 'd45' && values.position === 'superior') return tooth45Top(values);
  if (values.tooth === 'd45' && values.position === 'direito') return tooth45Right(values);
  if (values.tooth === 'd45' && values.position === 'baixo') return tooth45Bottom(values);
  if (values.tooth === 'd45' && values.position === 'esquerdo') return tooth45Left(values);
  if (values.tooth === 'd45' && values.position === 'centro') return tooth45Middle(values);
  if (values.tooth === 'd46' && values.position === 'superior') return tooth46Top(values);
  if (values.tooth === 'd46' && values.position === 'direito') return tooth46Right(values);
  if (values.tooth === 'd46' && values.position === 'baixo') return tooth46Bottom(values);
  if (values.tooth === 'd46' && values.position === 'esquerdo') return tooth46Left(values);
  if (values.tooth === 'd46' && values.position === 'centro') return tooth46Middle(values);
  if (values.tooth === 'd47' && values.position === 'superior') return tooth47Top(values);
  if (values.tooth === 'd47' && values.position === 'direito') return tooth47Right(values);
  if (values.tooth === 'd47' && values.position === 'baixo') return tooth47Bottom(values);
  if (values.tooth === 'd47' && values.position === 'esquerdo') return tooth47Left(values);
  if (values.tooth === 'd47' && values.position === 'centro') return tooth47Middle(values);
  if (values.tooth === 'd48' && values.position === 'superior') return tooth48Top(values);
  if (values.tooth === 'd48' && values.position === 'direito') return tooth48Right(values);
  if (values.tooth === 'd48' && values.position === 'baixo') return tooth48Bottom(values);
  if (values.tooth === 'd48' && values.position === 'esquerdo') return tooth48Left(values);
  if (values.tooth === 'd48' && values.position === 'centro') return tooth48Middle(values);
  if (values.tooth === 'd51' && values.position === 'superior') return tooth51Top(values);
  if (values.tooth === 'd51' && values.position === 'direito') return tooth51Right(values);
  if (values.tooth === 'd51' && values.position === 'baixo') return tooth51Bottom(values);
  if (values.tooth === 'd51' && values.position === 'esquerdo') return tooth51Left(values);
  if (values.tooth === 'd51' && values.position === 'centro') return tooth51Middle(values);
  if (values.tooth === 'd52' && values.position === 'superior') return tooth52Top(values);
  if (values.tooth === 'd52' && values.position === 'direito') return tooth52Right(values);
  if (values.tooth === 'd52' && values.position === 'baixo') return tooth52Bottom(values);
  if (values.tooth === 'd52' && values.position === 'esquerdo') return tooth52Left(values);
  if (values.tooth === 'd52' && values.position === 'centro') return tooth52Middle(values);
  if (values.tooth === 'd53' && values.position === 'superior') return tooth53Top(values);
  if (values.tooth === 'd53' && values.position === 'direito') return tooth53Right(values);
  if (values.tooth === 'd53' && values.position === 'baixo') return tooth53Bottom(values);
  if (values.tooth === 'd53' && values.position === 'esquerdo') return tooth53Left(values);
  if (values.tooth === 'd53' && values.position === 'centro') return tooth53Middle(values);
  if (values.tooth === 'd54' && values.position === 'superior') return tooth54Top(values);
  if (values.tooth === 'd54' && values.position === 'direito') return tooth54Right(values);
  if (values.tooth === 'd54' && values.position === 'baixo') return tooth54Bottom(values);
  if (values.tooth === 'd54' && values.position === 'esquerdo') return tooth54Left(values);
  if (values.tooth === 'd54' && values.position === 'centro') return tooth54Middle(values);
  if (values.tooth === 'd55' && values.position === 'superior') return tooth55Top(values);
  if (values.tooth === 'd55' && values.position === 'direito') return tooth55Right(values);
  if (values.tooth === 'd55' && values.position === 'baixo') return tooth55Bottom(values);
  if (values.tooth === 'd55' && values.position === 'esquerdo') return tooth55Left(values);
  if (values.tooth === 'd55' && values.position === 'centro') return tooth55Middle(values);
  if (values.tooth === 'd61' && values.position === 'superior') return tooth61Top(values);
  if (values.tooth === 'd61' && values.position === 'direito') return tooth61Right(values);
  if (values.tooth === 'd61' && values.position === 'baixo') return tooth61Bottom(values);
  if (values.tooth === 'd61' && values.position === 'esquerdo') return tooth61Left(values);
  if (values.tooth === 'd61' && values.position === 'centro') return tooth61Middle(values);
  if (values.tooth === 'd62' && values.position === 'superior') return tooth62Top(values);
  if (values.tooth === 'd62' && values.position === 'direito') return tooth62Right(values);
  if (values.tooth === 'd62' && values.position === 'baixo') return tooth62Bottom(values);
  if (values.tooth === 'd62' && values.position === 'esquerdo') return tooth62Left(values);
  if (values.tooth === 'd62' && values.position === 'centro') return tooth62Middle(values);
  if (values.tooth === 'd63' && values.position === 'superior') return tooth63Top(values);
  if (values.tooth === 'd63' && values.position === 'direito') return tooth63Right(values);
  if (values.tooth === 'd63' && values.position === 'baixo') return tooth63Bottom(values);
  if (values.tooth === 'd63' && values.position === 'esquerdo') return tooth63Left(values);
  if (values.tooth === 'd63' && values.position === 'centro') return tooth63Middle(values);
  if (values.tooth === 'd64' && values.position === 'superior') return tooth64Top(values);
  if (values.tooth === 'd64' && values.position === 'direito') return tooth64Right(values);
  if (values.tooth === 'd64' && values.position === 'baixo') return tooth64Bottom(values);
  if (values.tooth === 'd64' && values.position === 'esquerdo') return tooth64Left(values);
  if (values.tooth === 'd64' && values.position === 'centro') return tooth64Middle(values);
  if (values.tooth === 'd65' && values.position === 'superior') return tooth65Top(values);
  if (values.tooth === 'd65' && values.position === 'direito') return tooth65Right(values);
  if (values.tooth === 'd65' && values.position === 'baixo') return tooth65Bottom(values);
  if (values.tooth === 'd65' && values.position === 'esquerdo') return tooth65Left(values);
  if (values.tooth === 'd65' && values.position === 'centro') return tooth65Middle(values);
  if (values.tooth === 'd71' && values.position === 'superior') return tooth71Top(values);
  if (values.tooth === 'd71' && values.position === 'direito') return tooth71Right(values);
  if (values.tooth === 'd71' && values.position === 'baixo') return tooth71Bottom(values);
  if (values.tooth === 'd71' && values.position === 'esquerdo') return tooth71Left(values);
  if (values.tooth === 'd71' && values.position === 'centro') return tooth71Middle(values);
  if (values.tooth === 'd72' && values.position === 'superior') return tooth72Top(values);
  if (values.tooth === 'd72' && values.position === 'direito') return tooth72Right(values);
  if (values.tooth === 'd72' && values.position === 'baixo') return tooth72Bottom(values);
  if (values.tooth === 'd72' && values.position === 'esquerdo') return tooth72Left(values);
  if (values.tooth === 'd72' && values.position === 'centro') return tooth72Middle(values);
  if (values.tooth === 'd73' && values.position === 'superior') return tooth73Top(values);
  if (values.tooth === 'd73' && values.position === 'direito') return tooth73Right(values);
  if (values.tooth === 'd73' && values.position === 'baixo') return tooth73Bottom(values);
  if (values.tooth === 'd73' && values.position === 'esquerdo') return tooth73Left(values);
  if (values.tooth === 'd73' && values.position === 'centro') return tooth73Middle(values);
  if (values.tooth === 'd74' && values.position === 'superior') return tooth74Top(values);
  if (values.tooth === 'd74' && values.position === 'direito') return tooth74Right(values);
  if (values.tooth === 'd74' && values.position === 'baixo') return tooth74Bottom(values);
  if (values.tooth === 'd74' && values.position === 'esquerdo') return tooth74Left(values);
  if (values.tooth === 'd74' && values.position === 'centro') return tooth74Middle(values);
  if (values.tooth === 'd75' && values.position === 'superior') return tooth75Top(values);
  if (values.tooth === 'd75' && values.position === 'direito') return tooth75Right(values);
  if (values.tooth === 'd75' && values.position === 'baixo') return tooth75Bottom(values);
  if (values.tooth === 'd75' && values.position === 'esquerdo') return tooth75Left(values);
  if (values.tooth === 'd75' && values.position === 'centro') return tooth75Middle(values);
  if (values.tooth === 'd81' && values.position === 'superior') return tooth81Top(values);
  if (values.tooth === 'd81' && values.position === 'direito') return tooth81Right(values);
  if (values.tooth === 'd81' && values.position === 'baixo') return tooth81Bottom(values);
  if (values.tooth === 'd81' && values.position === 'esquerdo') return tooth81Left(values);
  if (values.tooth === 'd81' && values.position === 'centro') return tooth81Middle(values);
  if (values.tooth === 'd82' && values.position === 'superior') return tooth82Top(values);
  if (values.tooth === 'd82' && values.position === 'direito') return tooth82Right(values);
  if (values.tooth === 'd82' && values.position === 'baixo') return tooth82Bottom(values);
  if (values.tooth === 'd82' && values.position === 'esquerdo') return tooth82Left(values);
  if (values.tooth === 'd82' && values.position === 'centro') return tooth82Middle(values);
  if (values.tooth === 'd83' && values.position === 'superior') return tooth83Top(values);
  if (values.tooth === 'd83' && values.position === 'direito') return tooth83Right(values);
  if (values.tooth === 'd83' && values.position === 'baixo') return tooth83Bottom(values);
  if (values.tooth === 'd83' && values.position === 'esquerdo') return tooth83Left(values);
  if (values.tooth === 'd83' && values.position === 'centro') return tooth83Middle(values);
  if (values.tooth === 'd84' && values.position === 'superior') return tooth84Top(values);
  if (values.tooth === 'd84' && values.position === 'direito') return tooth84Right(values);
  if (values.tooth === 'd84' && values.position === 'baixo') return tooth84Bottom(values);
  if (values.tooth === 'd84' && values.position === 'esquerdo') return tooth84Left(values);
  if (values.tooth === 'd84' && values.position === 'centro') return tooth84Middle(values);
  if (values.tooth === 'd85' && values.position === 'superior') return tooth85Top(values);
  if (values.tooth === 'd85' && values.position === 'direito') return tooth85Right(values);
  if (values.tooth === 'd85' && values.position === 'baixo') return tooth85Bottom(values);
  if (values.tooth === 'd85' && values.position === 'esquerdo') return tooth85Left(values);
  if (values.tooth === 'd85' && values.position === 'centro') return tooth85Middle(values);
}

export function tooth11Top(values) {
  return {
    type: 'TOOTH_11_TOP',
    payload: values
  }
}

export function tooth11Right(values) {
  return {
    type: 'TOOTH_11_RIGHT',
    payload: values
  }
}

export function tooth11Bottom(values) {
  return {
    type: 'TOOTH_11_BOTTOM',
    payload: values
  }
}

export function tooth11Left(values) {
  return {
    type: 'TOOTH_11_LEFT',
    payload: values
  }
}

export function tooth11Middle(values) {
  return {
    type: 'TOOTH_11_MIDDLE',
    payload: values
  }
}

export function tooth12Top(values) {
  return {
    type: 'TOOTH_12_TOP',
    payload: values
  }
}

export function tooth12Right(values) {
  return {
    type: 'TOOTH_12_RIGHT',
    payload: values
  }
}

export function tooth12Bottom(values) {
  return {
    type: 'TOOTH_12_BOTTOM',
    payload: values
  }
}

export function tooth12Left(values) {
  return {
    type: 'TOOTH_12_LEFT',
    payload: values
  }
}

export function tooth12Middle(values) {
  return {
    type: 'TOOTH_12_MIDDLE',
    payload: values
  }
}

export function tooth13Top(values) {
  return {
    type: 'TOOTH_13_TOP',
    payload: values
  }
}

export function tooth13Right(values) {
  return {
    type: 'TOOTH_13_RIGHT',
    payload: values
  }
}

export function tooth13Bottom(values) {
  return {
    type: 'TOOTH_13_BOTTOM',
    payload: values
  }
}

export function tooth13Left(values) {
  return {
    type: 'TOOTH_13_LEFT',
    payload: values
  }
}

export function tooth13Middle(values) {
  return {
    type: 'TOOTH_13_MIDDLE',
    payload: values
  }
}

export function tooth14Top(values) {
  return {
    type: 'TOOTH_14_TOP',
    payload: values
  }
}

export function tooth14Right(values) {
  return {
    type: 'TOOTH_14_RIGHT',
    payload: values
  }
}

export function tooth14Bottom(values) {
  return {
    type: 'TOOTH_14_BOTTOM',
    payload: values
  }
}

export function tooth14Left(values) {
  return {
    type: 'TOOTH_14_LEFT',
    payload: values
  }
}

export function tooth14Middle(values) {
  return {
    type: 'TOOTH_14_MIDDLE',
    payload: values
  }
}

export function tooth15Top(values) {
  return {
    type: 'TOOTH_15_TOP',
    payload: values
  }
}

export function tooth15Right(values) {
  return {
    type: 'TOOTH_15_RIGHT',
    payload: values
  }
}

export function tooth15Bottom(values) {
  return {
    type: 'TOOTH_15_BOTTOM',
    payload: values
  }
}

export function tooth15Left(values) {
  return {
    type: 'TOOTH_15_LEFT',
    payload: values
  }
}

export function tooth15Middle(values) {
  return {
    type: 'TOOTH_15_MIDDLE',
    payload: values
  }
}

export function tooth16Top(values) {
  return {
    type: 'TOOTH_16_TOP',
    payload: values
  }
}

export function tooth16Right(values) {
  return {
    type: 'TOOTH_16_RIGHT',
    payload: values
  }
}

export function tooth16Bottom(values) {
  return {
    type: 'TOOTH_16_BOTTOM',
    payload: values
  }
}

export function tooth16Left(values) {
  return {
    type: 'TOOTH_16_LEFT',
    payload: values
  }
}

export function tooth16Middle(values) {
  return {
    type: 'TOOTH_16_MIDDLE',
    payload: values
  }
}

export function tooth17Top(values) {
  return {
    type: 'TOOTH_17_TOP',
    payload: values
  }
}

export function tooth17Right(values) {
  return {
    type: 'TOOTH_17_RIGHT',
    payload: values
  }
}

export function tooth17Bottom(values) {
  return {
    type: 'TOOTH_17_BOTTOM',
    payload: values
  }
}

export function tooth17Left(values) {
  return {
    type: 'TOOTH_17_LEFT',
    payload: values
  }
}

export function tooth17Middle(values) {
  return {
    type: 'TOOTH_17_MIDDLE',
    payload: values
  }
}

export function tooth18Top(values) {
  return {
    type: 'TOOTH_18_TOP',
    payload: values
  }
}

export function tooth18Right(values) {
  return {
    type: 'TOOTH_18_RIGHT',
    payload: values
  }
}

export function tooth18Bottom(values) {
  return {
    type: 'TOOTH_18_BOTTOM',
    payload: values
  }
}

export function tooth18Left(values) {
  return {
    type: 'TOOTH_18_LEFT',
    payload: values
  }
}

export function tooth18Middle(values) {
  return {
    type: 'TOOTH_18_MIDDLE',
    payload: values
  }
}

export function tooth21Top(values) {
  return {
    type: 'TOOTH_21_TOP',
    payload: values
  }
}

export function tooth21Right(values) {
  return {
    type: 'TOOTH_21_RIGHT',
    payload: values
  }
}

export function tooth21Bottom(values) {
  return {
    type: 'TOOTH_21_BOTTOM',
    payload: values
  }
}

export function tooth21Left(values) {
  return {
    type: 'TOOTH_21_LEFT',
    payload: values
  }
}

export function tooth21Middle(values) {
  return {
    type: 'TOOTH_21_MIDDLE',
    payload: values
  }
}

export function tooth22Top(values) {
  return {
    type: 'TOOTH_22_TOP',
    payload: values
  }
}

export function tooth22Right(values) {
  return {
    type: 'TOOTH_22_RIGHT',
    payload: values
  }
}

export function tooth22Bottom(values) {
  return {
    type: 'TOOTH_22_BOTTOM',
    payload: values
  }
}

export function tooth22Left(values) {
  return {
    type: 'TOOTH_22_LEFT',
    payload: values
  }
}

export function tooth22Middle(values) {
  return {
    type: 'TOOTH_22_MIDDLE',
    payload: values
  }
}

export function tooth23Top(values) {
  return {
    type: 'TOOTH_23_TOP',
    payload: values
  }
}

export function tooth23Right(values) {
  return {
    type: 'TOOTH_23_RIGHT',
    payload: values
  }
}

export function tooth23Bottom(values) {
  return {
    type: 'TOOTH_23_BOTTOM',
    payload: values
  }
}

export function tooth23Left(values) {
  return {
    type: 'TOOTH_23_LEFT',
    payload: values
  }
}

export function tooth23Middle(values) {
  return {
    type: 'TOOTH_23_MIDDLE',
    payload: values
  }
}

export function tooth24Top(values) {
  return {
    type: 'TOOTH_24_TOP',
    payload: values
  }
}

export function tooth24Right(values) {
  return {
    type: 'TOOTH_24_RIGHT',
    payload: values
  }
}

export function tooth24Bottom(values) {
  return {
    type: 'TOOTH_24_BOTTOM',
    payload: values
  }
}

export function tooth24Left(values) {
  return {
    type: 'TOOTH_24_LEFT',
    payload: values
  }
}

export function tooth24Middle(values) {
  return {
    type: 'TOOTH_24_MIDDLE',
    payload: values
  }
}

export function tooth25Top(values) {
  return {
    type: 'TOOTH_25_TOP',
    payload: values
  }
}

export function tooth25Right(values) {
  return {
    type: 'TOOTH_25_RIGHT',
    payload: values
  }
}

export function tooth25Bottom(values) {
  return {
    type: 'TOOTH_25_BOTTOM',
    payload: values
  }
}

export function tooth25Left(values) {
  return {
    type: 'TOOTH_25_LEFT',
    payload: values
  }
}

export function tooth25Middle(values) {
  return {
    type: 'TOOTH_25_MIDDLE',
    payload: values
  }
}

export function tooth26Top(values) {
  return {
    type: 'TOOTH_26_TOP',
    payload: values
  }
}

export function tooth26Right(values) {
  return {
    type: 'TOOTH_26_RIGHT',
    payload: values
  }
}

export function tooth26Bottom(values) {
  return {
    type: 'TOOTH_26_BOTTOM',
    payload: values
  }
}

export function tooth26Left(values) {
  return {
    type: 'TOOTH_26_LEFT',
    payload: values
  }
}

export function tooth26Middle(values) {
  return {
    type: 'TOOTH_26_MIDDLE',
    payload: values
  }
}

export function tooth27Top(values) {
  return {
    type: 'TOOTH_27_TOP',
    payload: values
  }
}

export function tooth27Right(values) {
  return {
    type: 'TOOTH_27_RIGHT',
    payload: values
  }
}

export function tooth27Bottom(values) {
  return {
    type: 'TOOTH_27_BOTTOM',
    payload: values
  }
}

export function tooth27Left(values) {
  return {
    type: 'TOOTH_27_LEFT',
    payload: values
  }
}

export function tooth27Middle(values) {
  return {
    type: 'TOOTH_27_MIDDLE',
    payload: values
  }
}

export function tooth28Top(values) {
  return {
    type: 'TOOTH_28_TOP',
    payload: values
  }
}

export function tooth28Right(values) {
  return {
    type: 'TOOTH_28_RIGHT',
    payload: values
  }
}

export function tooth28Bottom(values) {
  return {
    type: 'TOOTH_28_BOTTOM',
    payload: values
  }
}

export function tooth28Left(values) {
  return {
    type: 'TOOTH_28_LEFT',
    payload: values
  }
}

export function tooth28Middle(values) {
  return {
    type: 'TOOTH_28_MIDDLE',
    payload: values
  }
}

export function tooth31Top(values) {
  return {
    type: 'TOOTH_31_TOP',
    payload: values
  }
}

export function tooth31Right(values) {
  return {
    type: 'TOOTH_31_RIGHT',
    payload: values
  }
}

export function tooth31Bottom(values) {
  return {
    type: 'TOOTH_31_BOTTOM',
    payload: values
  }
}

export function tooth31Left(values) {
  return {
    type: 'TOOTH_31_LEFT',
    payload: values
  }
}

export function tooth31Middle(values) {
  return {
    type: 'TOOTH_31_MIDDLE',
    payload: values
  }
}

export function tooth32Top(values) {
  return {
    type: 'TOOTH_32_TOP',
    payload: values
  }
}

export function tooth32Right(values) {
  return {
    type: 'TOOTH_32_RIGHT',
    payload: values
  }
}

export function tooth32Bottom(values) {
  return {
    type: 'TOOTH_32_BOTTOM',
    payload: values
  }
}

export function tooth32Left(values) {
  return {
    type: 'TOOTH_32_LEFT',
    payload: values
  }
}

export function tooth32Middle(values) {
  return {
    type: 'TOOTH_32_MIDDLE',
    payload: values
  }
}

export function tooth33Top(values) {
  return {
    type: 'TOOTH_33_TOP',
    payload: values
  }
}

export function tooth33Right(values) {
  return {
    type: 'TOOTH_33_RIGHT',
    payload: values
  }
}

export function tooth33Bottom(values) {
  return {
    type: 'TOOTH_33_BOTTOM',
    payload: values
  }
}

export function tooth33Left(values) {
  return {
    type: 'TOOTH_33_LEFT',
    payload: values
  }
}

export function tooth33Middle(values) {
  return {
    type: 'TOOTH_33_MIDDLE',
    payload: values
  }
}

export function tooth34Top(values) {
  return {
    type: 'TOOTH_34_TOP',
    payload: values
  }
}

export function tooth34Right(values) {
  return {
    type: 'TOOTH_34_RIGHT',
    payload: values
  }
}

export function tooth34Bottom(values) {
  return {
    type: 'TOOTH_34_BOTTOM',
    payload: values
  }
}

export function tooth34Left(values) {
  return {
    type: 'TOOTH_34_LEFT',
    payload: values
  }
}

export function tooth34Middle(values) {
  return {
    type: 'TOOTH_34_MIDDLE',
    payload: values
  }
}

export function tooth35Top(values) {
  return {
    type: 'TOOTH_35_TOP',
    payload: values
  }
}

export function tooth35Right(values) {
  return {
    type: 'TOOTH_35_RIGHT',
    payload: values
  }
}

export function tooth35Bottom(values) {
  return {
    type: 'TOOTH_35_BOTTOM',
    payload: values
  }
}

export function tooth35Left(values) {
  return {
    type: 'TOOTH_35_LEFT',
    payload: values
  }
}

export function tooth35Middle(values) {
  return {
    type: 'TOOTH_35_MIDDLE',
    payload: values
  }
}

export function tooth36Top(values) {
  return {
    type: 'TOOTH_36_TOP',
    payload: values
  }
}

export function tooth36Right(values) {
  return {
    type: 'TOOTH_36_RIGHT',
    payload: values
  }
}

export function tooth36Bottom(values) {
  return {
    type: 'TOOTH_36_BOTTOM',
    payload: values
  }
}

export function tooth36Left(values) {
  return {
    type: 'TOOTH_36_LEFT',
    payload: values
  }
}

export function tooth36Middle(values) {
  return {
    type: 'TOOTH_36_MIDDLE',
    payload: values
  }
}

export function tooth37Top(values) {
  return {
    type: 'TOOTH_37_TOP',
    payload: values
  }
}

export function tooth37Right(values) {
  return {
    type: 'TOOTH_37_RIGHT',
    payload: values
  }
}

export function tooth37Bottom(values) {
  return {
    type: 'TOOTH_37_BOTTOM',
    payload: values
  }
}

export function tooth37Left(values) {
  return {
    type: 'TOOTH_37_LEFT',
    payload: values
  }
}

export function tooth37Middle(values) {
  return {
    type: 'TOOTH_37_MIDDLE',
    payload: values
  }
}

export function tooth38Top(values) {
  return {
    type: 'TOOTH_38_TOP',
    payload: values
  }
}

export function tooth38Right(values) {
  return {
    type: 'TOOTH_38_RIGHT',
    payload: values
  }
}

export function tooth38Bottom(values) {
  return {
    type: 'TOOTH_38_BOTTOM',
    payload: values
  }
}

export function tooth38Left(values) {
  return {
    type: 'TOOTH_38_LEFT',
    payload: values
  }
}

export function tooth38Middle(values) {
  return {
    type: 'TOOTH_38_MIDDLE',
    payload: values
  }
}

export function tooth41Top(values) {
  return {
    type: 'TOOTH_41_TOP',
    payload: values
  }
}

export function tooth41Right(values) {
  return {
    type: 'TOOTH_41_RIGHT',
    payload: values
  }
}

export function tooth41Bottom(values) {
  return {
    type: 'TOOTH_41_BOTTOM',
    payload: values
  }
}

export function tooth41Left(values) {
  return {
    type: 'TOOTH_41_LEFT',
    payload: values
  }
}

export function tooth41Middle(values) {
  return {
    type: 'TOOTH_41_MIDDLE',
    payload: values
  }
}

export function tooth42Top(values) {
  return {
    type: 'TOOTH_42_TOP',
    payload: values
  }
}

export function tooth42Right(values) {
  return {
    type: 'TOOTH_42_RIGHT',
    payload: values
  }
}

export function tooth42Bottom(values) {
  return {
    type: 'TOOTH_42_BOTTOM',
    payload: values
  }
}

export function tooth42Left(values) {
  return {
    type: 'TOOTH_42_LEFT',
    payload: values
  }
}

export function tooth42Middle(values) {
  return {
    type: 'TOOTH_42_MIDDLE',
    payload: values
  }
}

export function tooth43Top(values) {
  return {
    type: 'TOOTH_43_TOP',
    payload: values
  }
}

export function tooth43Right(values) {
  return {
    type: 'TOOTH_43_RIGHT',
    payload: values
  }
}

export function tooth43Bottom(values) {
  return {
    type: 'TOOTH_43_BOTTOM',
    payload: values
  }
}

export function tooth43Left(values) {
  return {
    type: 'TOOTH_43_LEFT',
    payload: values
  }
}

export function tooth43Middle(values) {
  return {
    type: 'TOOTH_43_MIDDLE',
    payload: values
  }
}

export function tooth44Top(values) {
  return {
    type: 'TOOTH_44_TOP',
    payload: values
  }
}

export function tooth44Right(values) {
  return {
    type: 'TOOTH_44_RIGHT',
    payload: values
  }
}

export function tooth44Bottom(values) {
  return {
    type: 'TOOTH_44_BOTTOM',
    payload: values
  }
}

export function tooth44Left(values) {
  return {
    type: 'TOOTH_44_LEFT',
    payload: values
  }
}

export function tooth44Middle(values) {
  return {
    type: 'TOOTH_44_MIDDLE',
    payload: values
  }
}

export function tooth45Top(values) {
  return {
    type: 'TOOTH_45_TOP',
    payload: values
  }
}

export function tooth45Right(values) {
  return {
    type: 'TOOTH_45_RIGHT',
    payload: values
  }
}

export function tooth45Bottom(values) {
  return {
    type: 'TOOTH_45_BOTTOM',
    payload: values
  }
}

export function tooth45Left(values) {
  return {
    type: 'TOOTH_45_LEFT',
    payload: values
  }
}

export function tooth45Middle(values) {
  return {
    type: 'TOOTH_45_MIDDLE',
    payload: values
  }
}

export function tooth46Top(values) {
  return {
    type: 'TOOTH_46_TOP',
    payload: values
  }
}

export function tooth46Right(values) {
  return {
    type: 'TOOTH_46_RIGHT',
    payload: values
  }
}

export function tooth46Bottom(values) {
  return {
    type: 'TOOTH_46_BOTTOM',
    payload: values
  }
}

export function tooth46Left(values) {
  return {
    type: 'TOOTH_46_LEFT',
    payload: values
  }
}

export function tooth46Middle(values) {
  return {
    type: 'TOOTH_46_MIDDLE',
    payload: values
  }
}

export function tooth47Top(values) {
  return {
    type: 'TOOTH_47_TOP',
    payload: values
  }
}

export function tooth47Right(values) {
  return {
    type: 'TOOTH_47_RIGHT',
    payload: values
  }
}

export function tooth47Bottom(values) {
  return {
    type: 'TOOTH_47_BOTTOM',
    payload: values
  }
}

export function tooth47Left(values) {
  return {
    type: 'TOOTH_47_LEFT',
    payload: values
  }
}

export function tooth47Middle(values) {
  return {
    type: 'TOOTH_47_MIDDLE',
    payload: values
  }
}

export function tooth48Top(values) {
  return {
    type: 'TOOTH_48_TOP',
    payload: values
  }
}

export function tooth48Right(values) {
  return {
    type: 'TOOTH_48_RIGHT',
    payload: values
  }
}

export function tooth48Bottom(values) {
  return {
    type: 'TOOTH_48_BOTTOM',
    payload: values
  }
}

export function tooth48Left(values) {
  return {
    type: 'TOOTH_48_LEFT',
    payload: values
  }
}

export function tooth48Middle(values) {
  return {
    type: 'TOOTH_48_MIDDLE',
    payload: values
  }
}

export function tooth51Top(values) {
  return {
    type: 'TOOTH_51_TOP',
    payload: values
  }
}

export function tooth51Right(values) {
  return {
    type: 'TOOTH_51_RIGHT',
    payload: values
  }
}

export function tooth51Bottom(values) {
  return {
    type: 'TOOTH_51_BOTTOM',
    payload: values
  }
}

export function tooth51Left(values) {
  return {
    type: 'TOOTH_51_LEFT',
    payload: values
  }
}

export function tooth51Middle(values) {
  return {
    type: 'TOOTH_51_MIDDLE',
    payload: values
  }
}

export function tooth52Top(values) {
  return {
    type: 'TOOTH_52_TOP',
    payload: values
  }
}

export function tooth52Right(values) {
  return {
    type: 'TOOTH_52_RIGHT',
    payload: values
  }
}

export function tooth52Bottom(values) {
  return {
    type: 'TOOTH_52_BOTTOM',
    payload: values
  }
}

export function tooth52Left(values) {
  return {
    type: 'TOOTH_52_LEFT',
    payload: values
  }
}

export function tooth52Middle(values) {
  return {
    type: 'TOOTH_52_MIDDLE',
    payload: values
  }
}

export function tooth53Top(values) {
  return {
    type: 'TOOTH_53_TOP',
    payload: values
  }
}

export function tooth53Right(values) {
  return {
    type: 'TOOTH_53_RIGHT',
    payload: values
  }
}

export function tooth53Bottom(values) {
  return {
    type: 'TOOTH_53_BOTTOM',
    payload: values
  }
}

export function tooth53Left(values) {
  return {
    type: 'TOOTH_53_LEFT',
    payload: values
  }
}

export function tooth53Middle(values) {
  return {
    type: 'TOOTH_53_MIDDLE',
    payload: values
  }
}

export function tooth54Top(values) {
  return {
    type: 'TOOTH_54_TOP',
    payload: values
  }
}

export function tooth54Right(values) {
  return {
    type: 'TOOTH_54_RIGHT',
    payload: values
  }
}

export function tooth54Bottom(values) {
  return {
    type: 'TOOTH_54_BOTTOM',
    payload: values
  }
}

export function tooth54Left(values) {
  return {
    type: 'TOOTH_54_LEFT',
    payload: values
  }
}

export function tooth54Middle(values) {
  return {
    type: 'TOOTH_54_MIDDLE',
    payload: values
  }
}

export function tooth55Top(values) {
  return {
    type: 'TOOTH_55_TOP',
    payload: values
  }
}

export function tooth55Right(values) {
  return {
    type: 'TOOTH_55_RIGHT',
    payload: values
  }
}

export function tooth55Bottom(values) {
  return {
    type: 'TOOTH_55_BOTTOM',
    payload: values
  }
}

export function tooth55Left(values) {
  return {
    type: 'TOOTH_55_LEFT',
    payload: values
  }
}

export function tooth55Middle(values) {
  return {
    type: 'TOOTH_55_MIDDLE',
    payload: values
  }
}

export function tooth61Top(values) {
  return {
    type: 'TOOTH_61_TOP',
    payload: values
  }
}

export function tooth61Right(values) {
  return {
    type: 'TOOTH_61_RIGHT',
    payload: values
  }
}

export function tooth61Bottom(values) {
  return {
    type: 'TOOTH_61_BOTTOM',
    payload: values
  }
}

export function tooth61Left(values) {
  return {
    type: 'TOOTH_61_LEFT',
    payload: values
  }
}

export function tooth61Middle(values) {
  return {
    type: 'TOOTH_61_MIDDLE',
    payload: values
  }
}

export function tooth62Top(values) {
  return {
    type: 'TOOTH_62_TOP',
    payload: values
  }
}

export function tooth62Right(values) {
  return {
    type: 'TOOTH_62_RIGHT',
    payload: values
  }
}

export function tooth62Bottom(values) {
  return {
    type: 'TOOTH_62_BOTTOM',
    payload: values
  }
}

export function tooth62Left(values) {
  return {
    type: 'TOOTH_62_LEFT',
    payload: values
  }
}

export function tooth62Middle(values) {
  return {
    type: 'TOOTH_62_MIDDLE',
    payload: values
  }
}

export function tooth63Top(values) {
  return {
    type: 'TOOTH_63_TOP',
    payload: values
  }
}

export function tooth63Right(values) {
  return {
    type: 'TOOTH_63_RIGHT',
    payload: values
  }
}

export function tooth63Bottom(values) {
  return {
    type: 'TOOTH_63_BOTTOM',
    payload: values
  }
}

export function tooth63Left(values) {
  return {
    type: 'TOOTH_63_LEFT',
    payload: values
  }
}

export function tooth63Middle(values) {
  return {
    type: 'TOOTH_63_MIDDLE',
    payload: values
  }
}

export function tooth64Top(values) {
  return {
    type: 'TOOTH_64_TOP',
    payload: values
  }
}

export function tooth64Right(values) {
  return {
    type: 'TOOTH_64_RIGHT',
    payload: values
  }
}

export function tooth64Bottom(values) {
  return {
    type: 'TOOTH_64_BOTTOM',
    payload: values
  }
}

export function tooth64Left(values) {
  return {
    type: 'TOOTH_64_LEFT',
    payload: values
  }
}

export function tooth64Middle(values) {
  return {
    type: 'TOOTH_64_MIDDLE',
    payload: values
  }
}

export function tooth65Top(values) {
  return {
    type: 'TOOTH_65_TOP',
    payload: values
  }
}

export function tooth65Right(values) {
  return {
    type: 'TOOTH_65_RIGHT',
    payload: values
  }
}

export function tooth65Bottom(values) {
  return {
    type: 'TOOTH_65_BOTTOM',
    payload: values
  }
}

export function tooth65Left(values) {
  return {
    type: 'TOOTH_65_LEFT',
    payload: values
  }
}

export function tooth65Middle(values) {
  return {
    type: 'TOOTH_65_MIDDLE',
    payload: values
  }
}

export function tooth71Top(values) {
  return {
    type: 'TOOTH_71_TOP',
    payload: values
  }
}

export function tooth71Right(values) {
  return {
    type: 'TOOTH_71_RIGHT',
    payload: values
  }
}

export function tooth71Bottom(values) {
  return {
    type: 'TOOTH_71_BOTTOM',
    payload: values
  }
}

export function tooth71Left(values) {
  return {
    type: 'TOOTH_71_LEFT',
    payload: values
  }
}

export function tooth71Middle(values) {
  return {
    type: 'TOOTH_71_MIDDLE',
    payload: values
  }
}

export function tooth72Top(values) {
  return {
    type: 'TOOTH_72_TOP',
    payload: values
  }
}

export function tooth72Right(values) {
  return {
    type: 'TOOTH_72_RIGHT',
    payload: values
  }
}

export function tooth72Bottom(values) {
  return {
    type: 'TOOTH_72_BOTTOM',
    payload: values
  }
}

export function tooth72Left(values) {
  return {
    type: 'TOOTH_72_LEFT',
    payload: values
  }
}

export function tooth72Middle(values) {
  return {
    type: 'TOOTH_72_MIDDLE',
    payload: values
  }
}

export function tooth73Top(values) {
  return {
    type: 'TOOTH_73_TOP',
    payload: values
  }
}

export function tooth73Right(values) {
  return {
    type: 'TOOTH_73_RIGHT',
    payload: values
  }
}

export function tooth73Bottom(values) {
  return {
    type: 'TOOTH_73_BOTTOM',
    payload: values
  }
}

export function tooth73Left(values) {
  return {
    type: 'TOOTH_73_LEFT',
    payload: values
  }
}

export function tooth73Middle(values) {
  return {
    type: 'TOOTH_73_MIDDLE',
    payload: values
  }
}

export function tooth74Top(values) {
  return {
    type: 'TOOTH_74_TOP',
    payload: values
  }
}

export function tooth74Right(values) {
  return {
    type: 'TOOTH_74_RIGHT',
    payload: values
  }
}

export function tooth74Bottom(values) {
  return {
    type: 'TOOTH_74_BOTTOM',
    payload: values
  }
}

export function tooth74Left(values) {
  return {
    type: 'TOOTH_74_LEFT',
    payload: values
  }
}

export function tooth74Middle(values) {
  return {
    type: 'TOOTH_74_MIDDLE',
    payload: values
  }
}

export function tooth75Top(values) {
  return {
    type: 'TOOTH_75_TOP',
    payload: values
  }
}

export function tooth75Right(values) {
  return {
    type: 'TOOTH_75_RIGHT',
    payload: values
  }
}

export function tooth75Bottom(values) {
  return {
    type: 'TOOTH_75_BOTTOM',
    payload: values
  }
}

export function tooth75Left(values) {
  return {
    type: 'TOOTH_75_LEFT',
    payload: values
  }
}

export function tooth75Middle(values) {
  return {
    type: 'TOOTH_75_MIDDLE',
    payload: values
  }
}

export function tooth81Top(values) {
  return {
    type: 'TOOTH_81_TOP',
    payload: values
  }
}

export function tooth81Right(values) {
  return {
    type: 'TOOTH_81_RIGHT',
    payload: values
  }
}

export function tooth81Bottom(values) {
  return {
    type: 'TOOTH_81_BOTTOM',
    payload: values
  }
}

export function tooth81Left(values) {
  return {
    type: 'TOOTH_81_LEFT',
    payload: values
  }
}

export function tooth81Middle(values) {
  return {
    type: 'TOOTH_81_MIDDLE',
    payload: values
  }
}

export function tooth82Top(values) {
  return {
    type: 'TOOTH_82_TOP',
    payload: values
  }
}

export function tooth82Right(values) {
  return {
    type: 'TOOTH_82_RIGHT',
    payload: values
  }
}

export function tooth82Bottom(values) {
  return {
    type: 'TOOTH_82_BOTTOM',
    payload: values
  }
}

export function tooth82Left(values) {
  return {
    type: 'TOOTH_82_LEFT',
    payload: values
  }
}

export function tooth82Middle(values) {
  return {
    type: 'TOOTH_82_MIDDLE',
    payload: values
  }
}

export function tooth83Top(values) {
  return {
    type: 'TOOTH_83_TOP',
    payload: values
  }
}

export function tooth83Right(values) {
  return {
    type: 'TOOTH_83_RIGHT',
    payload: values
  }
}

export function tooth83Bottom(values) {
  return {
    type: 'TOOTH_83_BOTTOM',
    payload: values
  }
}

export function tooth83Left(values) {
  return {
    type: 'TOOTH_83_LEFT',
    payload: values
  }
}

export function tooth83Middle(values) {
  return {
    type: 'TOOTH_83_MIDDLE',
    payload: values
  }
}

export function tooth84Top(values) {
  return {
    type: 'TOOTH_84_TOP',
    payload: values
  }
}

export function tooth84Right(values) {
  return {
    type: 'TOOTH_84_RIGHT',
    payload: values
  }
}

export function tooth84Bottom(values) {
  return {
    type: 'TOOTH_84_BOTTOM',
    payload: values
  }
}

export function tooth84Left(values) {
  return {
    type: 'TOOTH_84_LEFT',
    payload: values
  }
}

export function tooth84Middle(values) {
  return {
    type: 'TOOTH_84_MIDDLE',
    payload: values
  }
}

export function tooth85Top(values) {
  return {
    type: 'TOOTH_85_TOP',
    payload: values
  }
}

export function tooth85Right(values) {
  return {
    type: 'TOOTH_85_RIGHT',
    payload: values
  }
}

export function tooth85Bottom(values) {
  return {
    type: 'TOOTH_85_BOTTOM',
    payload: values
  }
}

export function tooth85Left(values) {
  return {
    type: 'TOOTH_85_LEFT',
    payload: values
  }
}

export function tooth85Middle(values) {
  return {
    type: 'TOOTH_85_MIDDLE',
    payload: values
  }
}