const INITIAL_STATE = {
  currentMonthValues: { valorTotal: 0, atendimentos: [] },
  loading: true,
  aniversariantes: [],
  totalPacientes: {}
}
export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'CURRENT_MONTH_YELDS':
      return { 
        ...state, 
        currentMonthValues: action.payload.data,
        loading: false 
      }
    case 'CURRENT_MONTH_YELDS_CLEAR':
      return { ...state, currentMonthValues: INITIAL_STATE.currentMonthValues }
    case 'ANIVERSARIANTES_DO_DIA':
      return { ...state, aniversariantes: action.payload.data }
    case 'QUANTIDADE_TOTAL_PACIENTES':
      return { ...state, totalPacientes: action.payload.data }
    
    default:
      return state
  }
}