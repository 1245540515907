export const verifyProfile = (perfil) => {
    switch (perfil) {
      case 1:
        return 'SUPER';
      case 2:
        return 'ADMIN';
      case 3:
        return 'ATENDENTE';
              
      default:
        break;
    }
  }