import React from 'react';
import { Layout, Icon } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import MenuNav from './MenuNav';
import './LayoutApp.css'
import { validateToken, getUser, logout, getConsultorio } from '../components/Login/Redux/actions';
import Profile from '../components/Profile/Profile';
import logo from '../assets/logo_dente.png';
import { SelectConsultorio } from '../components/UI/ConsultorioSelect/SelectConsultorio';
import { getConsultorios } from '../components/Consultorio/Redux/actions';

const { Header, Sider, Content, Footer } = Layout;
const currentYear = new Date();

class LayoutApp extends React.Component {

  state = {
    collapsed: false,
    inputColor: true,
    invalidYear: false
  };

  componentDidMount() {
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
    this.props.validateToken({ token, userId });
    this.props.getUser(parseInt(userId));
  }
  
  componentDidUpdate(prevProps) {
    const consultorioId = parseInt(localStorage.getItem('consultorioId'));
    const { user, getConsultorio, getConsultorios } = this.props;
    const changed = this.props.user.pessoa !== prevProps.user.pessoa;

    if (changed) {
      if(user.isSuper) getConsultorios();
      
      if (consultorioId && !user.isSuper) {
        user.consultorios.forEach(consult => {
          if (consult.id === consultorioId) {
            getConsultorio(consult);
          } else getConsultorio(user.consultorios[0]);
        });
      }
      getConsultorio(user.consultorios[0]);
    }
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  handleLogout = () => {
    this.props.logout();
  };

  render() {
    const { user, consultorioActive, getConsultorio, consultorios } = this.props;
    const consultoriosList = user.isSuper ? consultorios.content : user.consultorios;
    return (
      <Layout>
        <Sider
          style={{ background: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          trigger={null}
          collapsible
          collapsed={this.state.collapsed} >
            <center>
              <img style={{ maxHeight: '60px' }} src={logo} alt="sysprod_logo"/>
            </center>
          <MenuNav
            collapsed={this.state.collapsed}
            user={user}
          />
        </Sider>
        <Layout>
          <Header
            style={{ background: '#fff', padding: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Icon
              style={{ fontSize: '18px', lineHeight: '64px', padding: '0 24px', cursor: 'pointer', transition: 'color .3s' }}
              className="trigger"
              type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
              onClick={this.toggle} />
              { user.consultorios.length > 1 || user.isSuper ?
                <SelectConsultorio 
                value={consultorioActive.id} 
                onClick={(consultorio) => getConsultorio(consultorio)} 
                consultorios={consultoriosList} 
                style={{ width: '500px' }} 
                />
                : ''
              }
            <Profile user={user} onClick={() => this.handleLogout()} />
          </Header>
          <Content style={{ margin: '24px 16px', padding: 24, background: '#fff', height: '100%' }}>
            {this.props.children}
          </Content>
          <Footer style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', color: '#3276b1', width: '100%', height: '78px' }}>
            <p> 2019 - {currentYear.getFullYear()} Copyright <Icon type="copyright" theme="outlined" /> SYSPROD - Sistema de Prontuário de Dentista</p>
            <p>Todos os direitos reservados.</p>
          </Footer>
        </Layout>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  user: state.login.user,
  consultorioActive: state.login.consultorioActive,
  consultorios: state.consultorio.list,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  validateToken,
  getUser,
  logout,
  getConsultorio,
  getConsultorios
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LayoutApp);
