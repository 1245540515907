const INITIAL_STATE = {
  funcionario: {
    cargo: {
      id: null
    },
    pessoa: {
      id: null,
      endereco: {
        id: null,
        cidade: {
          estado: {}
        }
      }
    }
  },
  list: { content: [] },
  loading: true,
  professores: []
}
export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'FUNCIONARIO_FETCHED':
      return {
        ...state,
        list: action.payload.data ? action.payload.data : { content: [] },
        loading: false
      }
    case 'FUNCIONARIO_FETCH':
      return { ...state, funcionario: action.payload }
    case 'FUNCIONARIO_CLEAR':
      return { ...state, funcionario: INITIAL_STATE.funcionario }
    case 'FUNCIONARIO_SEARCH':
      return { ...state, list: action.payload.data }
    case 'FUNCIONARIO_FETCH_ID':
      return { ...state, funcionario: action.payload.data }
    case 'UPDATE_FUNCIONARIO':
      return { ...state, funcionario: action.payload }
    case 'FUNCIONARIO_PESSOA_FETCHED':
      return { 
        ...state, 
        funcionario: {
          ...state.funcionario,
          pessoa: action.payload
        } 
      }

    default:
      return state
  }
}