import React from 'react';
import PropTypes from "prop-types";
//import { Tooltip, Icon } from 'antd';
import './PrintHeader.css';


export class PrintHeader extends React.Component {

  static propTypes = {
    consultorio: PropTypes.shape({
      pj: PropTypes.bool,
      nome: PropTypes.string,
      cro: PropTypes.string,
      nomeFantasia: PropTypes.string,
      razaoSocial: PropTypes.string,
      cnpj: PropTypes.string,
      telefone: PropTypes.string,
      celular: PropTypes.string,
      email: PropTypes.string,
      endereco: PropTypes.shape({
        logradouro: PropTypes.string,
        numero: PropTypes.string,
        bairro: PropTypes.string,
        cep: PropTypes.string,
        cidade: PropTypes.shape({
          nome: PropTypes.string,
          estado: PropTypes.shape({
            sigla: PropTypes.string
          })
        })
      })
    })
  };

  render() {
    const { consultorio } = this.props;
    const telefone = consultorio.telefone ? 'Telefone: ' + consultorio.telefone + ' ' : '';
    const celular = consultorio.celular ? 'Celular: ' + consultorio.celular + ' ' : '';
    const email = consultorio.email ? 'E-mail: ' + consultorio.email : '';
    const contato = telefone + celular + email;
    if (consultorio.pj) {
      return (
        <div className="print-only">
          <p style={{ margin: '4px', fontSize: '14pt' }}>{consultorio.nomeFantasia}</p>
          <p style={{ margin: '4px' }}>{consultorio.razaoSocial}</p>
          <p style={{ margin: '4px' }}>{`${consultorio.endereco.logradouro}, nº ${consultorio.endereco.numero}, Bairro ${consultorio.endereco.bairro}, CEP ${consultorio.endereco.cep}, ${consultorio.endereco.cidade.nome}/${consultorio.endereco.cidade.estado.sigla}`}</p>
          <p style={{ margin: '4px' }}>{`Contato: ${contato}`}</p>
          <p style={{ margin: '4px' }}>{`CNPJ: ${consultorio.cnpj}`}</p>
      </div>
      );
    }
    
    return (
      <div className="print-only">
        <p style={{ margin: '4px', fontSize: '14pt' }}>{consultorio.nome}</p>
        <p style={{ margin: '4px' }}>{`CRO: ${consultorio.cro}`}</p>
        <p style={{ margin: '4px' }}>{`${consultorio.endereco.logradouro}, nº ${consultorio.endereco.numero}, Bairro ${consultorio.endereco.bairro}, CEP ${consultorio.endereco.cep}, ${consultorio.endereco.cidade.nome}/${consultorio.endereco.cidade.estado.sigla}`}</p>
        <p style={{ margin: '4px', marginBottom: '50px' }}>{`Contato: ${contato}`}</p>
      </div>
    );
  }
}