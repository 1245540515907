import React from 'react';
import { Input, Form, Button, Card, Radio, Checkbox } from 'antd';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ContentTitle } from '../UI/ContentTitle/ContentTitle';
import { addConsultorio, updateConsultorio, clearConsultorio, getCnpj } from './Redux/actions';
import EnderecoFullForm from '../Endereco/EnderecoFull';
import { InputTelephone } from '../UI/TelefoneInput/InputTelephone';
import { InputCellPhone } from '../UI/TelefoneInput/InputCellPhone';

const FormItem = Form.Item;

class ConsultorioForm extends React.Component {

  state = {
    pj: true,
    matriz: true
  };

  componentDidMount() {
    const { form, consultorio } = this.props;
    const id = this.consultorioId;

    if(id) {
      this.setState({ ...this.state, pj: this.props.consultorio.pj });
      setTimeout(() => {
        form.setFieldsValue({
          'pj': consultorio.pj,
          'razaoSocial': consultorio.razaoSocial,
          'nomeFantasia': consultorio.nomeFantasia,
          'cnpj': consultorio.cnpj,
          'telefone': consultorio.telefone2,
          'celular': consultorio.celular,
          'email': consultorio.email,
          'nome': consultorio.nome,
          'cpf': consultorio.cpf,
          'cro': consultorio.cro,
        });
        
      }, 1);
    }
  }

  componentWillUnmount() {
    this.props.clearConsultorio();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { consultorio, consultorioActive } = this.props;

        values.endereco.cidade = { id: values.endereco.cidade };

        const consultorioId = this.consultorioId;
        if (consultorioId) {
          values.id = consultorioId;
          values.endereco.id = consultorio.endereco.id;
          this.props.updateConsultorio(values, consultorioActive);
        } else {
          this.props.addConsultorio(values, consultorioActive);
        }
        this.props.history.replace('/consultorio/list');

      }
    });
  }

  handleBack = () => {
    this.props.history.goBack();
  }

  handleFormatCnpj = (event) => {
    let number = this.convertNumber(event.target.value);
    this.handleSearchCnpj(number);
    event.target.value = this.convertCnpj(number);
  }

  convertCnpj = (value) => {
    value = value.replace(/\D/g, "")
    value = value.replace(/^(\d{2})(\d)/, "$1.$2")
    value = value.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
    value = value.replace(/\.(\d{3})(\d)/, ".$1/$2")
    value = value.replace(/(\d{4})(\d)/, "$1-$2")

    return value;
  }

  handleStringToNumber = (event) => {
    let number = this.convertNumber(event.target.value);
    event.target.value = number;
  }

  convertNumber(string) {
    return string.replace(/[^0-9]/g, '');
  }

  get consultorioId() {
    return this.props.match.params.id;
  }

  //webservice cnpj
  handleSearchCnpj = (cnpj) => {
    const { getCnpj } = this.props;
    if (cnpj.length === 14) {
      getCnpj(cnpj);
    }
  }

  onChangeType = e => {
    this.setState({
      ...this.state,
      pj: e.target.value,
    });
  };

  onChangeCheckbox = e => {
    this.setState({ ...this.state, matriz: e.target.checked });
  }

  handleformatCpf = (event) => {
    let cpfDigitado = this.convertNumber(event.target.value);
    if (cpfDigitado.length > 11) {
      cpfDigitado = cpfDigitado.slice(0, 11);
    }
    event.target.value = this.formatCpf(cpfDigitado);
  }

  validateCPF = (rule, value, callback) => {
    if (value) {
      let soma, resto, cpf = value;
      let strCPF = this.convertNumber(cpf);
      soma = 0;
      if (strCPF === "00000000000") callback('CPF inválido!');

      for (let i = 1; i <= 9; i++) soma = soma + parseInt(strCPF.substring(i - 1, i), 10) * (11 - i);
      resto = (soma * 10) % 11;

      if ((resto === 10) || (resto === 11)) resto = 0;
      if (resto !== parseInt(strCPF.substring(9, 10), 10)) callback('CPF inválido!');

      soma = 0;
      for (let i = 1; i <= 10; i++) soma = soma + parseInt(strCPF.substring(i - 1, i), 10) * (12 - i);
      resto = (soma * 10) % 11;

      if ((resto === 10) || (resto === 11)) resto = 0;
      if (resto !== parseInt(strCPF.substring(10, 11), 10)) callback('CPF inválido!');
    }
    callback();
  }

  formatCpf(cpf) {
    cpf = cpf.replace(/\D/g, "");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    return cpf;
  }

  renderFormPJ() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const formItemLayout = {
      labelCol: { span: 7 },
      wrapperCol: { span: 10 }
    };
    return (
      <React.Fragment>
        <FormItem
          {...formItemLayout}
          label="Matriz"
        >
          {getFieldDecorator('matriz', {
            initialValue: this.state.matriz,
            rules: [{ required: false, message: 'Por favor informe o tipo!' }],
          })(
            <Checkbox checked={this.state.matriz} onChange={this.onChangeCheckbox}></Checkbox>
          )}
        </FormItem>
        <FormItem
          {...formItemLayout}
          label="CNPJ"
        >
          {getFieldDecorator('cnpj', {
            rules: [{ required: true, message: 'Por favor informe o CNPJ do consultório!' }],
          })(
            <Input
              onChange={this.handleFormatCnpj}
              maxLength={18} />
          )}
        </FormItem>
        <FormItem
          {...formItemLayout}
          label="Razão Social"
        >
          {getFieldDecorator('razaoSocial', {
            rules: [{ required: true, message: 'Por favor informe a razão social!' }],
          })(
            <Input maxLength={150} />
          )}
        </FormItem>
        <FormItem
          {...formItemLayout}
          label="Nome Fantasia"
        >
          {getFieldDecorator('nomeFantasia', {
            rules: [{ required: true, message: 'Por favor informe o nome fantasia!' }
            ],
          })(
            <Input maxLength={150} />
          )}
        </FormItem>
        <Card title='Contato' bordered={false}>
          <FormItem
            {...formItemLayout}
            label="Telefone"
          >
            {getFieldDecorator('telefone', {
              rules: [{ required: false, message: 'Por favor informe um telefone de contato do consultório!' }],
            })(
              <InputTelephone />
            )}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label="Celular"
          >
            {getFieldDecorator('celular', {
              rules: [{ required: false }],
            })(
              <InputCellPhone />
            )}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label="E-mail"
          >
            {getFieldDecorator('email', {
              rules: [{
                type: 'email', message: 'O e-mail não é válido!',
              }],
            })(
              <Input maxLength={45} />
            )}
          </FormItem>
        </Card>
      </React.Fragment>
    );
  }

  renderFormPF() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const formItemLayout = {
      labelCol: { span: 7 },
      wrapperCol: { span: 10 }
    };
    return (
      <React.Fragment>
        <FormItem
          {...formItemLayout}
          label="Nome"
        >
          {getFieldDecorator('nome', {
            rules: [{ required: true, message: 'Por favor informe o nome do consultório' }],
          })(
            <Input />
          )}
        </FormItem>
        <FormItem
              {...formItemLayout}
              label="CPF"
            >
              {getFieldDecorator('cpf', {
                rules: [{ required: true, message: 'Por favor informe o CPF do dentista!' },
                {
                  validator: this.validateCPF,
                }
                ],
              })(
                <Input
                  style={{ width: 140 }}
                  onChange={this.handleformatCpf} />
              )}
            </FormItem>
        <FormItem
          {...formItemLayout}
          label="CRO"
        >
          {getFieldDecorator('cro', {
            rules: [{ required: true, message: 'Por favor informe o CRO do dentista!' }
            ],
          })(
            <Input />
          )}
        </FormItem>
        <Card title='Contato' bordered={false}>
        <FormItem
            {...formItemLayout}
            label="Telefone"
          >
            {getFieldDecorator('telefone', {
              rules: [{ required: false, message: 'Por favor informe um telefone de contato do consultório!' }],
            })(
              <InputTelephone />
            )}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label="Celular"
          >
            {getFieldDecorator('celular', {
              rules: [{ required: false }],
            })(
              <InputCellPhone />
            )}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label="E-mail"
          >
            {getFieldDecorator('email', {
              rules: [{
                type: 'email', message: 'O e-mail não é válido!',
              }],
            })(
              <Input />
            )}
          </FormItem>
        </Card>
      </React.Fragment>
    );
  }

  render() {
    const { consultorio, form } = this.props;
    const { endereco } = consultorio;
    const id = this.consultorioId;
    const { pj } = this.state;
    const title = id ? "Atualizar Consultorio" : "Nova Consultorio";
    const { getFieldDecorator } = form;
    const formItemLayout = {
      labelCol: { span: 7 },
      wrapperCol: { span: 10 }
    };

    return (
      <React.Fragment>
        <ContentTitle hasBack>
          {title}
        </ContentTitle>
        <Form onSubmit={this.handleSubmit}>
          <Card title='Dados do Consultório' bordered={false}>
            <FormItem
              {...formItemLayout}
              label="Tipo"
            >
              {getFieldDecorator('pj', {
                initialValue: true,
                rules: [{ required: true, message: 'Por favor informe o tipo!' }],
              })(
                <Radio.Group onChange={this.onChangeType} >
                  <Radio value={true}>Pessoa Jurídica</Radio>
                  <Radio value={false}>Pessoa Física</Radio>
                </Radio.Group>
              )}
            </FormItem>
            { pj ?
            this.renderFormPJ()
            :
            this.renderFormPF()
             }
          </Card>
          <Card title='Endereço' bordered={false}>
            <FormItem>
              <EnderecoFullForm form={form} endereco={endereco} />
            </FormItem>
          </Card>
          <FormItem>
            <center>
              <Button type="primary" htmlType="submit">{id ? 'Atualizar' : 'Salvar'}</Button>
              {'    '}
              <Button onClick={this.handleBack}>Cancelar</Button>
            </center>
          </FormItem>
        </Form>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  consultorio: state.consultorio.consultorio,
  //cnpj: state.instituicao.cnpj,
  user: state.login.user,
  consultorioActive: state.login.consultorioActive
});
const mapDispatchToProps = dispatch => bindActionCreators({
  addConsultorio,
  updateConsultorio,
  clearConsultorio,
  getCnpj
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(ConsultorioForm));
withRouter(ConsultorioForm)
