import React from 'react';
import { Layout, Menu, Icon } from 'antd';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { logout } from '../components/Login/Redux/actions';
import { FaPlusSquare, FaClinicMedical, FaUserFriends, FaCalendarCheck, FaStethoscope, FaSearchDollar, FaUsers } from 'react-icons/fa';

const { Sider } = Layout;
const { SubMenu } = Menu;

class MenuNav extends React.Component {

  handleLogout = () => {
    this.props.logout();
  };

  render() {
    const { user } = this.props;
    const { isSuper, isAdmin, isAtendente } = user;
    return (
      <Sider
        //style={{ background: theme === 'dark' ? '#001529' : '#fff' }}
        trigger={null}
        collapsible
        collapsed={this.props.collapsed}>
        <Menu
          mode="inline"
          defaultSelectedKeys={['1']}>
          <Menu.Item key="1">
          <Link to="/dashboard">
            <Icon type="dashboard" />
            <span>Dashboard</span>
          </Link>
          </Menu.Item>
          { isSuper || isAdmin || isAtendente ?
            <SubMenu key="sub1"
              title={<span style={{ display: 'flex', alignItems: 'center' }}><FaPlusSquare style={{ marginRight: '10px' }} /><span>Cadastros</span></span>}>
            <Menu.Item key="2">
              <Link to="/consultorio/list" style={{ display: 'flex', alignItems: 'center' }}>
                <FaClinicMedical style={{ marginRight: '10px' }} />
                <span>Consultório</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="3">
              <Link to="/funcionarios/list">
                <Icon type="idcard" />
                <span>Funcionário</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="4">
              <Link to="/paciente/list" style={{ display: 'flex', alignItems: 'center' }}>
                <FaUserFriends style={{ marginRight: '10px' }} />
                <span>Paciente</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="5">
              <Link to="/cargo/list">
                <Icon type="audit" />
                <span>Cargo</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="6">
              <Link to="/servico/list" style={{ display: 'flex', alignItems: 'center' }}>
                <FaStethoscope style={{ marginRight: '10px' }} />
                <span>Serviço</span>
              </Link>
            </Menu.Item>
          </SubMenu>
          : ''
          }

          {isSuper || isAdmin || isAtendente ?
          <Menu.Item key="7">
            <Link to="/atendimento/list" style={{ display: 'flex', alignItems: 'center' }}>
              <FaCalendarCheck style={{ marginRight: '10px' }} />
              <span>Atendimento</span>
            </Link>
          </Menu.Item>
          : ''
          }

          { isSuper || isAdmin ?
          <SubMenu key="sub3"
            title={<span><Icon type="dollar" /><span>Financeiro</span></span>}>
            <Menu.Item key="8">
              <Link to="/financeiro/consulta" style={{ display: 'flex', alignItems: 'center' }}>
                <FaSearchDollar style={{ marginRight: '10px' }} />
                <span>Consulta</span>
              </Link>
            </Menu.Item>
          </SubMenu>
          : ''
          }

          { isSuper || isAdmin ?
          <SubMenu key="sub4"
            title={<span><Icon type="setting" /><span>Configurações</span></span>}>
            <Menu.Item key="16">
              <Link to="/usuario/list">
                <FaUsers style={{ marginRight: '10px' }} />
                <span>Usuários</span>
              </Link>
            </Menu.Item>
          </SubMenu>
          : ''
          }
          
          <Menu.Item key="9">
              <Link onClick={() => this.handleLogout()} to="/">
                <Icon type="logout" />
                <span>Sair</span>
              </Link>
            </Menu.Item>
        </Menu>
        
      </Sider>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  logout
}, dispatch);
    
export default connect(null, mapDispatchToProps)(MenuNav);
  