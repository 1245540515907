import React from 'react';
import { Card, Icon, Avatar, Tooltip } from 'antd';
import PropTypes from 'prop-types';

import MaleBlankAvatar from '../../assets/Male_Blank_Avatar.jpg';
import FemaleBlankAvatar from '../../assets/Female_Blank_Avatar.jpg';
import { abbreviateName } from '../UI/util/abbreviateName';

const { Meta } = Card;

export default class Content extends React.Component {

  render() {
    const { user } = this.props;
    const avatarGenre = user.pessoa.genero === "MASCULINO" ? MaleBlankAvatar : FemaleBlankAvatar;
    return (
      <Card
        style={{ width: 300, marginTop: 16 }}
        actions={[
          <Tooltip title="Configurações">
            <Icon type="setting" key="setting" />
          </Tooltip>,
          <Tooltip title="Sair">
            <Icon type="logout" key="logout" onClick={this.props.onClick} />
          </Tooltip>
        ]}
      >
        <Meta
          avatar={
            <Avatar size="large" src={!user.imageUrl ? avatarGenre : user.imageUrl} />
          }
          title={
            <Tooltip title={user.pessoa.nome}>
              {abbreviateName(user.pessoa.nome)}
            </Tooltip>
          }
          description={`Login: ${user.login}`}
        />
      </Card>
    );
  }
}

Content.propTypes = {
  user: PropTypes.object.isRequired
};
